import { getNestedValue, isDefined } from 'src/app/shared/common';
import { isPath, parseSegments } from 'src/app/shared/topic-utils';

class Evaluator {
    private lastTimestamp = 0;
    private segmentsCache: Record<string, string[]> = {};
    private functionsCache: Record<string, any> = {};

    getFunction = (code: string) => {
        if (!(code in this.functionsCache)) {
            const hasReturn = /return/.test(code);
            const wrappedCode = hasReturn ? code : `return ${code}`;
            this.functionsCache[code] = this.createFunction(wrappedCode);
        }
        return this.functionsCache[code];
    };

    createFunction = (code: string) => Function('datasources', `${code}`);

    evaluate(value: string, datasources: object) {
        if (!value) {
            return undefined;
        }

        // Add the value to the cache if it's not there already
        if (!isDefined(this.segmentsCache[value])) {
            if (isPath(value)) {
                this.segmentsCache[value] = parseSegments(value);
            } else {
                this.segmentsCache[value] = [];
            }
        }

        // Get the value directly to avoid eval
        if (this.segmentsCache[value].length > 0) {
            return getNestedValue(datasources, this.segmentsCache[value]);
        }

        const now = +new Date();
        if (now - this.lastTimestamp > 1000) {
            this.lastTimestamp = now;
        }
        try {
            const datasourceFunction = this.getFunction(value);
            const result = datasourceFunction(datasources);
            if (
                (typeof result === 'object' || typeof result === 'function') &&
                result !== null
            ) {
                return Object.keys(result).length ? result : null;
            }
            return result;
        } catch (e) {
            return null;
        }
    }
}

const evaluator = new Evaluator();
export const evaluate = (value: string, datasources: object) =>
    evaluator.evaluate(value, datasources);
