module.exports = {
  ".alert": {
    "display": "grid",
    "width": "100%",
    "gridAutoFlow": "row",
    "alignContent": "flex-start",
    "alignItems": "center",
    "justifyItems": "center",
    "gap": "1rem",
    "textAlign": "center",
    "borderWidth": "1px",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "padding": "1rem",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)",
    "-AlertBg": "hsl(var(--b2))",
    "-AlertBgMix": "hsl(var(--b1))",
    "backgroundColor": "var(--alert-bg)"
  },
  "@media (min-width: 640px)": {
    ".alert": {
      "gridAutoFlow": "column",
      "gridTemplateColumns": "auto minmax(auto,1fr)",
      "justifyItems": "start",
      "textAlign": "right"
    }
  },
  ".artboard": {
    "width": "100%"
  },
  ".avatar": {
    "position": "relative",
    "display": "inline-flex"
  },
  ".avatar > div": {
    "display": "block",
    "aspectRatio": "1 / 1",
    "overflow": "hidden"
  },
  ".avatar img": {
    "height": "100%",
    "width": "100%",
    "objectFit": "cover"
  },
  ".avatar.placeholder > div": {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  },
  ".badge": {
    "display": "inline-flex",
    "alignItems": "center",
    "justifyContent": "center",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms",
    "height": "1.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "width": "fit-content",
    "paddingRight": "0.563rem",
    "paddingLeft": "0.563rem",
    "borderWidth": "1px",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "borderRadius": "var(--rounded-badge, 1.9rem)"
  },
  ".btm-nav": {
    "position": "fixed",
    "bottom": "0px",
    "right": "0px",
    "left": "0px",
    "display": "flex",
    "width": "100%",
    "flexDirection": "row",
    "alignItems": "center",
    "justifyContent": "space-around",
    "paddingBottom": "env(safe-area-inset-bottom)",
    "height": "4rem",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "color": "currentColor"
  },
  ".btm-nav > *": {
    "position": "relative",
    "display": "flex",
    "height": "100%",
    "flexBasis": "100%",
    "cursor": "pointer",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center",
    "gap": "0.25rem",
    "borderColor": "currentColor"
  },
  ".breadcrumbs": {
    "maxWidth": "100%",
    "overflowX": "auto",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  },
  ".breadcrumbs > ul,\n  .breadcrumbs > ol": {
    "display": "flex",
    "alignItems": "center",
    "whiteSpace": "nowrap",
    "minHeight": "min-content"
  },
  ".breadcrumbs > ul > li, .breadcrumbs > ol > li": {
    "display": "flex",
    "alignItems": "center"
  },
  ".breadcrumbs > ul > li > a, .breadcrumbs > ol > li > a": {
    "display": "flex",
    "cursor": "pointer",
    "alignItems": "center"
  },
  "@media (hover:hover)": [{
    ".breadcrumbs > ul > li > a:hover, .breadcrumbs > ol > li > a:hover": {
      "textDecorationLine": "underline"
    }
  }, {
    ".link-hover:hover": {
      "textDecorationLine": "underline"
    }
  }, {
    ".checkbox-primary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-secondary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-accent:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-success:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-warning:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-info:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
    }
  }, {
    ".checkbox-error:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
    }
  }, {
    ".label a:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--bc) / var(--tw-text-opacity))"
    }
  }, {
    ".link-primary:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--pf) / var(--tw-text-opacity))"
    }
  }, {
    ".link-secondary:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--sf) / var(--tw-text-opacity))"
    }
  }, {
    ".link-accent:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--af) / var(--tw-text-opacity))"
    }
  }, {
    ".link-neutral:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--nf) / var(--tw-text-opacity))"
    }
  }, {
    ".link-success:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--su) / var(--tw-text-opacity))"
    }
  }, {
    ".link-info:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--in) / var(--tw-text-opacity))"
    }
  }, {
    ".link-warning:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--wa) / var(--tw-text-opacity))"
    }
  }, {
    ".link-error:hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--er) / var(--tw-text-opacity))"
    }
  }, {
    ".menu li > *:not(ul):not(.menu-title):not(details):active,\n.menu li > *:not(ul):not(.menu-title):not(details).active,\n.menu li > details > summary:active": {
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--nc) / var(--tw-text-opacity))"
    }
  }, {
    ".radio-primary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-secondary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-accent:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-success:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-warning:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-info:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
    }
  }, {
    ".radio-error:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
    }
  }, {
    ".tab:hover": {
      "-TwTextOpacity": "1"
    }
  }, {
    ".tabs-boxed .tab-active:not(.tab-disabled):not([disabled]):hover": {
      "-TwTextOpacity": "1",
      "color": "hsl(var(--pc) / var(--tw-text-opacity))"
    }
  }, {
    ".table tr.hover:hover,\n  .table tr.hover:nth-child(even):hover": {
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))"
    }
  }, {
    ".table-zebra tr.hover:hover,\n  .table-zebra tr.hover:nth-child(even):hover": {
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))"
    }
  }],
  ".btn": {
    "display": "inline-flex",
    "flexShrink": "0",
    "cursor": "pointer",
    "userSelect": "none",
    "flexWrap": "wrap",
    "alignItems": "center",
    "justifyContent": "center",
    "borderColor": ["transparent", "hsl(var(--b2) / var(--tw-border-opacity))"],
    "textAlign": "center",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms",
    "borderRadius": "var(--rounded-btn, 0.5rem)",
    "height": "3rem",
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "minHeight": "3rem",
    "fontSize": "0.875rem",
    "lineHeight": "1em",
    "gap": "0.5rem",
    "fontWeight": "600",
    "textDecorationLine": ["none", "none"],
    "borderWidth": "var(--border-btn, 1px)",
    "animation": "button-pop var(--animation-btn, 0.25s) ease-out",
    "textTransform": "var(--btn-text-case, uppercase)",
    "-TwBorderOpacity": "1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--bc) / 1)"
  },
  ".btn-disabled,\n  .btn[disabled],\n  .btn:disabled": {
    "pointerEvents": "none"
  },
  ".btn-square": {
    "height": "3rem",
    "width": "3rem",
    "padding": "0px"
  },
  ".btn-circle": {
    "height": "3rem",
    "width": "3rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-group": {
    "display": "inline-flex"
  },
  ".btn-group > input[type=\"radio\"].btn": {
    "appearance": "none"
  },
  ".btn-group > input[type=\"radio\"].btn:before": {
    "content": "attr(data-title)"
  },
  ".btn:is(input[type=\"checkbox\"]),\n.btn:is(input[type=\"radio\"])": {
    "width": "auto",
    "appearance": "none"
  },
  ".btn:is(input[type=\"checkbox\"]):after,\n.btn:is(input[type=\"radio\"]):after": {
    "-TwContent": "attr(aria-label)",
    "content": "var(--tw-content)"
  },
  ".card": {
    "position": "relative",
    "display": "flex",
    "flexDirection": "column",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".card:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".card-body": {
    "display": ["flex", "flex"],
    "flex": "1 1 auto",
    "flexDirection": ["column", "column"],
    "padding": "var(--padding-card, 2rem)",
    "gap": "0.5rem"
  },
  ".card-body :where(p)": {
    "flexGrow": "1"
  },
  ".card-actions": {
    "display": "flex",
    "flexWrap": "wrap",
    "alignItems": "flex-start",
    "gap": "0.5rem"
  },
  ".card figure": {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center"
  },
  ".card.image-full": {
    "display": "grid"
  },
  ".card.image-full:before": {
    "position": "relative",
    "content": "\"\"",
    "zIndex": "10",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "opacity": "0.75",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".card.image-full:before,\n    .card.image-full > *": {
    "gridColumnStart": "1",
    "gridRowStart": "1"
  },
  ".card.image-full > figure img": {
    "height": "100%",
    "objectFit": "cover"
  },
  ".card.image-full > .card-body": {
    "position": "relative",
    "zIndex": "20",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))"
  },
  ".carousel": {
    "display": "inline-flex",
    "overflowX": "scroll",
    "scrollSnapType": "x mandatory",
    "scrollBehavior": "smooth",
    "msOverflowStyle": "none",
    "scrollbarWidth": "none"
  },
  ".carousel-vertical": {
    "flexDirection": "column",
    "overflowY": "scroll",
    "scrollSnapType": "y mandatory"
  },
  ".carousel-item": {
    "boxSizing": "content-box",
    "display": "flex",
    "flex": "none",
    "scrollSnapAlign": "start"
  },
  ".carousel-center .carousel-item": {
    "scrollSnapAlign": "center"
  },
  ".carousel-end .carousel-item": {
    "scrollSnapAlign": "end"
  },
  ".chat": {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
    "columnGap": "0.75rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem"
  },
  ".chat-image": {
    "gridRow": "span 2 / span 2",
    "alignSelf": "flex-end"
  },
  ".chat-header": {
    "gridRowStart": "1",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".chat-footer": {
    "gridRowStart": "3",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".chat-bubble": {
    "position": "relative",
    "display": "block",
    "width": "fit-content",
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "maxWidth": "90%",
    "borderRadius": "var(--rounded-box, 1rem)",
    "minHeight": "2.75rem",
    "minWidth": "2.75rem",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))"
  },
  ".chat-bubble:before": {
    "position": "absolute",
    "bottom": "0px",
    "height": "0.75rem",
    "width": "0.75rem",
    "backgroundColor": "inherit",
    "content": "\"\"",
    "maskSize": "contain",
    "maskRepeat": "no-repeat",
    "maskPosition": "center"
  },
  ".chat-start": {
    "placeItems": "start",
    "gridTemplateColumns": "auto 1fr"
  },
  ".chat-start .chat-header": {
    "gridColumnStart": "2"
  },
  ".chat-start .chat-footer": {
    "gridColumnStart": "2"
  },
  ".chat-start .chat-image": {
    "gridColumnStart": "1"
  },
  ".chat-start .chat-bubble": {
    "gridColumnStart": "2",
    "borderBottomRightRadius": "0px"
  },
  ".chat-start .chat-bubble:before": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e\")",
    "right": "-0.749rem"
  },
  "[dir=\"rtl\"] .chat-start .chat-bubble:before": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e\")"
  },
  ".chat-end": {
    "placeItems": "end",
    "gridTemplateColumns": "1fr auto"
  },
  ".chat-end .chat-header": {
    "gridColumnStart": "1"
  },
  ".chat-end .chat-footer": {
    "gridColumnStart": "1"
  },
  ".chat-end .chat-image": {
    "gridColumnStart": "2"
  },
  ".chat-end .chat-bubble": {
    "gridColumnStart": "1",
    "borderBottomLeftRadius": "0px"
  },
  ".chat-end .chat-bubble:before": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e\")",
    "right": "99.9%"
  },
  "[dir=\"rtl\"] .chat-end .chat-bubble:before": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e\")"
  },
  ".checkbox": {
    "flexShrink": "0",
    "-Chkbg": "var(--bc)",
    "-Chkfg": "var(--b1)",
    "height": "1.5rem",
    "width": "1.5rem",
    "cursor": "pointer",
    "appearance": "none",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.2",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".collapse:not(td):not(tr):not(colgroup)": {
    "visibility": "visible"
  },
  ".collapse": {
    "position": "relative",
    "display": "grid",
    "overflow": "hidden",
    "gridTemplateRows": "auto 0fr",
    "transition": "grid-template-rows 0.2s",
    "width": "100%",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".collapse-title,\n.collapse > input[type=\"checkbox\"],\n.collapse > input[type=\"radio\"],\n.collapse-content": {
    "gridColumnStart": "1",
    "gridRowStart": "1"
  },
  ".collapse > input[type=\"checkbox\"],\n.collapse > input[type=\"radio\"]": {
    "appearance": "none",
    "opacity": "0"
  },
  ".collapse-content": {
    "visibility": "hidden",
    "gridColumnStart": "1",
    "gridRowStart": "2",
    "minHeight": "0px",
    "transition": ["visibility 0.2s", "padding 0.2s ease-out,\n    background-color 0.2s ease-out"],
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "cursor": "unset"
  },
  ".collapse[open],\n.collapse-open,\n.collapse:focus:not(.collapse-close)": {
    "gridTemplateRows": "auto 1fr"
  },
  ".collapse:not(.collapse-close):has(> input[type=\"checkbox\"]:checked),\n.collapse:not(.collapse-close):has(> input[type=\"radio\"]:checked)": {
    "gridTemplateRows": "auto 1fr"
  },
  ".collapse[open] > .collapse-content,\n.collapse-open > .collapse-content,\n.collapse:focus:not(.collapse-close) > .collapse-content,\n.collapse:not(.collapse-close) > input[type=\"checkbox\"]:checked ~ .collapse-content,\n.collapse:not(.collapse-close) > input[type=\"radio\"]:checked ~ .collapse-content": {
    "visibility": "visible",
    "minHeight": "fit-content"
  },
  ":root .countdown": {
    "lineHeight": "1em"
  },
  ".countdown": {
    "display": "inline-flex"
  },
  ".countdown > *": {
    "height": "1em",
    "display": "inline-block",
    "overflowY": "hidden"
  },
  ".countdown > *:before": {
    "position": "relative",
    "content": "\"00\\A 01\\A 02\\A 03\\A 04\\A 05\\A 06\\A 07\\A 08\\A 09\\A 10\\A 11\\A 12\\A 13\\A 14\\A 15\\A 16\\A 17\\A 18\\A 19\\A 20\\A 21\\A 22\\A 23\\A 24\\A 25\\A 26\\A 27\\A 28\\A 29\\A 30\\A 31\\A 32\\A 33\\A 34\\A 35\\A 36\\A 37\\A 38\\A 39\\A 40\\A 41\\A 42\\A 43\\A 44\\A 45\\A 46\\A 47\\A 48\\A 49\\A 50\\A 51\\A 52\\A 53\\A 54\\A 55\\A 56\\A 57\\A 58\\A 59\\A 60\\A 61\\A 62\\A 63\\A 64\\A 65\\A 66\\A 67\\A 68\\A 69\\A 70\\A 71\\A 72\\A 73\\A 74\\A 75\\A 76\\A 77\\A 78\\A 79\\A 80\\A 81\\A 82\\A 83\\A 84\\A 85\\A 86\\A 87\\A 88\\A 89\\A 90\\A 91\\A 92\\A 93\\A 94\\A 95\\A 96\\A 97\\A 98\\A 99\\A\"",
    "whiteSpace": "pre",
    "top": "calc(var(--value) * -1em)",
    "textAlign": "center",
    "transition": "all 1s cubic-bezier(1, 0, 0, 1)"
  },
  ".divider": {
    "display": "flex",
    "flexDirection": "row",
    "alignItems": "center",
    "alignSelf": "stretch",
    "marginTop": "1rem",
    "marginBottom": "1rem",
    "height": "1rem",
    "whiteSpace": "nowrap"
  },
  ".divider:before,\n  .divider:after": {
    "content": "\"\"",
    "flexGrow": "1",
    "height": "0.125rem",
    "width": "100%"
  },
  ".drawer": {
    "position": "relative",
    "display": "grid",
    "gridAutoColumns": "max-content auto",
    "width": "100%"
  },
  ".drawer-content": {
    "gridColumnStart": "2",
    "gridRowStart": "1"
  },
  ".drawer-side": {
    "pointerEvents": "none",
    "position": "fixed",
    "right": "0px",
    "top": "0px",
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "display": "grid",
    "width": "100%",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gridTemplateRows": "repeat(1, minmax(0, 1fr))",
    "alignItems": "flex-start",
    "justifyItems": "start",
    "overflowY": "auto",
    "overscrollBehavior": "contain",
    "height": ["100vh", "100dvh"],
    "scrollbarWidth": "none"
  },
  ".drawer-side::-webkit-scrollbar": {
    "display": "none"
  },
  ".drawer-side > .drawer-overlay": {
    "position": "sticky",
    "top": "0px",
    "placeSelf": "stretch",
    "cursor": "pointer",
    "backgroundColor": "transparent",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms"
  },
  ".drawer-side > *": {
    "gridColumnStart": "1",
    "gridRowStart": "1"
  },
  ".drawer-side > *:not(.drawer-overlay)": {
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "300ms",
    "transform": "translateX(100%)"
  },
  "[dir=\"rtl\"] .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(100%)"
  },
  ".drawer-toggle": {
    "position": "fixed",
    "height": "0px",
    "width": "0px",
    "appearance": "none",
    "opacity": "0"
  },
  ".drawer-toggle:checked ~ .drawer-side": {
    "pointerEvents": "auto",
    "visibility": "visible"
  },
  ".drawer-toggle:checked ~ .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(0%)"
  },
  ".drawer-end": {
    "gridAutoColumns": "auto max-content"
  },
  ".drawer-end .drawer-toggle ~ .drawer-content": {
    "gridColumnStart": "1"
  },
  ".drawer-end .drawer-toggle ~ .drawer-side": {
    "gridColumnStart": "2",
    "justifyItems": "end"
  },
  ".drawer-end .drawer-toggle ~ .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(-100%)"
  },
  ".drawer-end .drawer-toggle:checked ~ .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(0%)"
  },
  ".dropdown": {
    "position": "relative",
    "display": "inline-block"
  },
  ".dropdown > *:not(summary):focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".dropdown .dropdown-content": {
    "position": "absolute"
  },
  ".dropdown:is(:not(details)) .dropdown-content": {
    "visibility": "hidden",
    "opacity": "0",
    "transformOrigin": "top",
    "-TwScaleX": ".95",
    "-TwScaleY": ".95",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms"
  },
  ".dropdown-end .dropdown-content": {
    "left": "0px"
  },
  ".dropdown-left .dropdown-content": {
    "bottom": "auto",
    "left": "100%",
    "top": "0px",
    "transformOrigin": "left"
  },
  ".dropdown-right .dropdown-content": {
    "bottom": "auto",
    "right": "100%",
    "top": "0px",
    "transformOrigin": "right"
  },
  ".dropdown-bottom .dropdown-content": {
    "bottom": "auto",
    "top": "100%",
    "transformOrigin": "top"
  },
  ".dropdown-top .dropdown-content": {
    "bottom": "100%",
    "top": "auto",
    "transformOrigin": "bottom"
  },
  ".dropdown-end.dropdown-right .dropdown-content": {
    "bottom": "0px",
    "top": "auto"
  },
  ".dropdown-end.dropdown-left .dropdown-content": {
    "bottom": "0px",
    "top": "auto"
  },
  ".dropdown.dropdown-open .dropdown-content,\n.dropdown:not(.dropdown-hover):focus .dropdown-content,\n.dropdown:focus-within .dropdown-content": {
    "visibility": "visible",
    "opacity": "1"
  },
  "@media (hover: hover)": [{
    ".dropdown.dropdown-hover:hover .dropdown-content": {
      "visibility": "visible",
      "opacity": "1"
    }
  }, {
    ".btm-nav > *.disabled:hover,\n      .btm-nav > *[disabled]:hover": {
      "pointerEvents": "none",
      "-TwBorderOpacity": "0",
      "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
      "-TwBgOpacity": "0.1",
      "color": "hsl(var(--bc) / var(--tw-text-opacity))",
      "-TwTextOpacity": "0.2"
    }
  }, {
    ".btn:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--b3) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-primary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--pf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--pf) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-secondary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--sf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--sf) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-accent:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--af) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--af) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-neutral:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--nf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--nf) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-info:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-success:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-warning:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn-error:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))"
    }
  }, {
    ".btn.glass:hover": {
      "-GlassOpacity": "25%",
      "-GlassBorderOpacity": "15%"
    }
  }, {
    ".btn-ghost:hover": {
      "-TwBorderOpacity": "0",
      "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
      "-TwBgOpacity": "0.2"
    }
  }, {
    ".btn-link:hover": {
      "borderColor": "transparent",
      "backgroundColor": "transparent",
      "textDecorationLine": "underline"
    }
  }, {
    ".btn-outline:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--b1) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-primary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--pf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--pf) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--pc) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-secondary:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--sf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--sf) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--sc) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-accent:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--af) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--af) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--ac) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-success:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--suc) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-info:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--inc) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-warning:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--wac) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-outline.btn-error:hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--erc) / var(--tw-text-opacity))"
    }
  }, {
    ".btn-disabled:hover,\n    .btn[disabled]:hover,\n    .btn:disabled:hover": {
      "-TwBorderOpacity": "0",
      "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
      "-TwBgOpacity": "0.2",
      "color": "hsl(var(--bc) / var(--tw-text-opacity))",
      "-TwTextOpacity": "0.2"
    }
  }, {
    ".btn:is(input[type=\"checkbox\"]:checked):hover, .btn:is(input[type=\"radio\"]:checked):hover": {
      "-TwBorderOpacity": "1",
      "borderColor": "hsl(var(--pf) / var(--tw-border-opacity))",
      "-TwBgOpacity": "1",
      "backgroundColor": "hsl(var(--pf) / var(--tw-bg-opacity))"
    }
  }, {
    ".dropdown.dropdown-hover:hover .dropdown-content": {
      "-TwScaleX": "1",
      "-TwScaleY": "1",
      "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
    }
  }, {
    ":where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(.active):hover, :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(.active):hover": {
      "cursor": "pointer",
      "backgroundColor": "hsl(var(--bc) / 0.1)",
      "-TwTextOpacity": "1",
      "color": "hsl(var(--bc) / var(--tw-text-opacity))",
      "outline": "2px solid transparent",
      "outlineOffset": "2px"
    }
  }, {
    ".tab[disabled],\n    .tab[disabled]:hover": {
      "cursor": "not-allowed",
      "color": "hsl(var(--bc) / var(--tw-text-opacity))",
      "-TwTextOpacity": "0.2"
    }
  }],
  ".dropdown:is(details) summary::-webkit-details-marker": {
    "display": "none"
  },
  ".file-input": {
    "height": "3rem",
    "flexShrink": "1",
    "paddingLeft": "1rem",
    "fontSize": ["0.875rem", "1rem"],
    "lineHeight": ["1.25rem", "2", "1.5rem"],
    "overflow": "hidden",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".file-input::file-selector-button": {
    "marginLeft": "1rem",
    "display": "inline-flex",
    "height": "100%",
    "flexShrink": "0",
    "cursor": "pointer",
    "userSelect": "none",
    "flexWrap": "wrap",
    "alignItems": "center",
    "justifyContent": "center",
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "textAlign": "center",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "1em"],
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms",
    "borderStyle": "solid",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--n) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "fontWeight": "600",
    "textTransform": ["uppercase", "var(--btn-text-case, uppercase)"],
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))",
    "textDecorationLine": "none",
    "borderWidth": "var(--border-btn, 1px)",
    "animation": "button-pop var(--animation-btn, 0.25s) ease-out"
  },
  ".footer": {
    "display": "grid",
    "width": "100%",
    "gridAutoFlow": "row",
    "placeItems": "start",
    "columnGap": "1rem",
    "rowGap": "2.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".footer > *": {
    "display": "grid",
    "placeItems": "start",
    "gap": "0.5rem"
  },
  ".footer-center": {
    "placeItems": "center",
    "textAlign": "center"
  },
  ".footer-center > *": {
    "placeItems": "center"
  },
  "@media (min-width: 48rem)": {
    ".footer": {
      "gridAutoFlow": "column"
    },
    ".footer-center": {
      "gridAutoFlow": "row dense"
    }
  },
  ".form-control": {
    "display": "flex",
    "flexDirection": "column"
  },
  ".label": {
    "display": "flex",
    "userSelect": "none",
    "alignItems": "center",
    "justifyContent": "space-between",
    "paddingRight": "0.25rem",
    "paddingLeft": "0.25rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  },
  ".hero": {
    "display": "grid",
    "width": "100%",
    "placeItems": "center",
    "backgroundSize": "cover",
    "backgroundPosition": "center"
  },
  ".hero > *": {
    "gridColumnStart": "1",
    "gridRowStart": "1"
  },
  ".hero-overlay": {
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "height": "100%",
    "width": "100%",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.5"
  },
  ".hero-content": {
    "zIndex": "0",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "maxWidth": "80rem",
    "gap": "1rem",
    "padding": "1rem"
  },
  ".indicator": {
    "position": "relative",
    "display": "inline-flex",
    "width": "max-content"
  },
  ".indicator :where(.indicator-item)": {
    "zIndex": "1",
    "position": "absolute",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "whiteSpace": "nowrap"
  },
  ".input": {
    "flexShrink": "1",
    "height": "3rem",
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "fontSize": ["0.875rem", "1rem"],
    "lineHeight": ["1.25rem", "2", "1.5rem"],
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".input-group": {
    "display": "flex",
    "width": "100%",
    "alignItems": "stretch"
  },
  ".input-group > .input": {
    "isolation": "isolate"
  },
  ".input-group > *,\n  .input-group > .input,\n  .input-group > .textarea,\n  .input-group > .select": {
    "borderRadius": "0px"
  },
  ".input-group-md": {
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".input-group-lg": {
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"]
  },
  ".input-group-sm": {
    "fontSize": "0.875rem",
    "lineHeight": "2rem"
  },
  ".input-group-xs": {
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"]
  },
  ".input-group :where(span)": {
    "display": "flex",
    "alignItems": "center",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))",
    "paddingRight": "1rem",
    "paddingLeft": "1rem"
  },
  ".input-group > :first-child": {
    "borderTopRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderTopLeftRadius": "0",
    "borderBottomRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomLeftRadius": "0"
  },
  ".input-group > :last-child": {
    "borderTopRightRadius": "0",
    "borderTopLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomRightRadius": "0",
    "borderBottomLeftRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".input-group-vertical": {
    "flexDirection": "column"
  },
  ".input-group-vertical :first-child": {
    "borderTopRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderTopLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomRightRadius": "0",
    "borderBottomLeftRadius": "0"
  },
  ".input-group-vertical :last-child": {
    "borderTopRightRadius": "0",
    "borderTopLeftRadius": "0",
    "borderBottomRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomLeftRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".join": {
    "display": "inline-flex",
    "alignItems": "stretch",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".join :where(.join-item)": {
    "borderStartEndRadius": "0",
    "borderEndEndRadius": "0",
    "borderEndStartRadius": "0",
    "borderStartStartRadius": "0"
  },
  ".join .join-item:not(:first-child):not(:last-child),\n  .join *:not(:first-child):not(:last-child) .join-item": {
    "borderStartEndRadius": "0",
    "borderEndEndRadius": "0",
    "borderEndStartRadius": "0",
    "borderStartStartRadius": "0"
  },
  ".join .join-item:first-child:not(:last-child),\n  .join *:first-child:not(:last-child) .join-item": {
    "borderStartEndRadius": "0",
    "borderEndEndRadius": "0"
  },
  ".join .dropdown .join-item:first-child:not(:last-child),\n  .join *:first-child:not(:last-child) .dropdown .join-item": {
    "borderStartEndRadius": "inherit",
    "borderEndEndRadius": "inherit"
  },
  ".join :where(.join-item:first-child:not(:last-child)),\n  .join :where(*:first-child:not(:last-child) .join-item)": {
    "borderEndStartRadius": "inherit",
    "borderStartStartRadius": "inherit"
  },
  ".join .join-item:last-child:not(:first-child),\n  .join *:last-child:not(:first-child) .join-item": {
    "borderEndStartRadius": "0",
    "borderStartStartRadius": "0"
  },
  ".join :where(.join-item:last-child:not(:first-child)),\n  .join :where(*:last-child:not(:first-child) .join-item)": {
    "borderStartEndRadius": "inherit",
    "borderEndEndRadius": "inherit"
  },
  "@supports not selector(:has(*))": {
    ":where(.join *)": {
      "borderRadius": "inherit"
    }
  },
  "@supports selector(:has(*))": {
    ":where(.join *:has(.join-item))": {
      "borderRadius": "inherit"
    }
  },
  ".kbd": {
    "display": "inline-flex",
    "alignItems": "center",
    "justifyContent": "center",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.2",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "paddingRight": "0.5rem",
    "paddingLeft": "0.5rem",
    "borderRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomWidth": "2px",
    "minHeight": "2.2em",
    "minWidth": "2.2em"
  },
  ".link": {
    "cursor": "pointer",
    "textDecorationLine": "underline"
  },
  ".link-hover": {
    "textDecorationLine": "none"
  },
  ".mask": {
    "maskSize": "contain",
    "maskRepeat": "no-repeat",
    "maskPosition": "center"
  },
  ".mask-half-1": {
    "maskSize": "200%",
    "maskPosition": "left"
  },
  ".mask-half-2": {
    "maskSize": "200%",
    "maskPosition": "right"
  },
  ".menu": {
    "display": "flex",
    "flexDirection": "column",
    "flexWrap": "wrap",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "padding": "0.5rem"
  },
  ".menu :where(li ul)": {
    "position": "relative",
    "whiteSpace": "nowrap",
    "marginRight": "1rem",
    "paddingRight": "0.5rem"
  },
  ".menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n  .menu :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "display": "grid",
    "gridAutoFlow": "column",
    "alignContent": "flex-start",
    "alignItems": "center",
    "gap": "0.5rem",
    "gridAutoColumns": "minmax(auto, max-content) auto max-content",
    "userSelect": "none"
  },
  ".menu li.disabled": {
    "cursor": "not-allowed",
    "userSelect": "none",
    "color": "hsl(var(--bc) / 0.3)"
  },
  ".menu :where(li > .menu-dropdown:not(.menu-dropdown-show))": {
    "display": "none"
  },
  ":where(.menu li)": {
    "position": "relative",
    "display": "flex",
    "flexShrink": "0",
    "flexDirection": "column",
    "flexWrap": "wrap",
    "alignItems": "stretch"
  },
  ":where(.menu li) .badge": {
    "justifySelf": "end"
  },
  ".mockup-code": {
    "position": "relative",
    "overflow": "hidden",
    "overflowX": "auto",
    "minWidth": "18rem",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "paddingTop": "1.25rem",
    "paddingBottom": "1.25rem",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".mockup-code pre[data-prefix]:before": {
    "content": ["attr(data-prefix)", "attr(data-prefix)"],
    "display": "inline-block",
    "textAlign": "left",
    "width": "2rem",
    "opacity": "0.5"
  },
  ".mockup-window": {
    "position": "relative",
    "overflow": "hidden",
    "overflowX": "auto",
    "paddingTop": "1.25rem",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".mockup-window pre[data-prefix]:before": {
    "content": "attr(data-prefix)",
    "display": "inline-block",
    "textAlign": "left"
  },
  ".mockup-browser": {
    "position": "relative",
    "overflow": "hidden",
    "overflowX": "auto",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".mockup-browser pre[data-prefix]:before": {
    "content": "attr(data-prefix)",
    "display": "inline-block",
    "textAlign": "left"
  },
  ".modal": {
    "pointerEvents": "none",
    "position": "fixed",
    "inset": "0px",
    "margin": "0px",
    "display": "grid",
    "height": "100%",
    "maxHeight": "none",
    "width": "100%",
    "maxWidth": "none",
    "justifyItems": "center",
    "padding": "0px",
    "opacity": "0",
    "overscrollBehavior": ["contain", "contain"],
    "zIndex": "999",
    "backgroundColor": "transparent",
    "color": "inherit",
    "transitionDuration": "200ms",
    "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
    "transitionProperty": "transform, opacity, visibility",
    "overflowY": "hidden"
  },
  ".modal-scroll": {
    "overscrollBehavior": "auto"
  },
  ":where(.modal)": {
    "alignItems": "center"
  },
  ".modal-box": {
    "maxHeight": "calc(100vh - 5em)",
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "width": "91.666667%",
    "maxWidth": "32rem",
    "-TwScaleX": ".9",
    "-TwScaleY": ".9",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "padding": "1.5rem",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms",
    "borderTopRightRadius": "var(--rounded-box, 1rem)",
    "borderTopLeftRadius": "var(--rounded-box, 1rem)",
    "borderBottomRightRadius": "var(--rounded-box, 1rem)",
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)",
    "boxShadow": "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
    "overflowY": "auto",
    "overscrollBehavior": "contain"
  },
  ".modal-open,\n.modal:target,\n.modal-toggle:checked + .modal,\n.modal[open]": {
    "pointerEvents": "auto",
    "visibility": "visible",
    "opacity": "1"
  },
  ".modal-action": {
    "display": "flex",
    "marginTop": "1.5rem",
    "justifyContent": "flex-end"
  },
  ".modal-toggle": {
    "position": "fixed",
    "height": "0px",
    "width": "0px",
    "appearance": "none",
    "opacity": "0"
  },
  ":root:has(:is(.modal-open, .modal:target, .modal-toggle:checked + .modal, .modal[open]))": {
    "overflow": "hidden"
  },
  ".navbar": {
    "display": "flex",
    "alignItems": "center",
    "padding": "var(--navbar-padding, 0.5rem)",
    "minHeight": "4rem",
    "width": "100%"
  },
  ":where(.navbar > *)": {
    "display": "inline-flex",
    "alignItems": "center"
  },
  ".navbar-start": {
    "width": "50%",
    "justifyContent": "flex-start"
  },
  ".navbar-center": {
    "flexShrink": "0"
  },
  ".navbar-end": {
    "width": "50%",
    "justifyContent": "flex-end"
  },
  ".progress": {
    "position": "relative",
    "width": "100%",
    "appearance": "none",
    "overflow": "hidden",
    "height": "0.5rem",
    "backgroundColor": "hsl(var(--bc) / 0.2)",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".radial-progress": {
    "position": "relative",
    "display": "inline-grid",
    "height": "var(--size)",
    "width": "var(--size)",
    "placeContent": "center",
    "borderRadius": "9999px",
    "backgroundColor": "transparent",
    "verticalAlign": "middle",
    "boxSizing": "content-box",
    "-Value": "0",
    "-Size": "5rem",
    "-Thickness": "calc(var(--size) / 10)"
  },
  ".radial-progress::-moz-progress-bar": {
    "appearance": "none",
    "backgroundColor": "transparent"
  },
  ".radial-progress::-webkit-progress-value": {
    "appearance": "none",
    "backgroundColor": "transparent"
  },
  ".radial-progress::-webkit-progress-bar": {
    "appearance": "none",
    "backgroundColor": "transparent"
  },
  ".radial-progress:before,\n.radial-progress:after": {
    "position": "absolute",
    "borderRadius": "9999px",
    "content": "\"\""
  },
  ".radial-progress:before": {
    "inset": "0px",
    "background": "radial-gradient(farthest-side, currentColor 98%, #0000) top/var(--thickness) var(--thickness)\n      no-repeat,\n    conic-gradient(currentColor calc(var(--value) * 1%), #0000 0)",
    "WebkitMask": "radial-gradient(\n    farthest-side,\n    #0000 calc(99% - var(--thickness)),\n    #000 calc(100% - var(--thickness))\n  )",
    "mask": "radial-gradient(\n    farthest-side,\n    #0000 calc(99% - var(--thickness)),\n    #000 calc(100% - var(--thickness))\n  )"
  },
  ".radial-progress:after": {
    "inset": "calc(50% - var(--thickness) / 2)",
    "transform": "rotate(calc(-1*(var(--value) * 3.6deg - 90deg))) translate(calc(-1*(var(--size) / 2 - 50%)))",
    "backgroundColor": "currentColor"
  },
  ".radio": {
    "flexShrink": "0",
    "-Chkbg": "var(--bc)",
    "height": "1.5rem",
    "width": "1.5rem",
    "cursor": "pointer",
    "appearance": "none",
    "borderRadius": "9999px",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.2"
  },
  ".range": {
    "height": "1.5rem",
    "width": "100%",
    "cursor": "pointer",
    "appearance": "none",
    "WebkitAppearance": "none",
    "-RangeShdw": "var(--bc)",
    "overflow": "hidden",
    "backgroundColor": "transparent",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".range:focus": {
    "outline": "none"
  },
  ".rating": {
    "position": "relative",
    "display": "inline-flex"
  },
  ".rating :where(input)": {
    "cursor": "pointer",
    "borderRadius": "0px",
    "animation": "rating-pop var(--animation-input, 0.25s) ease-out",
    "height": "1.5rem",
    "width": "1.5rem",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "1"
  },
  ".select": {
    "display": "inline-flex",
    "cursor": "pointer",
    "userSelect": "none",
    "appearance": "none",
    "height": "3rem",
    "paddingRight": "1rem",
    "paddingLeft": ["2.5rem", "2.5rem"],
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "minHeight": "3rem",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-btn, 0.5rem)",
    "backgroundImage": "linear-gradient(-45deg, transparent 50%, currentColor 50%),\n    linear-gradient(-135deg, currentColor 50%, transparent 50%)",
    "backgroundPosition": "calc(100% - (100% - 20px)) calc(1px + 50%),\n    calc(100% - (100% - 16.1px)) calc(1px + 50%)",
    "backgroundSize": "4px 4px,\n    4px 4px",
    "backgroundRepeat": "no-repeat"
  },
  ".select[multiple]": {
    "height": "auto"
  },
  ".stack": {
    "display": "inline-grid",
    "placeItems": "center",
    "alignItems": "flex-end"
  },
  ".stack > *": {
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "transform": "translateY(10%) scale(0.9)",
    "zIndex": "1",
    "width": "100%",
    "opacity": "0.6"
  },
  ".stack > *:nth-child(2)": {
    "transform": "translateY(5%) scale(0.95)",
    "zIndex": "2",
    "opacity": "0.8"
  },
  ".stack > *:nth-child(1)": {
    "transform": "translateY(0) scale(1)",
    "zIndex": "3",
    "opacity": "1"
  },
  ".stats": {
    "display": "inline-grid",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ":where(.stats)": {
    "gridAutoFlow": "column",
    "overflowX": "auto"
  },
  ".stat": {
    "display": "inline-grid",
    "width": "100%",
    "gridTemplateColumns": "repeat(1, 1fr)",
    "columnGap": "1rem",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "paddingRight": "1.5rem",
    "paddingLeft": "1.5rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  },
  ".stat-figure": {
    "gridColumnStart": "2",
    "gridRow": "span 3 / span 3",
    "gridRowStart": "1",
    "placeSelf": "center",
    "justifySelf": "end"
  },
  ".stat-title": {
    "gridColumnStart": "1",
    "whiteSpace": "nowrap",
    "color": "hsl(var(--bc) / 0.6)"
  },
  ".stat-value": {
    "gridColumnStart": "1",
    "whiteSpace": "nowrap",
    "fontSize": "2.25rem",
    "lineHeight": "2.5rem",
    "fontWeight": "800"
  },
  ".stat-desc": {
    "gridColumnStart": "1",
    "whiteSpace": "nowrap",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "color": "hsl(var(--bc) / 0.6)"
  },
  ".stat-actions": {
    "gridColumnStart": "1",
    "whiteSpace": "nowrap",
    "marginTop": "1rem"
  },
  ".steps": {
    "display": "inline-grid",
    "gridAutoFlow": "column",
    "overflow": "hidden",
    "overflowX": "auto",
    "counterReset": "step",
    "gridAutoColumns": "1fr"
  },
  ".steps .step": {
    "display": "grid",
    "gridTemplateColumns": ["repeat(1, minmax(0, 1fr))", "auto"],
    "gridTemplateRows": ["repeat(2, minmax(0, 1fr))", "40px 1fr"],
    "placeItems": "center",
    "textAlign": "center",
    "minWidth": "4rem"
  },
  ".swap": {
    "position": "relative",
    "display": "inline-grid",
    "userSelect": "none",
    "placeContent": "center",
    "cursor": "pointer"
  },
  ".swap > *": {
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "transitionDuration": "300ms",
    "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
    "transitionProperty": "transform, opacity"
  },
  ".swap input": {
    "appearance": "none"
  },
  ".swap .swap-on,\n.swap .swap-indeterminate,\n.swap input:indeterminate ~ .swap-on": {
    "opacity": "0"
  },
  ".swap input:checked ~ .swap-off,\n.swap-active .swap-off,\n.swap input:indeterminate ~ .swap-off": {
    "opacity": "0"
  },
  ".swap input:checked ~ .swap-on,\n.swap-active .swap-on,\n.swap input:indeterminate ~ .swap-indeterminate": {
    "opacity": "1"
  },
  ".tabs": {
    "display": "flex",
    "flexWrap": "wrap",
    "alignItems": "flex-end"
  },
  ".tab": {
    "position": "relative",
    "display": "inline-flex",
    "cursor": "pointer",
    "userSelect": "none",
    "flexWrap": "wrap",
    "alignItems": "center",
    "justifyContent": "center",
    "textAlign": "center",
    "height": "2rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "-TabPadding": "1rem",
    "-TwTextOpacity": "0.5",
    "-TabColor": "hsl(var(--bc) / var(--tw-text-opacity, 1))",
    "-TabBg": "hsl(var(--b1) / var(--tw-bg-opacity, 1))",
    "-TabBorderColor": "hsl(var(--b3) / var(--tw-bg-opacity, 1))",
    "color": "var(--tab-color)",
    "paddingRight": "var(--tab-padding, 1rem)",
    "paddingLeft": "var(--tab-padding, 1rem)"
  },
  ".table": {
    "position": "relative",
    "width": "100%",
    "textAlign": "right",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".table :where(.table-pin-rows thead tr)": {
    "position": "sticky",
    "top": "0px",
    "zIndex": "1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))"
  },
  ".table :where(.table-pin-rows tfoot tr)": {
    "position": "sticky",
    "bottom": "0px",
    "zIndex": "1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))"
  },
  ".table :where(.table-pin-cols tr th)": {
    "position": "sticky",
    "right": "0px",
    "left": "0px",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))"
  },
  ".table-zebra tbody tr:nth-child(even) :where(.table-pin-cols tr th)": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))"
  },
  ".textarea": {
    "flexShrink": "1",
    "minHeight": "3rem",
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".toast": {
    "position": "fixed",
    "display": "flex",
    "minWidth": "fit-content",
    "flexDirection": "column",
    "whiteSpace": "nowrap",
    "gap": "0.5rem",
    "padding": "1rem"
  },
  ".toggle": {
    "flexShrink": "0",
    "-Tglbg": "hsl(var(--b1))",
    "-Handleoffset": "1.5rem",
    "-Handleoffsetcalculator": "calc(var(--handleoffset) * -1)",
    "-Togglehandleborder": "0 0",
    "height": "1.5rem",
    "width": "3rem",
    "cursor": "pointer",
    "appearance": "none",
    "borderWidth": "1px",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.2",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.5",
    "borderRadius": "var(--rounded-badge, 1.9rem)",
    "transition": "background,\n    box-shadow var(--animation-input, 0.2s) ease-out",
    "boxShadow": "var(--handleoffsetcalculator) 0 0 2px var(--tglbg) inset,\n    0 0 0 2px var(--tglbg) inset,\n    var(--togglehandleborder)"
  },
  ".alert-info": {
    "borderColor": "hsl(var(--in) / 0.2)",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))",
    "-AlertBg": "hsl(var(--in))",
    "-AlertBgMix": "hsl(var(--b1))"
  },
  ".alert-success": {
    "borderColor": "hsl(var(--su) / 0.2)",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))",
    "-AlertBg": "hsl(var(--su))",
    "-AlertBgMix": "hsl(var(--b1))"
  },
  ".alert-warning": {
    "borderColor": "hsl(var(--wa) / 0.2)",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))",
    "-AlertBg": "hsl(var(--wa))",
    "-AlertBgMix": "hsl(var(--b1))"
  },
  ".alert-error": {
    "borderColor": "hsl(var(--er) / 0.2)",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))",
    "-AlertBg": "hsl(var(--er))",
    "-AlertBgMix": "hsl(var(--b1))"
  },
  ".avatar-group": {
    "display": "flex",
    "overflow": "hidden"
  },
  ".avatar-group :where(.avatar)": {
    "overflow": "hidden",
    "borderRadius": "9999px",
    "borderWidth": "4px",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b1) / var(--tw-border-opacity))"
  },
  ".badge-neutral": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--n) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))"
  },
  ".badge-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".badge-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".badge-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".badge-info": {
    "borderColor": "transparent",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".badge-success": {
    "borderColor": "transparent",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".badge-warning": {
    "borderColor": "transparent",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".badge-error": {
    "borderColor": "transparent",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".badge-ghost": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))"
  },
  ".badge-outline": {
    "borderColor": "currentColor",
    "-TwBorderOpacity": "0.5",
    "backgroundColor": "transparent",
    "color": "currentColor"
  },
  ".badge-outline.badge-neutral": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--n) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-primary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--p) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-secondary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--s) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-accent": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--a) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-info": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--in) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-success": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--su) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-warning": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wa) / var(--tw-text-opacity))"
  },
  ".badge-outline.badge-error": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--er) / var(--tw-text-opacity))"
  },
  ".btm-nav > *:not(.active)": {
    "paddingTop": "0.125rem"
  },
  ".btm-nav > *:where(.active)": {
    "borderTopWidth": "2px",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))"
  },
  ".btm-nav > *.disabled,\n    .btm-nav > *[disabled]": {
    "pointerEvents": "none",
    "-TwBorderOpacity": "0",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".btm-nav > * .label": {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  ".breadcrumbs > ul > li > a:focus, .breadcrumbs > ol > li > a:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".breadcrumbs > ul > li > a:focus-visible, .breadcrumbs > ol > li > a:focus-visible": {
    "outline": "2px solid currentColor",
    "outlineOffset": "2px"
  },
  ".breadcrumbs > ul > li + *:before, .breadcrumbs > ol > li + *:before": {
    "content": "\"\"",
    "marginRight": "0.5rem",
    "marginLeft": "0.75rem",
    "display": "block",
    "height": "0.375rem",
    "width": "0.375rem",
    "-TwRotate": "45deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "opacity": "0.4",
    "borderTop": "1px solid",
    "borderLeft": "1px solid",
    "backgroundColor": "transparent"
  },
  "[dir=\"rtl\"] .breadcrumbs > ul > li + *:before,\n[dir=\"rtl\"] .breadcrumbs > ol > li + *:before": {
    "-TwRotate": "-45deg"
  },
  ".btn:active:hover,\n  .btn:active:focus": {
    "animation": "button-pop 0s ease-out",
    "transform": "scale(var(--btn-focus-scale, 0.97))"
  },
  ".btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b3) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))"
  },
  ".btn:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px"
  },
  ".btn-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".btn-primary.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--pf) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--pf) / var(--tw-bg-opacity))"
  },
  ".btn-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".btn-secondary.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--sf) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--sf) / var(--tw-bg-opacity))"
  },
  ".btn-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".btn-accent.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--af) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--af) / var(--tw-bg-opacity))"
  },
  ".btn-neutral": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--n) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--n) / 1)"
  },
  ".btn-neutral.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--nf) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--nf) / var(--tw-bg-opacity))"
  },
  ".btn-info": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".btn-info.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))"
  },
  ".btn-success": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".btn-success.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))"
  },
  ".btn-warning": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".btn-warning.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))"
  },
  ".btn-error": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))",
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".btn-error.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))"
  },
  ".btn.glass": {
    "-TwShadow": "0 0 #0000",
    "-TwShadowColored": "0 0 #0000",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "outlineColor": "currentColor"
  },
  ".btn.glass.btn-active": {
    "-GlassOpacity": "25%",
    "-GlassBorderOpacity": "15%"
  },
  ".btn-ghost": {
    "borderWidth": "1px",
    "borderColor": "transparent",
    "backgroundColor": "transparent",
    "color": "currentColor",
    "-TwShadow": "0 0 #0000",
    "-TwShadowColored": "0 0 #0000",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "outlineColor": "currentColor"
  },
  ".btn-ghost.btn-active": {
    "-TwBorderOpacity": "0",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.2"
  },
  ".btn-link": {
    "borderColor": "transparent",
    "backgroundColor": "transparent",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--p) / var(--tw-text-opacity))",
    "textDecorationLine": "underline",
    "-TwShadow": "0 0 #0000",
    "-TwShadowColored": "0 0 #0000",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "outlineColor": "currentColor"
  },
  ".btn-link.btn-active": {
    "borderColor": "transparent",
    "backgroundColor": "transparent",
    "textDecorationLine": "underline"
  },
  ".btn-outline": {
    "borderColor": "currentColor",
    "backgroundColor": "transparent",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "-TwShadow": "0 0 #0000",
    "-TwShadowColored": "0 0 #0000",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  },
  ".btn-outline.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--b1) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-primary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--p) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-primary.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--pf) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--pf) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-secondary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--s) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-secondary.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--sf) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--sf) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-accent": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--a) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-accent.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--af) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--af) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-success": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--su) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-success.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-info": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--in) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-info.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-warning": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wa) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-warning.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-error": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--er) / var(--tw-text-opacity))"
  },
  ".btn-outline.btn-error.btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".btn.btn-disabled,\n  .btn[disabled],\n  .btn:disabled": {
    "-TwBorderOpacity": "0",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.2",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".btn-group > input[type=\"radio\"]:checked.btn,\n  .btn-group > .btn-active": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".btn-group > input[type=\"radio\"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".btn:is(input[type=\"checkbox\"]:checked),\n.btn:is(input[type=\"radio\"]:checked)": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".btn:is(input[type=\"checkbox\"]:checked):focus-visible, .btn:is(input[type=\"radio\"]:checked):focus-visible": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  "@keyframes button-pop": {
    "0%": {
      "transform": "scale(var(--btn-focus-scale, 0.98))"
    },
    "40%": {
      "transform": "scale(1.02)"
    },
    "100%": {
      "transform": "scale(1)"
    }
  },
  ".card :where(figure:first-child)": {
    "overflow": "hidden",
    "borderStartStartRadius": "inherit",
    "borderStartEndRadius": "inherit",
    "borderEndStartRadius": "unset",
    "borderEndEndRadius": "unset"
  },
  ".card :where(figure:last-child)": {
    "overflow": "hidden",
    "borderStartStartRadius": "unset",
    "borderStartEndRadius": "unset",
    "borderEndStartRadius": "inherit",
    "borderEndEndRadius": "inherit"
  },
  ".card:focus-visible": {
    "outline": "2px solid currentColor",
    "outlineOffset": "2px"
  },
  ".card.bordered": {
    "borderWidth": "1px",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))"
  },
  ".card-bordered": {
    "borderWidth": "1px",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))"
  },
  ".card.compact .card-body": {
    "padding": "1rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".card-title": {
    "display": "flex",
    "alignItems": "center",
    "gap": "0.5rem",
    "fontSize": "1.25rem",
    "lineHeight": "1.75rem",
    "fontWeight": "600"
  },
  ".card.image-full :where(figure)": {
    "overflow": "hidden",
    "borderRadius": "inherit"
  },
  ".carousel::-webkit-scrollbar": {
    "display": "none"
  },
  ".chat-bubble-primary": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".chat-bubble-secondary": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".chat-bubble-accent": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".chat-bubble-info": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".chat-bubble-success": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".chat-bubble-warning": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".chat-bubble-error": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".checkbox:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 1)"
  },
  ".checkbox:checked,\n  .checkbox[checked=\"true\"],\n  .checkbox[aria-checked=\"true\"]": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "backgroundRepeat": "no-repeat",
    "animation": "checkmark var(--animation-input, 0.2s) ease-out",
    "backgroundImage": "linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%),\n      linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%),\n      linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%),\n      linear-gradient(\n        -45deg,\n        hsl(var(--chkbg)) 30%,\n        hsl(var(--chkfg)) 30.99%,\n        hsl(var(--chkfg)) 40%,\n        transparent 40.99%\n      ),\n      linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%)"
  },
  ".checkbox:indeterminate": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "backgroundRepeat": "no-repeat",
    "animation": "checkmark var(--animation-input, 0.2s) ease-out",
    "backgroundImage": "linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%),\n      linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%),\n      linear-gradient(\n        0deg,\n        hsl(var(--chkbg)) 43%,\n        hsl(var(--chkfg)) 43%,\n        hsl(var(--chkfg)) 57%,\n        hsl(var(--chkbg)) 57%\n      )"
  },
  ".checkbox-primary": {
    "-Chkbg": "var(--p)",
    "-Chkfg": "var(--pc)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".checkbox-primary:focus-visible": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".checkbox-primary:checked,\n    .checkbox-primary[checked=\"true\"],\n    .checkbox-primary[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".checkbox-secondary": {
    "-Chkbg": "var(--s)",
    "-Chkfg": "var(--sc)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".checkbox-secondary:focus-visible": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".checkbox-secondary:checked,\n    .checkbox-secondary[checked=\"true\"],\n    .checkbox-secondary[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".checkbox-accent": {
    "-Chkbg": "var(--a)",
    "-Chkfg": "var(--ac)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".checkbox-accent:focus-visible": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".checkbox-accent:checked,\n    .checkbox-accent[checked=\"true\"],\n    .checkbox-accent[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".checkbox-success": {
    "-Chkbg": "var(--su)",
    "-Chkfg": "var(--suc)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".checkbox-success:focus-visible": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".checkbox-success:checked,\n    .checkbox-success[checked=\"true\"],\n    .checkbox-success[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".checkbox-warning": {
    "-Chkbg": "var(--wa)",
    "-Chkfg": "var(--wac)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".checkbox-warning:focus-visible": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".checkbox-warning:checked,\n    .checkbox-warning[checked=\"true\"],\n    .checkbox-warning[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".checkbox-info": {
    "-Chkbg": "var(--in)",
    "-Chkfg": "var(--inc)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".checkbox-info:focus-visible": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".checkbox-info:checked,\n    .checkbox-info[checked=\"true\"],\n    .checkbox-info[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".checkbox-error": {
    "-Chkbg": "var(--er)",
    "-Chkfg": "var(--erc)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".checkbox-error:focus-visible": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".checkbox-error:checked,\n    .checkbox-error[checked=\"true\"],\n    .checkbox-error[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".checkbox:disabled": {
    "cursor": "not-allowed",
    "borderColor": "transparent",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "opacity": "0.2"
  },
  "@keyframes checkmark": {
    "0%": {
      "backgroundPositionY": "5px"
    },
    "50%": {
      "backgroundPositionY": "-2px"
    },
    "100%": {
      "backgroundPositionY": "0"
    }
  },
  ".checkbox-mark": {
    "display": "none"
  },
  "[dir=\"rtl\"] .checkbox:checked,\n    [dir=\"rtl\"] .checkbox[checked=\"true\"],\n    [dir=\"rtl\"] .checkbox[aria-checked=\"true\"]": {
    "backgroundImage": "linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%),\n        linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%),\n        linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%),\n        linear-gradient(\n          45deg,\n          hsl(var(--chkbg)) 30%,\n          hsl(var(--chkfg)) 30.99%,\n          hsl(var(--chkfg)) 40%,\n          transparent 40.99%\n        ),\n        linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%)"
  },
  "details.collapse": {
    "width": "100%"
  },
  "details.collapse summary": {
    "position": "relative",
    "display": "block",
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  "details.collapse summary::-webkit-details-marker": {
    "display": "none"
  },
  ".collapse:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 1)"
  },
  ".collapse:has(.collapse-title:focus-visible),\n.collapse:has(> input[type=\"checkbox\"]:focus-visible),\n.collapse:has(> input[type=\"radio\"]:focus-visible)": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 1)"
  },
  ".collapse-arrow > .collapse-title:after": {
    "position": "absolute",
    "display": "block",
    "height": "0.5rem",
    "width": "0.5rem",
    "-TwTranslateY": "-100%",
    "-TwRotate": "45deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "transitionProperty": "all",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": ["150ms", "0.2s"],
    "top": "50%",
    "left": "1.4rem",
    "content": "\"\"",
    "transformOrigin": "25% 75%",
    "boxShadow": "-2px 2px",
    "pointerEvents": "none"
  },
  "[dir=\"rtl\"] .collapse-arrow > .collapse-title:after": {
    "-TwRotate": "-45deg"
  },
  ".collapse-plus > .collapse-title:after": {
    "position": "absolute",
    "display": "block",
    "height": "0.5rem",
    "width": "0.5rem",
    "transitionProperty": "all",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "300ms",
    "top": "0.9rem",
    "left": "1.4rem",
    "content": "\"+\"",
    "pointerEvents": "none"
  },
  ".collapse:not(.collapse-open):not(.collapse-close) > input[type=\"checkbox\"],\n.collapse:not(.collapse-open):not(.collapse-close) > input[type=\"radio\"]:not(:checked),\n.collapse:not(.collapse-open):not(.collapse-close) > .collapse-title": {
    "cursor": "pointer"
  },
  ".collapse:focus:not(.collapse-open):not(.collapse-close):not(.collapse[open]) > .collapse-title": {
    "cursor": "unset"
  },
  ".collapse-title": {
    "position": "relative"
  },
  ":where(.collapse > input[type=\"checkbox\"]),\n:where(.collapse > input[type=\"radio\"])": {
    "zIndex": "1"
  },
  ".collapse-title,\n:where(.collapse > input[type=\"checkbox\"]),\n:where(.collapse > input[type=\"radio\"])": {
    "width": "100%",
    "padding": "1rem",
    "paddingLeft": "3rem",
    "minHeight": "3.75rem",
    "transition": "background-color 0.2s ease-out"
  },
  ".collapse[open] > :where(.collapse-content),\n.collapse-open > :where(.collapse-content),\n.collapse:focus:not(.collapse-close) > :where(.collapse-content),\n.collapse:not(.collapse-close) > :where(input[type=\"checkbox\"]:checked ~ .collapse-content),\n.collapse:not(.collapse-close) > :where(input[type=\"radio\"]:checked ~ .collapse-content)": {
    "paddingBottom": "1rem",
    "transition": "padding 0.2s ease-out,\n    background-color 0.2s ease-out"
  },
  ".collapse[open].collapse-arrow > .collapse-title:after,\n.collapse-open.collapse-arrow > .collapse-title:after,\n.collapse-arrow:focus:not(.collapse-close) > .collapse-title:after,\n.collapse-arrow:not(.collapse-close) > input[type=\"checkbox\"]:checked ~ .collapse-title:after,\n.collapse-arrow:not(.collapse-close) > input[type=\"radio\"]:checked ~ .collapse-title:after": {
    "-TwTranslateY": "-50%",
    "-TwRotate": "225deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  "[dir=\"rtl\"] .collapse[open].collapse-arrow > .collapse-title:after,\n[dir=\"rtl\"] .collapse-open.collapse-arrow > .collapse-title:after,\n[dir=\"rtl\"] .collapse-arrow:focus:not(.collapse-close) .collapse-title:after,\n[dir=\"rtl\"]\n  .collapse-arrow:not(.collapse-close)\n  input[type=\"checkbox\"]:checked\n  ~ .collapse-title:after": {
    "-TwRotate": "135deg"
  },
  ".collapse[open].collapse-plus > .collapse-title:after,\n.collapse-open.collapse-plus > .collapse-title:after,\n.collapse-plus:focus:not(.collapse-close) > .collapse-title:after,\n.collapse-plus:not(.collapse-close) > input[type=\"checkbox\"]:checked ~ .collapse-title:after,\n.collapse-plus:not(.collapse-close) > input[type=\"radio\"]:checked ~ .collapse-title:after": {
    "content": "\"−\""
  },
  ".divider:before": {
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.1"
  },
  ".divider:after": {
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.1"
  },
  ".divider:not(:empty)": {
    "gap": "1rem"
  },
  ".drawer-toggle:checked ~ .drawer-side > .drawer-overlay": {
    "backgroundColor": "hsl(0 0% 0%/0.4)"
  },
  ".drawer-toggle:focus-visible ~ .drawer-content label.drawer-button": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px"
  },
  ".dropdown.dropdown-open .dropdown-content,\n.dropdown:focus .dropdown-content,\n.dropdown:focus-within .dropdown-content": {
    "-TwScaleX": "1",
    "-TwScaleY": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".file-input-bordered": {
    "-TwBorderOpacity": "0.2"
  },
  ".file-input:focus": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 0.2)"
  },
  ".file-input-ghost": {
    "-TwBgOpacity": "0.05"
  },
  ".file-input-ghost:focus": {
    "-TwBgOpacity": "1",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "boxShadow": "none"
  },
  ".file-input-ghost::file-selector-button": {
    "borderWidth": "1px",
    "borderColor": "transparent",
    "backgroundColor": "transparent",
    "color": "currentColor"
  },
  ".file-input-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".file-input-primary:focus": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".file-input-primary::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".file-input-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".file-input-secondary:focus": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".file-input-secondary::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".file-input-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".file-input-accent:focus": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".file-input-accent::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".file-input-info": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".file-input-info:focus": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".file-input-info::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".file-input-success": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".file-input-success:focus": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".file-input-success::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".file-input-warning": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".file-input-warning:focus": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".file-input-warning::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".file-input-error": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".file-input-error:focus": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".file-input-error::file-selector-button": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".file-input-disabled,\n  .file-input[disabled]": {
    "cursor": "not-allowed",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".file-input-disabled::placeholder,\n  .file-input[disabled]::placeholder": {
    "color": "hsl(var(--bc) / var(--tw-placeholder-opacity))",
    "-TwPlaceholderOpacity": "0.2"
  },
  ".file-input-disabled::file-selector-button, .file-input[disabled]::file-selector-button": {
    "-TwBorderOpacity": "0",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwBgOpacity": "0.2",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".footer-title": {
    "marginBottom": "0.5rem",
    "fontWeight": "700",
    "textTransform": "uppercase",
    "opacity": "0.5"
  },
  ".label-text": {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))"
  },
  ".label-text-alt": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))"
  },
  ".input input:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".input[list]::-webkit-calendar-picker-indicator": {
    "lineHeight": "1em"
  },
  ".input-bordered": {
    "-TwBorderOpacity": "0.2"
  },
  ".input:focus,\n  .input:focus-within": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 0.2)"
  },
  ".input-ghost": {
    "-TwBgOpacity": "0.05"
  },
  ".input-ghost:focus,\n    .input-ghost:focus-within": {
    "-TwBgOpacity": "1",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "boxShadow": "none"
  },
  ".input-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".input-primary:focus,\n    .input-primary:focus-within": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".input-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".input-secondary:focus,\n    .input-secondary:focus-within": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".input-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".input-accent:focus,\n    .input-accent:focus-within": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".input-info": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".input-info:focus,\n    .input-info:focus-within": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".input-success": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".input-success:focus,\n    .input-success:focus-within": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".input-warning": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".input-warning:focus,\n    .input-warning:focus-within": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".input-error": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".input-error:focus,\n    .input-error:focus-within": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".input-disabled,\n  .input:disabled,\n  .input[disabled]": {
    "cursor": "not-allowed",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".input-disabled::placeholder,\n  .input:disabled::placeholder,\n  .input[disabled]::placeholder": {
    "color": "hsl(var(--bc) / var(--tw-placeholder-opacity))",
    "-TwPlaceholderOpacity": "0.2"
  },
  ".join > :where(*:not(:first-child))": {
    "marginTop": "0px",
    "marginBottom": "0px",
    "marginRight": "-1px"
  },
  ".join-item:focus": {
    "isolation": "isolate"
  },
  ".link-primary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--p) / var(--tw-text-opacity))"
  },
  ".link-secondary": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--s) / var(--tw-text-opacity))"
  },
  ".link-accent": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--a) / var(--tw-text-opacity))"
  },
  ".link-neutral": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--n) / var(--tw-text-opacity))"
  },
  ".link-success": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--su) / var(--tw-text-opacity))"
  },
  ".link-info": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--in) / var(--tw-text-opacity))"
  },
  ".link-warning": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wa) / var(--tw-text-opacity))"
  },
  ".link-error": {
    "-TwTextOpacity": "1",
    "color": "hsl(var(--er) / var(--tw-text-opacity))"
  },
  ".link:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".link:focus-visible": {
    "outline": "2px solid currentColor",
    "outlineOffset": "2px"
  },
  ".loading": {
    "pointerEvents": "none",
    "display": "inline-block",
    "aspectRatio": "1 / 1",
    "width": "1.5rem",
    "backgroundColor": "currentColor",
    "maskSize": "100%",
    "maskRepeat": "no-repeat",
    "maskPosition": "center",
    "maskImage": "url(\"data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E\")"
  },
  ".loading-spinner": {
    "maskImage": "url(\"data:image/svg+xml,%3Csvg width='24' height='24' stroke='%23000' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_V8m1%7Btransform-origin:center;animation:spinner_zKoa 2s linear infinite%7D.spinner_V8m1 circle%7Bstroke-linecap:round;animation:spinner_YpZS 1.5s ease-out infinite%7D%40keyframes spinner_zKoa%7B100%25%7Btransform:rotate(360deg)%7D%7D%40keyframes spinner_YpZS%7B0%25%7Bstroke-dasharray:0 150;stroke-dashoffset:0%7D47.5%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-16%7D95%25%2C100%25%7Bstroke-dasharray:42 150;stroke-dashoffset:-59%7D%7D%3C%2Fstyle%3E%3Cg class='spinner_V8m1'%3E%3Ccircle cx='12' cy='12' r='9.5' fill='none' stroke-width='3'%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E\")"
  },
  ".loading-dots": {
    "maskImage": "url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_qM83%7Banimation:spinner_8HQG 1.05s infinite%7D.spinner_oXPr%7Banimation-delay:.1s%7D.spinner_ZTLf%7Banimation-delay:.2s%7D@keyframes spinner_8HQG%7B0%25,57.14%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);transform:translate(0)%7D28.57%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);transform:translateY(-6px)%7D100%25%7Btransform:translate(0)%7D%7D%3C/style%3E%3Ccircle class='spinner_qM83' cx='4' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_oXPr' cx='12' cy='12' r='3'/%3E%3Ccircle class='spinner_qM83 spinner_ZTLf' cx='20' cy='12' r='3'/%3E%3C/svg%3E\")"
  },
  ".loading-ring": {
    "maskImage": "url(\"data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' xmlns='http://www.w3.org/2000/svg' stroke='%23fff'%3E%3Cg fill='none' fill-rule='evenodd' stroke-width='2'%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='0s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='0s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='1'%3E%3Canimate attributeName='r' begin='-0.9s' dur='1.8s' values='1; 20' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate attributeName='stroke-opacity' begin='-0.9s' dur='1.8s' values='1; 0' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E\")"
  },
  ".loading-ball": {
    "maskImage": "url(\"data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_rXNP%7Banimation:spinner_YeBj .8s infinite%7D@keyframes spinner_YeBj%7B0%25%7Banimation-timing-function:cubic-bezier(0.33,0,.66,.33);cy:5px%7D46.875%25%7Bcy:20px;rx:4px;ry:4px%7D50%25%7Banimation-timing-function:cubic-bezier(0.33,.66,.66,1);cy:20.5px;rx:4.8px;ry:3px%7D53.125%25%7Brx:4px;ry:4px%7D100%25%7Bcy:5px%7D%7D%3C/style%3E%3Cellipse class='spinner_rXNP' cx='12' cy='5' rx='4' ry='4'/%3E%3C/svg%3E\")"
  },
  ".loading-bars": {
    "maskImage": "url(\"data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cstyle%3E.spinner_hzlK%7Banimation:spinner_vc4H .8s linear infinite;animation-delay:-.8s%7D.spinner_koGT%7Banimation-delay:-.65s%7D.spinner_YF1u%7Banimation-delay:-.5s%7D@keyframes spinner_vc4H%7B0%25%7By:1px;height:22px%7D93.75%25%7By:5px;height:14px;opacity:.2%7D%7D%3C/style%3E%3Crect class='spinner_hzlK' x='1' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_koGT' x='9' y='1' width='6' height='22'/%3E%3Crect class='spinner_hzlK spinner_YF1u' x='17' y='1' width='6' height='22'/%3E%3C/svg%3E\")"
  },
  ".loading-infinity": {
    "maskImage": "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='shape-rendering: auto;' width='200px' height='200px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Cpath fill='none' stroke='%230a0a0a' stroke-width='10' stroke-dasharray='205.271142578125 51.317785644531256' d='M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z' stroke-linecap='round' style='transform:scale(0.8);transform-origin:50px 50px'%3E%3Canimate attributeName='stroke-dashoffset' repeatCount='indefinite' dur='2s' keyTimes='0;1' values='0;256.58892822265625'%3E%3C/animate%3E%3C/path%3E%3C/svg%3E\")"
  },
  ".loading-xs": {
    "width": "1rem"
  },
  ".loading-sm": {
    "width": "1.25rem"
  },
  ".loading-md": {
    "width": "1.5rem"
  },
  ".loading-lg": {
    "width": "2.5rem"
  },
  ".mask-squircle": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0Z'/%3e%3c/svg%3e\")"
  },
  ".mask-decagon": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='192' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 58.779 19.098 36.327 50v61.804l-36.327 50L96 200l-58.779-19.098-36.327-50V69.098l36.327-50z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-diamond": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m100 0 100 100-100 100L0 100z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-heart": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='185' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M100 184.606a15.384 15.384 0 0 1-8.653-2.678C53.565 156.28 37.205 138.695 28.182 127.7 8.952 104.264-.254 80.202.005 54.146.308 24.287 24.264 0 53.406 0c21.192 0 35.869 11.937 44.416 21.879a2.884 2.884 0 0 0 4.356 0C110.725 11.927 125.402 0 146.594 0c29.142 0 53.098 24.287 53.4 54.151.26 26.061-8.956 50.122-28.176 73.554-9.023 10.994-25.383 28.58-63.165 54.228a15.384 15.384 0 0 1-8.653 2.673Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e\")"
  },
  ".mask-hexagon": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='182' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0L14.51 161.62C6.151 157.44.3 145.737.3 136.54V65.486Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e\")"
  },
  ".mask-hexagon-2": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='182' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M64.786 181.4c-9.196 0-20.063-6.687-25.079-14.21L3.762 105.33c-5.016-8.36-5.016-20.9 0-29.259l35.945-61.86C44.723 5.851 55.59 0 64.786 0h71.055c9.196 0 20.063 6.688 25.079 14.211l35.945 61.86c4.18 8.36 4.18 20.899 0 29.258l-35.945 61.86c-4.18 8.36-15.883 14.211-25.079 14.211H64.786Z' fill='black' fill-rule='nonzero'/%3e%3c/svg%3e\")"
  },
  ".mask-circle": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='black' cx='100' cy='100' r='100' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-parallelogram": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M46.154 0H200l-46.154 153.846H0z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-parallelogram-2": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='154' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.846 0H0l46.154 153.846H200z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-parallelogram-3": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.077 47.077v153.846l153.846-46.154V.923z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-parallelogram-4": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='154' height='201' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M153.923 47.077v153.846L.077 154.77V.923z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-pentagon": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='192' height='181' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 0 95.106 69.098-36.327 111.804H37.22L.894 69.098z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-square": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='200' height='200' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M0 0h200v200H0z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-star": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 137.263-58.779 42.024 22.163-68.389L.894 68.481l72.476-.243L96 0l22.63 68.238 72.476.243-58.49 42.417 22.163 68.389z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-star-2": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='192' height='180' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m96 153.044-58.779 26.243 7.02-63.513L.894 68.481l63.117-13.01L96 0l31.989 55.472 63.117 13.01-43.347 47.292 7.02 63.513z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-triangle": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='174' height='149' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 148.476-86.603.185L43.86 74.423 87 0l43.14 74.423 43.463 74.238z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-triangle-2": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='174' height='150' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m87 .738 86.603-.184-43.463 74.238L87 149.214 43.86 74.792.397.554z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-triangle-3": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m149.369 87.107.185 86.603-74.239-43.463L.893 87.107l74.422-43.14L149.554.505z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ".mask-triangle-4": {
    "maskImage": "url(\"data:image/svg+xml,%3csvg width='150' height='174' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='M.631 87.107.446.505l74.239 43.462 74.422 43.14-74.422 43.14L.446 173.71z' fill-rule='evenodd'/%3e%3c/svg%3e\")"
  },
  ":where(.menu li:empty)": {
    "backgroundColor": "hsl(var(--bc) / 0.1)",
    "margin": "0.5rem 1rem",
    "height": "1px"
  },
  ".menu :where(li ul):before": {
    "position": "absolute",
    "bottom": "0.75rem",
    "right": "0px",
    "top": "0.75rem",
    "width": "1px",
    "backgroundColor": "hsl(var(--bc) / 0.1)",
    "content": "\"\""
  },
  ".menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n.menu :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "textAlign": "right",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "200ms",
    "borderRadius": "var(--rounded-btn, 0.5rem)",
    "textWrap": "balance"
  },
  ":where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(summary):not(.active).focus,\n  :where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):not(summary):not(.active):focus,\n  :where(.menu li:not(.menu-title):not(.disabled) > *:not(ul):not(details):not(.menu-title)):is(summary):not(.active):focus-visible,\n  :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active).focus,\n  :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):not(summary):not(.active):focus,\n  :where(.menu li:not(.menu-title):not(.disabled) > details > summary:not(.menu-title)):is(summary):not(.active):focus-visible": {
    "cursor": "pointer",
    "backgroundColor": "hsl(var(--bc) / 0.1)",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".menu li > *:not(ul):not(.menu-title):not(details):active,\n.menu li > *:not(ul):not(.menu-title):not(details).active,\n.menu li > details > summary:active": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))"
  },
  ".menu :where(li > details > summary)::-webkit-details-marker": {
    "display": "none"
  },
  ".menu :where(li > details > summary):after,\n.menu :where(li > .menu-dropdown-toggle):after": {
    "justifySelf": "end",
    "display": "block",
    "marginTop": "-0.5rem",
    "height": "0.5rem",
    "width": "0.5rem",
    "transform": "rotate(-45deg)",
    "transitionProperty": "transform, margin-top",
    "transitionDuration": "0.3s",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "content": "\"\"",
    "transformOrigin": "25% 75%",
    "boxShadow": "-2px 2px",
    "pointerEvents": "none"
  },
  ".menu :where(li > details[open] > summary):after,\n.menu :where(li > .menu-dropdown-toggle.menu-dropdown-show):after": {
    "transform": "rotate(-225deg)",
    "marginTop": "0"
  },
  ".menu-title": {
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "700",
    "color": "hsl(var(--bc) / 0.4)"
  },
  ".mockup-code:before": {
    "content": "\"\"",
    "marginBottom": "1rem",
    "display": "block",
    "height": "0.75rem",
    "width": "0.75rem",
    "borderRadius": "9999px",
    "opacity": "0.3",
    "boxShadow": "-1.4em 0,\n        -2.8em 0,\n        -4.2em 0"
  },
  ".mockup-code pre": {
    "paddingLeft": "1.25rem"
  },
  ".mockup-code pre:before": {
    "content": "\"\"",
    "marginLeft": "2ch"
  },
  ".mockup-window:before": {
    "content": "\"\"",
    "marginBottom": "1rem",
    "display": "block",
    "aspectRatio": "1 / 1",
    "height": "0.75rem",
    "borderRadius": "9999px",
    "opacity": "0.3",
    "boxShadow": "-1.4em 0,\n        -2.8em 0,\n        -4.2em 0"
  },
  ".mockup-phone": {
    "display": "inline-block",
    "border": "4px solid #444",
    "borderRadius": "50px",
    "backgroundColor": "#000",
    "padding": "10px",
    "margin": "0 auto",
    "overflow": "hidden"
  },
  ".mockup-phone .camera": {
    "position": "relative",
    "top": "0px",
    "right": "0px",
    "background": "#000",
    "height": "25px",
    "width": "150px",
    "margin": "0 auto",
    "borderBottomRightRadius": "17px",
    "borderBottomLeftRadius": "17px",
    "zIndex": "11"
  },
  ".mockup-phone .camera:before": {
    "content": "\"\"",
    "position": "absolute",
    "top": "35%",
    "right": "50%",
    "width": "50px",
    "height": "4px",
    "borderRadius": "5px",
    "backgroundColor": "#0c0b0e",
    "transform": "translate(50%, -50%)"
  },
  ".mockup-phone .camera:after": {
    "content": "\"\"",
    "position": "absolute",
    "top": "20%",
    "right": "70%",
    "width": "8px",
    "height": "8px",
    "borderRadius": "5px",
    "backgroundColor": "#0f0b25"
  },
  ".mockup-phone .display": {
    "overflow": "hidden",
    "borderRadius": "40px",
    "marginTop": "-25px"
  },
  ".mockup-browser .mockup-browser-toolbar": {
    "marginTop": "0.75rem",
    "marginBottom": "0.75rem",
    "display": "inline-flex",
    "width": "100%",
    "alignItems": "center",
    "paddingLeft": "1.4em"
  },
  ".mockup-browser .mockup-browser-toolbar:before": {
    "content": "\"\"",
    "marginLeft": "4.8rem",
    "display": "inline-block",
    "aspectRatio": "1 / 1",
    "height": "0.75rem",
    "borderRadius": "9999px",
    "opacity": "0.3",
    "boxShadow": "-1.4em 0,\n          -2.8em 0,\n          -4.2em 0"
  },
  ".mockup-browser .mockup-browser-toolbar .input": {
    "position": "relative",
    "marginRight": "auto",
    "marginLeft": "auto",
    "display": "block",
    "height": "1.75rem",
    "width": "24rem",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "paddingRight": "2rem"
  },
  ".mockup-browser .mockup-browser-toolbar .input:before": {
    "content": "\"\"",
    "position": "absolute",
    "right": "0.5rem",
    "top": "50%",
    "aspectRatio": "1 / 1",
    "height": "0.75rem",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderRadius": "9999px",
    "borderWidth": "2px",
    "borderColor": "currentColor",
    "opacity": "0.6"
  },
  ".mockup-browser .mockup-browser-toolbar .input:after": {
    "content": "\"\"",
    "position": "absolute",
    "right": "1.25rem",
    "top": "50%",
    "height": "0.5rem",
    "-TwTranslateY": "25%",
    "-TwRotate": "-45deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderRadius": "9999px",
    "borderWidth": "1px",
    "borderColor": "currentColor",
    "opacity": "0.6"
  },
  ".modal:not(dialog:not(.modal-open)),\n  .modal::backdrop": {
    "backgroundColor": "rgba(0, 0, 0, 0.3)",
    "animation": "modal-pop 0.2s ease-out"
  },
  ".modal-backdrop": {
    "zIndex": "-1",
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "display": "grid",
    "alignSelf": "stretch",
    "justifySelf": "stretch",
    "color": "transparent"
  },
  ".modal-open .modal-box,\n.modal-toggle:checked + .modal .modal-box,\n.modal:target .modal-box,\n.modal[open] .modal-box": {
    "-TwTranslateY": "0px",
    "-TwScaleX": "1",
    "-TwScaleY": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".modal-action > :not([hidden]) ~ :not([hidden])": {
    "-TwSpaceXReverse": "0",
    "marginLeft": "calc(0.5rem * var(--tw-space-x-reverse))",
    "marginRight": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
  },
  "@keyframes modal-pop": {
    "0%": {
      "opacity": "0"
    }
  },
  ".progress::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-primary::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-secondary::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-accent::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-info::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-success::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-warning::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-error::-moz-progress-bar": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress:indeterminate": {
    "-ProgressColor": "hsl(var(--bc))",
    "backgroundImage": "repeating-linear-gradient(\n    -90deg,\n    var(--progress-color) -1%,\n    var(--progress-color) 10%,\n    transparent 10%,\n    transparent 90%\n  )",
    "backgroundSize": "200%",
    "backgroundPositionX": "85%",
    "animation": "progress-loading 5s ease-in-out infinite"
  },
  ".progress-primary:indeterminate": {
    "-ProgressColor": "hsl(var(--p))"
  },
  ".progress-secondary:indeterminate": {
    "-ProgressColor": "hsl(var(--s))"
  },
  ".progress-accent:indeterminate": {
    "-ProgressColor": "hsl(var(--a))"
  },
  ".progress-info:indeterminate": {
    "-ProgressColor": "hsl(var(--in))"
  },
  ".progress-success:indeterminate": {
    "-ProgressColor": "hsl(var(--su))"
  },
  ".progress-warning:indeterminate": {
    "-ProgressColor": "hsl(var(--wa))"
  },
  ".progress-error:indeterminate": {
    "-ProgressColor": "hsl(var(--er))"
  },
  ".progress::-webkit-progress-bar": {
    "backgroundColor": "transparent",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".progress-primary::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))"
  },
  ".progress-secondary::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))"
  },
  ".progress-accent::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))"
  },
  ".progress-info::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))"
  },
  ".progress-success::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))"
  },
  ".progress-warning::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))"
  },
  ".progress-error::-webkit-progress-value": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))"
  },
  ".progress:indeterminate::-moz-progress-bar": {
    "backgroundColor": "transparent",
    "backgroundImage": "repeating-linear-gradient(\n    -90deg,\n    var(--progress-color) -1%,\n    var(--progress-color) 10%,\n    transparent 10%,\n    transparent 90%\n  )",
    "backgroundSize": "200%",
    "backgroundPositionX": "85%",
    "animation": "progress-loading 5s ease-in-out infinite"
  },
  "@keyframes progress-loading": {
    "50%": {
      "backgroundPositionX": "215%"
    }
  },
  ".radio:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 1)"
  },
  ".radio:checked,\n  .radio[aria-checked=\"true\"]": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--bc) / var(--tw-bg-opacity))",
    "animation": "radiomark var(--animation-input, 0.2s) ease-out",
    "boxShadow": "0 0 0 4px hsl(var(--b1)) inset,\n      0 0 0 4px hsl(var(--b1)) inset"
  },
  ".radio-primary": {
    "-Chkbg": "var(--p)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".radio-primary:focus-visible": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".radio-primary:checked,\n    .radio-primary[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".radio-secondary": {
    "-Chkbg": "var(--s)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".radio-secondary:focus-visible": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".radio-secondary:checked,\n    .radio-secondary[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".radio-accent": {
    "-Chkbg": "var(--a)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".radio-accent:focus-visible": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".radio-accent:checked,\n    .radio-accent[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".radio-success": {
    "-Chkbg": "var(--su)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".radio-success:focus-visible": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".radio-success:checked,\n    .radio-success[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".radio-warning": {
    "-Chkbg": "var(--wa)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".radio-warning:focus-visible": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".radio-warning:checked,\n    .radio-warning[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".radio-info": {
    "-Chkbg": "var(--in)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".radio-info:focus-visible": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".radio-info:checked,\n    .radio-info[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".radio-error": {
    "-Chkbg": "var(--er)",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".radio-error:focus-visible": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".radio-error:checked,\n    .radio-error[aria-checked=\"true\"]": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".radio:disabled": {
    "cursor": "not-allowed",
    "opacity": "0.2"
  },
  "@keyframes radiomark": {
    "0%": {
      "boxShadow": "0 0 0 12px hsl(var(--b1)) inset,\n      0 0 0 12px hsl(var(--b1)) inset"
    },
    "50%": {
      "boxShadow": "0 0 0 3px hsl(var(--b1)) inset,\n      0 0 0 3px hsl(var(--b1)) inset"
    },
    "100%": {
      "boxShadow": "0 0 0 4px hsl(var(--b1)) inset,\n      0 0 0 4px hsl(var(--b1)) inset"
    }
  },
  ".radio-mark": {
    "display": "none"
  },
  ".range:focus-visible::-webkit-slider-thumb": {
    "-FocusShadow": "0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset"
  },
  ".range:focus-visible::-moz-range-thumb": {
    "-FocusShadow": "0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset"
  },
  ".range::-webkit-slider-runnable-track": {
    "height": "0.5rem",
    "width": "100%",
    "backgroundColor": "hsl(var(--bc) / 0.1)",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".range::-moz-range-track": {
    "height": "0.5rem",
    "width": "100%",
    "backgroundColor": "hsl(var(--bc) / 0.1)",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".range::-webkit-slider-thumb": {
    "position": "relative",
    "height": "1.5rem",
    "width": "1.5rem",
    "borderStyle": "none",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)",
    "appearance": "none",
    "WebkitAppearance": "none",
    "top": "50%",
    "color": "hsl(var(--range-shdw))",
    "transform": "translateY(-50%)",
    "-FillerSize": "100rem",
    "-FillerOffset": "0.6rem",
    "boxShadow": "0 0 0 3px hsl(var(--range-shdw)) inset,\n      var(--focus-shadow, 0 0),\n      calc(-1*(var(--filler-size) * -1 - var(--filler-offset))) 0 0 var(--filler-size)"
  },
  ".range::-moz-range-thumb": {
    "position": "relative",
    "height": "1.5rem",
    "width": "1.5rem",
    "borderStyle": "none",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)",
    "top": "50%",
    "color": "hsl(var(--range-shdw))",
    "-FillerSize": "100rem",
    "-FillerOffset": "0.5rem",
    "boxShadow": "0 0 0 3px hsl(var(--range-shdw)) inset,\n      var(--focus-shadow, 0 0),\n      calc(-1*(var(--filler-size) * -1 - var(--filler-offset))) 0 0 var(--filler-size)"
  },
  ".range-primary": {
    "-RangeShdw": "var(--p)"
  },
  ".range-secondary": {
    "-RangeShdw": "var(--s)"
  },
  ".range-accent": {
    "-RangeShdw": "var(--a)"
  },
  ".range-success": {
    "-RangeShdw": "var(--su)"
  },
  ".range-warning": {
    "-RangeShdw": "var(--wa)"
  },
  ".range-info": {
    "-RangeShdw": "var(--in)"
  },
  ".range-error": {
    "-RangeShdw": "var(--er)"
  },
  ".rating input": {
    "appearance": "none",
    "WebkitAppearance": "none"
  },
  ".rating .rating-hidden": {
    "width": "0.5rem",
    "backgroundColor": "transparent"
  },
  ".rating input:checked ~ input,\n  .rating input[aria-checked=\"true\"] ~ input": {
    "-TwBgOpacity": "0.2"
  },
  ".rating input:focus-visible": {
    "transitionProperty": "transform",
    "transitionTimingFunction": ["cubic-bezier(0.4, 0, 0.2, 1)", "cubic-bezier(0, 0, 0.2, 1)"],
    "transitionDuration": "300ms",
    "transform": "translateY(-0.125em)"
  },
  ".rating input:active:focus": {
    "animation": "none",
    "transform": "translateY(-0.125em)"
  },
  ".rating-half :where(input:not(.rating-hidden))": {
    "width": "0.75rem"
  },
  "@keyframes rating-pop": {
    "0%": {
      "transform": "translateY(-0.125em)"
    },
    "40%": {
      "transform": "translateY(-0.125em)"
    },
    "100%": {
      "transform": "translateY(0)"
    }
  },
  ".select-bordered": {
    "-TwBorderOpacity": "0.2"
  },
  ".select:focus": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 0.2)"
  },
  ".select-ghost": {
    "-TwBgOpacity": "0.05"
  },
  ".select-ghost:focus": {
    "-TwBgOpacity": "1",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))"
  },
  ".select-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".select-primary:focus": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".select-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".select-secondary:focus": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".select-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".select-accent:focus": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".select-info": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".select-info:focus": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".select-success": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".select-success:focus": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".select-warning": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".select-warning:focus": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".select-error": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".select-error:focus": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".select-disabled,\n  .select:disabled,\n  .select[disabled]": {
    "cursor": "not-allowed",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".select-disabled::placeholder,\n  .select:disabled::placeholder,\n  .select[disabled]::placeholder": {
    "color": "hsl(var(--bc) / var(--tw-placeholder-opacity))",
    "-TwPlaceholderOpacity": "0.2"
  },
  ".select-multiple,\n  .select[multiple],\n  .select[size].select:not([size=\"1\"])": {
    "backgroundImage": "none",
    "paddingLeft": "1rem"
  },
  "[dir=\"rtl\"] .select": {
    "backgroundPosition": "calc(100% - (0% + 12px)) calc(1px + 50%),\n    calc(100% - (0% + 16px)) calc(1px + 50%)"
  },
  ":where(.stats) > :not([hidden]) ~ :not([hidden])": {
    "-TwDivideXReverse": "0",
    "borderLeftWidth": "calc(1px * var(--tw-divide-x-reverse))",
    "borderRightWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
    "-TwDivideYReverse": "0",
    "borderTopWidth": "calc(0px * calc(1 - var(--tw-divide-y-reverse)))",
    "borderBottomWidth": "calc(0px * var(--tw-divide-y-reverse))"
  },
  ".steps .step:before": {
    "top": "0px",
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "height": "0.5rem",
    "width": "100%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "content": "\"\"",
    "marginRight": "-100%"
  },
  ".steps .step:after": {
    "content": "counter(step)",
    "counterIncrement": "step",
    "zIndex": "1",
    "position": "relative",
    "gridColumnStart": "1",
    "gridRowStart": "1",
    "display": "grid",
    "height": "2rem",
    "width": "2rem",
    "placeItems": "center",
    "placeSelf": "center",
    "borderRadius": "9999px",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))"
  },
  ".steps .step:first-child:before": {
    "content": "none"
  },
  ".steps .step[data-content]:after": {
    "content": "attr(data-content)"
  },
  ".steps .step-neutral + .step-neutral:before,\n  .steps .step-neutral:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--n) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--nc) / var(--tw-text-opacity))"
  },
  ".steps .step-primary + .step-primary:before,\n  .steps .step-primary:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".steps .step-secondary + .step-secondary:before,\n  .steps .step-secondary:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".steps .step-accent + .step-accent:before,\n  .steps .step-accent:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".steps .step-info + .step-info:before": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))"
  },
  ".steps .step-info:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".steps .step-success + .step-success:before": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))"
  },
  ".steps .step-success:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".steps .step-warning + .step-warning:before": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))"
  },
  ".steps .step-warning:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".steps .step-error + .step-error:before": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))"
  },
  ".steps .step-error:after": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".swap-rotate .swap-on,\n.swap-rotate .swap-indeterminate,\n.swap-rotate input:indeterminate ~ .swap-on": {
    "-TwRotate": "45deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".swap-rotate input:checked ~ .swap-off,\n.swap-active:where(.swap-rotate) .swap-off,\n.swap-rotate input:indeterminate ~ .swap-off": {
    "-TwRotate": "-45deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".swap-rotate input:checked ~ .swap-on,\n.swap-active:where(.swap-rotate) .swap-on,\n.swap-rotate input:indeterminate ~ .swap-indeterminate": {
    "-TwRotate": "0deg",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".swap-flip": {
    "transformStyle": "preserve-3d",
    "perspective": "16em"
  },
  ".swap-flip .swap-on,\n.swap-flip .swap-indeterminate,\n.swap-flip input:indeterminate ~ .swap-on": {
    "transform": "rotateY(-180deg)",
    "backfaceVisibility": "hidden",
    "opacity": "1"
  },
  ".swap-flip input:checked ~ .swap-off,\n.swap-active:where(.swap-flip) .swap-off,\n.swap-flip input:indeterminate ~ .swap-off": {
    "transform": "rotateY(180deg)",
    "backfaceVisibility": "hidden",
    "opacity": "1"
  },
  ".swap-flip input:checked ~ .swap-on,\n.swap-active:where(.swap-flip) .swap-on,\n.swap-flip input:indeterminate ~ .swap-indeterminate": {
    "transform": "rotateY(0deg)"
  },
  ".tab.tab-active:not(.tab-disabled):not([disabled])": {
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "1",
    "-TwTextOpacity": "1"
  },
  ".tab:focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px"
  },
  ".tab:focus-visible": {
    "outline": "2px solid currentColor",
    "outlineOffset": "-3px"
  },
  ".tab:focus-visible.tab-lifted": {
    "borderBottomLeftRadius": "var(--tab-radius, 0.5rem)",
    "borderBottomRightRadius": "var(--tab-radius, 0.5rem)"
  },
  ".tab-disabled,\n  .tab[disabled]": {
    "cursor": "not-allowed",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".tab-bordered": {
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.2",
    "borderStyle": "solid",
    "borderBottomWidth": "calc(var(--tab-border, 1px) + 1px)"
  },
  ".tab-lifted": {
    "border": "var(--tab-border, 1px) solid transparent",
    "borderWidth": "0 0 var(--tab-border, 1px) 0",
    "borderTopRightRadius": "var(--tab-radius, 0.5rem)",
    "borderTopLeftRadius": "var(--tab-radius, 0.5rem)",
    "borderBottomColor": "var(--tab-border-color)",
    "paddingRight": "var(--tab-padding, 1rem)",
    "paddingLeft": "var(--tab-padding, 1rem)",
    "paddingTop": "var(--tab-border, 1px)"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled])": {
    "backgroundColor": "var(--tab-bg)",
    "borderWidth": "var(--tab-border, 1px) var(--tab-border, 1px) 0 var(--tab-border, 1px)",
    "borderRightColor": "var(--tab-border-color)",
    "borderLeftColor": "var(--tab-border-color)",
    "borderTopColor": "var(--tab-border-color)",
    "paddingRight": "calc(var(--tab-padding, 1rem) - var(--tab-border, 1px))",
    "paddingLeft": "calc(var(--tab-padding, 1rem) - var(--tab-border, 1px))",
    "paddingBottom": "var(--tab-border, 1px)",
    "paddingTop": "0"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before,\n    .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after": {
    "zIndex": "1",
    "content": "\"\"",
    "display": "block",
    "position": "absolute",
    "width": "var(--tab-radius, 0.5rem)",
    "height": "var(--tab-radius, 0.5rem)",
    "bottom": "0",
    "-TabGrad": "calc(68% - var(--tab-border, 1px))",
    "-TabCornerBg": "radial-gradient(\n        circle at var(--circle-pos),\n        transparent var(--tab-grad),\n        var(--tab-border-color) calc(var(--tab-grad) + 0.3px),\n        var(--tab-border-color) calc(var(--tab-grad) + var(--tab-border, 1px)),\n        var(--tab-bg) calc(var(--tab-grad) + var(--tab-border, 1px) + 0.3px)\n      )"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before": {
    "right": "calc(var(--tab-radius, 0.5rem) * -1)",
    "-CirclePos": "top left",
    "backgroundImage": "var(--tab-corner-bg)"
  },
  "[dir=\"rtl\"] .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before": {
    "-CirclePos": "top right"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after": {
    "left": "calc(var(--tab-radius, 0.5rem) * -1)",
    "-CirclePos": "top right",
    "backgroundImage": "var(--tab-corner-bg)"
  },
  "[dir=\"rtl\"] .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):after": {
    "-CirclePos": "top left"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled]):first-child:before": {
    "background": "none"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled]):last-child:after": {
    "background": "none"
  },
  ".tab-lifted.tab-active:not(.tab-disabled):not([disabled])\n  + .tab-lifted.tab-active:not(.tab-disabled):not([disabled]):before": {
    "background": "none"
  },
  ".tabs-boxed": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "padding": "0.25rem",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".tabs-boxed .tab": {
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".tabs-boxed .tab-active:not(.tab-disabled):not([disabled])": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".table :where(th, td)": {
    "paddingRight": "1rem",
    "paddingLeft": "1rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "verticalAlign": "middle"
  },
  ".table tr.active,\n  .table tr.active:nth-child(even),\n  .table-zebra tbody tr:nth-child(even)": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))"
  },
  ".table-zebra tr.active,\n    .table-zebra tr.active:nth-child(even),\n    .table-zebra-zebra tbody tr:nth-child(even)": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))"
  },
  ".table :where(thead, tbody) :where(tr:not(:last-child)),\n    .table :where(thead, tbody) :where(tr:first-child:last-child)": {
    "borderBottomWidth": "1px",
    "-TwBorderOpacity": "1",
    "borderBottomColor": "hsl(var(--b2) / var(--tw-border-opacity))"
  },
  ".table :where(thead, tfoot)": {
    "whiteSpace": "nowrap",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "fontWeight": "700",
    "color": "hsl(var(--bc) / 0.6)"
  },
  ".textarea-bordered": {
    "-TwBorderOpacity": "0.2"
  },
  ".textarea:focus": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 0.2)"
  },
  ".textarea-ghost": {
    "-TwBgOpacity": "0.05"
  },
  ".textarea-ghost:focus": {
    "-TwBgOpacity": "1",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "boxShadow": "none"
  },
  ".textarea-primary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))"
  },
  ".textarea-primary:focus": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".textarea-secondary": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))"
  },
  ".textarea-secondary:focus": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".textarea-accent": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))"
  },
  ".textarea-accent:focus": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".textarea-info": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))"
  },
  ".textarea-info:focus": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".textarea-success": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))"
  },
  ".textarea-success:focus": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".textarea-warning": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))"
  },
  ".textarea-warning:focus": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".textarea-error": {
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))"
  },
  ".textarea-error:focus": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".textarea-disabled,\n  .textarea:disabled,\n  .textarea[disabled]": {
    "cursor": "not-allowed",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--b2) / var(--tw-border-opacity))",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b2) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "0.2"
  },
  ".textarea-disabled::placeholder,\n  .textarea:disabled::placeholder,\n  .textarea[disabled]::placeholder": {
    "color": "hsl(var(--bc) / var(--tw-placeholder-opacity))",
    "-TwPlaceholderOpacity": "0.2"
  },
  ".toast > *": {
    "animation": "toast-pop 0.25s ease-out"
  },
  "@keyframes toast-pop": {
    "0%": {
      "transform": "scale(0.9)",
      "opacity": "0"
    },
    "100%": {
      "transform": "scale(1)",
      "opacity": "1"
    }
  },
  "[dir=\"rtl\"] .toggle": {
    "-Handleoffsetcalculator": "calc(var(--handleoffset) * 1)"
  },
  ".toggle:focus-visible": {
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineOffset": "2px",
    "outlineColor": "hsl(var(--bc) / 0.2)"
  },
  ".toggle:checked,\n  .toggle[checked=\"true\"],\n  .toggle[aria-checked=\"true\"]": {
    "-Handleoffsetcalculator": "var(--handleoffset)",
    "-TwBorderOpacity": "1",
    "-TwBgOpacity": "1"
  },
  "[dir=\"rtl\"] .toggle:checked, [dir=\"rtl\"] .toggle[checked=\"true\"], [dir=\"rtl\"] .toggle[aria-checked=\"true\"]": {
    "-Handleoffsetcalculator": "calc(var(--handleoffset) * -1)"
  },
  ".toggle:indeterminate": {
    "-TwBorderOpacity": "1",
    "-TwBgOpacity": "1",
    "boxShadow": "calc(-1*(var(--handleoffset) / 2)) 0 0 2px var(--tglbg) inset,\n      calc(-1*(var(--handleoffset) / -2)) 0 0 2px var(--tglbg) inset,\n      0 0 0 2px var(--tglbg) inset"
  },
  "[dir=\"rtl\"] .toggle:indeterminate": {
    "boxShadow": "calc(-1*(var(--handleoffset) / 2)) 0 0 2px var(--tglbg) inset,\n        calc(-1*(var(--handleoffset) / -2)) 0 0 2px var(--tglbg) inset,\n        0 0 0 2px var(--tglbg) inset"
  },
  ".toggle-primary:focus-visible": {
    "outlineColor": "hsl(var(--p) / 1)"
  },
  ".toggle-primary:checked,\n    .toggle-primary[checked=\"true\"],\n    .toggle-primary[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--p) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--p) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--pc) / var(--tw-text-opacity))"
  },
  ".toggle-secondary:focus-visible": {
    "outlineColor": "hsl(var(--s) / 1)"
  },
  ".toggle-secondary:checked,\n    .toggle-secondary[checked=\"true\"],\n    .toggle-secondary[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--s) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--s) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--sc) / var(--tw-text-opacity))"
  },
  ".toggle-accent:focus-visible": {
    "outlineColor": "hsl(var(--a) / 1)"
  },
  ".toggle-accent:checked,\n    .toggle-accent[checked=\"true\"],\n    .toggle-accent[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--a) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--a) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--ac) / var(--tw-text-opacity))"
  },
  ".toggle-success:focus-visible": {
    "outlineColor": "hsl(var(--su) / 1)"
  },
  ".toggle-success:checked,\n    .toggle-success[checked=\"true\"],\n    .toggle-success[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--su) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--suc) / var(--tw-text-opacity))"
  },
  ".toggle-warning:focus-visible": {
    "outlineColor": "hsl(var(--wa) / 1)"
  },
  ".toggle-warning:checked,\n    .toggle-warning[checked=\"true\"],\n    .toggle-warning[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--wa) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--wa) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--wac) / var(--tw-text-opacity))"
  },
  ".toggle-info:focus-visible": {
    "outlineColor": "hsl(var(--in) / 1)"
  },
  ".toggle-info:checked,\n    .toggle-info[checked=\"true\"],\n    .toggle-info[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--in) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--in) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--inc) / var(--tw-text-opacity))"
  },
  ".toggle-error:focus-visible": {
    "outlineColor": "hsl(var(--er) / 1)"
  },
  ".toggle-error:checked,\n    .toggle-error[checked=\"true\"],\n    .toggle-error[aria-checked=\"true\"]": {
    "borderColor": "hsl(var(--er) / var(--tw-border-opacity))",
    "-TwBorderOpacity": "0.1",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--er) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--erc) / var(--tw-text-opacity))"
  },
  ".toggle:disabled": {
    "cursor": "not-allowed",
    "-TwBorderOpacity": "1",
    "borderColor": "hsl(var(--bc) / var(--tw-border-opacity))",
    "backgroundColor": "transparent",
    "opacity": "0.3",
    "-Togglehandleborder": "0 0 0 3px hsl(var(--bc)) inset,\n      var(--handleoffsetcalculator) 0 0 3px hsl(var(--bc)) inset"
  },
  ".toggle-mark": {
    "display": "none"
  },
  ":root .prose": {
    "-TwProseBody": "hsl(var(--bc) / 0.8)",
    "-TwProseHeadings": "hsl(var(--bc))",
    "-TwProseLead": "hsl(var(--bc))",
    "-TwProseLinks": "hsl(var(--bc))",
    "-TwProseBold": "hsl(var(--bc))",
    "-TwProseCounters": "hsl(var(--bc))",
    "-TwProseBullets": "hsl(var(--bc) / 0.5)",
    "-TwProseHr": "hsl(var(--bc) / 0.2)",
    "-TwProseQuotes": "hsl(var(--bc))",
    "-TwProseQuoteBorders": "hsl(var(--bc) / 0.2)",
    "-TwProseCaptions": "hsl(var(--bc) / 0.5)",
    "-TwProseCode": "hsl(var(--bc))",
    "-TwProsePreCode": "hsl(var(--nc))",
    "-TwProsePreBg": "hsl(var(--n))",
    "-TwProseThBorders": "hsl(var(--bc) / 0.5)",
    "-TwProseTdBorders": "hsl(var(--bc) / 0.2)"
  },
  ".prose :where(code):not(:where([class~=\"not-prose\"] *))": {
    "padding": "2px 8px",
    "borderRadius": "var(--rounded-badge)"
  },
  ".prose code:after,\n  .prose code:before": {
    "content": "none"
  },
  ".prose pre code": {
    "borderRadius": "0",
    "padding": "0"
  },
  ".prose :where(tbody tr, thead):not(:where([class~=\"not-prose\"] *))": {
    "borderBottomColor": "hsl(var(--bc) / 20%)"
  }
};