import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { ButtonConfig } from 'src/app/shared/button';
import { Device } from 'src/app/shared/models/device';
import { Preview } from 'src/app/shared/models/preview';

@Component({
    selector: 'sz-item-preview',
    templateUrl: 'item-preview.component.html',
})
export class ItemPreviewComponent implements OnChanges {
    @Input({ required: true }) preview: Preview;
    @Input() showDeleteButton = true;
    @Input() buttons: ButtonConfig[];
    @Output() clearSelection = new EventEmitter<void>();
    @Output() delete = new EventEmitter();

    devices: Promise<Device[]>;

    constructor(
        private api: ApiService,
        private router: Router,
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.preview && changes.preview.currentValue) {
            this.devices = Promise.all(
                this.preview?.deviceIds?.map((id) =>
                    this.api.devices.getAsync(id),
                ) ?? [],
            );
        }
    }

    goToDevice(deviceId: number) {
        this.router.navigate(['devices/' + deviceId]);
    }
}
