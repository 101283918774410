import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';
import { IconType } from './indicator';

export class InteractiveIndicatorWidget extends Widget {
    type = WidgetType.InteractiveIndicatorWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Title',
                },
                {
                    id: 'initial_value',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.initial_value',
                    previewValue: 1,
                    defaultValue: 1,
                    config: {
                        options: [
                            { value: 1, name: 'widget.settings.on' },
                            { value: 0, name: 'widget.settings.off' },
                        ],
                    },
                },
                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'onText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.on_text',
                    previewValue: 'On',
                },
                {
                    id: 'offText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.off_text',
                    previewValue: 'Off',
                },
                {
                    id: 'icon',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.icon',
                    previewValue: IconType.Power,
                    defaultValue: IconType.Power,
                    config: {
                        options: Object.values(IconType).map((type) => ({
                            value: type,
                            name: `widget.settings.icon.${type}`,
                        })),
                    },
                },
                {
                    id: 'color',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.icon_color',
                    previewValue: 'orange',
                    defaultValue: 'orange',
                },
            ],
            transformOldSettings(settings),
        );
    }
}

function transformOldSettings(settings) {
    if (!settings || settings['initial_value']) {
        return settings;
    }

    const value = settings['value'] ? 1 : 0;
    return { ...settings, initial_value: value };
}
