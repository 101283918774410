import { Chart, ChartType } from '../chart';
import { ChartTypeConfig } from '../chart-type';
import { SignalResourceType } from '../resource-type';
import { generateSeriesStatisticSignals } from '../signal';
import { TimeFormat } from '../signals/time';

export class TableChart extends Chart {
    type = ChartType.Table;

    get statisticSignals() {
        return generateSeriesStatisticSignals(this.signals);
    }
}

export const tableConfig: ChartTypeConfig = {
    icon: 'table',
    canToggleSignalVisibility: true,
    supportedResourceTypes: [SignalResourceType.Time, SignalResourceType.Log],
    signalResourceDefaults: {
        [SignalResourceType.Time]: {
            format: TimeFormat.DateTime,
        },
    },
};
