import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'sz-spinner',
    templateUrl: './spinner.component.html',
})
export class SpinnerComponent {
    @Input() size: 'huge' | 'large' | 'medium' | 'small' = 'small';
    // TODO: add color input
    private spinnerSizeMap = {
        huge: '120',
        large: '28',
        medium: '18',
        small: '12',
    };

    get spinnerSize() {
        return this.spinnerSizeMap[this?.size] ?? this.spinnerSizeMap['small'];
    }
}
