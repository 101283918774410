<div class="flex items-center">
    <mat-select
        class="grow rounded-md border border-gray-400 bg-transparent px-1.5 py-2 text-base focus:!border-primary-500 dark:border-gray-600 has-[:disabled]:opacity-25"
        [formControl]="control"
        [multiple]="isMultiple"
    >
        @if (groups && groups.length) {
            @for (group of groups; track group.id) {
                <mat-optgroup [label]="group.name" class="group-select">
                    @for (
                        option of getGroupOptions(group.id);
                        track option.value
                    ) {
                        <mat-option [value]="option.value">
                            @if (option.icon) {
                                <mat-icon [svgIcon]="option.icon" />
                            }
                            {{ option.name | translate }}
                        </mat-option>
                    }
                </mat-optgroup>
            }
        } @else {
            @for (option of options; track option.value) {
                <mat-option [value]="option.value">
                    @if (option.icon) {
                        <mat-icon [svgIcon]="option.icon" />
                    }
                    {{ option.name | translate }}
                </mat-option>
            }
        }
    </mat-select>
    @if (control.invalid) {
        <mat-icon
            class="!h-8 !w-8 pl-1 text-red-500"
            [matTooltip]="errorMessage | translate"
            svgIcon="exclamation-thick"
        />
    }
</div>
