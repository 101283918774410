import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sz-dialog-button-bar',
    templateUrl: './dialog-button-bar.component.html',
})
export class DialogButtonBarComponent {
    @Input() primaryText = 'btn.apply';
    @Input() primaryColor: 'primary' | 'accent' | 'warn' = 'primary';
    @Input() primaryDisabled = false;
    @Input() primaryHidden = false;
    @Input() secondaryText = 'btn.cancel';
    @Input() secondaryDisabled = false;
    @Input() tertiaryText = '';
    @Input() tertiaryDisabled = false;
    @Input() tertiaryHidden = true;

    @Output() primaryClick = new EventEmitter();
    @Output() secondaryClick = new EventEmitter();
    @Output() tertiaryClick = new EventEmitter();

    showSpinner = false;

    handlePrimaryClick() {
        this.showSpinner = true;
        this.primaryDisabled = true;
        this.primaryClick.emit();
        setTimeout(() => {
            this.showSpinner = false;
        }, 2000);
    }
}
