import { ChartSignal, StatisticType } from './signal';

export enum SignalResourceType {
    Time = 'time',
    Log = 'logDataFiles',
}

export const generateUuid = (
    signal: ChartSignal,
    type: StatisticType,
): string => {
    let subUuid = '';
    switch (signal.resource.type) {
        case SignalResourceType.Log: {
            const { signalId, stat, threshold } = signal.resource;
            const deviceIds = [...signal.resource.deviceIds];
            deviceIds.sort();
            const devicesString = `(${deviceIds.join(',')})`;
            const segments = [signalId, devicesString, stat];
            if (threshold) {
                segments.push(`${threshold.operator}:${threshold.value}`);
            }
            subUuid = segments.join(':');
            break;
        }
        case SignalResourceType.Time:
            subUuid = signal.resource.format;
            break;
        default:
            throw Error(`Unknown resource type: ${signal.resource}`);
    }
    return type + ':' + signal.resource.type + ':' + subUuid;
};
