import { snakeCase } from 'change-case';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { palette } from 'src/app/shared/palette';
import { environment } from 'src/environments/environment';

/**
 * Convert a kebab case string into a camel case string.
 */
export function kebabCaseToCamelCase(kebabString: string) {
    return kebabString.replace(/-./g, (x) => x[1].toUpperCase());
}

/**
 * Convert a snake case string into a camel case string.
 */
export function snakeCaseToCamelCase(snakeString: string) {
    return snakeString.replace(/_./g, (x) => x[1].toUpperCase());
}

/**
 * Perform a union set operation.
 * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#implementing_basic_set_operations.
 */
export function union(setA, setB) {
    const _union = new Set(setA);
    for (const elem of setB) {
        _union.add(elem);
    }
    return _union;
}

/**
 * Asynchronously delay the given number of milliseconds.
 */
export function delay(milliseconds: number) {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Use setTimeout to wait for the next micro task.
 */
export function pause() {
    return delay(0);
}

/**
 * Calculate what has changed.
 */
export function diffChanges(original, current) {
    return Object.fromEntries(
        Object.entries(current).filter(([id, value]) => original[id] != value),
    );
}

/**
 * Switch the key mapping to map the opposite direction.
 */
export function invertMapping(keyMapping) {
    return Object.fromEntries(
        Object.entries(keyMapping).map(([oldId, newId]) => [newId, oldId]),
    );
}

/**
 * Convert the keys of the object using the key mapping object.
 */
export function convertKeys(values, keyMapping) {
    return Object.fromEntries(
        Object.entries(keyMapping)
            .filter(([oldId]: [string, string]) => oldId in values)
            .map(([oldId, newId]) => [newId, values[oldId]]),
    );
}

/**
 * Get file name component of a file path.
 */
export function getFileName(value: string) {
    if (value && value.includes('/')) {
        const shorterName = value.split('/');
        return shorterName.pop();
    } else {
        return value;
    }
}

/**
 * Convert an array to an object, keyed by the given property.
 */
export function arrayToObject(array, property) {
    return Object.fromEntries(array.map((item) => [item[property], item]));
}

/**
 * Convert an old theme name if needed.
 */
export function convertColor(color) {
    const regex = /#([a-f0-9]{3}){1,2}\b/i;
    if (regex.test(color)) {
        return color;
    } else if (color in palette) {
        return palette[color];
    }
    return palette.Default;
}

/**
 * Check if the object is empty.
 */
export function isEmpty(obj) {
    return Object.keys(obj).length == 0;
}

/**
 * Merge objects.
 */
export function merge(...objs) {
    return Object.assign({}, ...objs);
}

/**
 * Merge recursively.
 */
export function mergeObjectsRecursively(
    objects: Record<string, unknown>[],
): Record<string, unknown> {
    const mergedObject: Record<string, unknown> = {};

    for (const obj of objects) {
        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    mergedObject[key] = mergeObjectsRecursively([
                        mergedObject[key] as Record<string, unknown>,
                        obj[key] as Record<string, unknown>,
                    ]);
                } else {
                    mergedObject[key] = obj[key];
                }
            }
        }
    }

    return mergedObject;
}

/**
 * Bound a number in a range.
 */
export function bound(value, min, max) {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    }
    return value;
}

/**
 * Deep clone a value.
 */
export function clone(input) {
    if (isDefined(structuredClone)) {
        // TODO: replace all `clone` and `cloneArray` with `structuredClone`.
        return structuredClone(input);
    }
    if (input === null || typeof input !== 'object') {
        return input;
    } else if (Array.isArray(input)) {
        return cloneArray(input);
    }
    const output = {};
    for (const key in input) {
        if (Object.hasOwn(input, key)) {
            output[key] = clone(input[key]);
        }
    }
    return output;
}

/**
 * Shallow clone an array.
 */
export function cloneArray(array) {
    return array.map((item) => clone(item));
}

type SegmentType = string | number;

/**
 * Get a value in a nested object.
 */
export function getNestedValue(object, path: SegmentType[]) {
    if (path.length == 0) {
        return object;
    }
    const firstSegment = path[0];
    if (path.length == 1) {
        return object[firstSegment];
    } else {
        if (!(firstSegment in object)) {
            return undefined;
        }
        return getNestedValue(object[firstSegment], path.slice(1));
    }
}

/**
 * Check if the nested object contains a value at the given path.
 */
export function hasPath(object, path: SegmentType[]) {
    return isDefined(getNestedValue(object, path));
}

/**
 * Set a value in a nested object, creating objects as needed.
 */
export function setNestedValue(object, path: SegmentType[], value) {
    const firstSegment = path[0];
    if (path.length == 1) {
        object[firstSegment] = value;
    } else {
        setDefault(object, firstSegment, {});
        setNestedValue(object[firstSegment], path.slice(1), value);
    }
}

/**
 * Update an object in a nested object, merging as needed.
 */
export function updateNestedObject(object, path: string[], value: object) {
    setNestedValue(object, path, merge(getNestedValue(object, path), value));
}

/**
 * Set the value of the key in the object, if the key isn't in the object.
 */
export function setDefault(object, key, value) {
    if (!(key in object)) {
        object[key] = value;
    }
}

/**
 * Check if the value is a number.
 */
export function isNumber(value: string | number): boolean {
    return !isNaN(+value);
}

/**
 * Zip multiple arrays together.
 */
export function zip(...arrays) {
    const length = arrays[0].length;
    for (const array of arrays) {
        if (array.length != length) {
            throw Error('Arrays to be zipped must all have the same length.');
        }
    }
    const zipped = [];
    for (let i = 0; i < length; i++) {
        const item = [];
        for (const array of arrays) {
            item.push(array[i]);
        }
        zipped.push(item);
    }
    return zipped;
}

/**
 * Convert the string to title case.
 */
export function titleCase(str) {
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
    );
}

/**
 * Import a date from a UTC string to a local Date object
 */
export function importDateString(date: string): Date {
    if (date == null) {
        return null;
    }
    if (DateTime.fromISO(date).isValid) {
        return DateTime.fromISO(date).toLocal().toJSDate();
    }
}

/**
 * Export a date from a local Date object to a UTC string
 */
export function exportDateString(date: Date): string {
    if (DateTime.fromJSDate(date).isValid) {
        return DateTime.fromJSDate(date).toUTC().toISO();
    }
}

/**
 * Return a new array with only unique values.
 */
export function uniqueArray<T>(array: T[]): T[] {
    return Array.from(new Set(array));
}

/**
 * Split an array into smaller chunks.
 */
export function chunkArray(array: unknown[], chunkSize = 100) {
    const chunks = [];
    const operatingArray = cloneArray(array); // don't mutate the input array
    while (operatingArray.length > 0) {
        const chunk = operatingArray.splice(0, chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}

/**
 * Swap two element in an array.
 */
export function swap(array, i, j) {
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
}

/**
 * Check if the array values are equivalent.
 */
export function arrayEquals(a: unknown[], b: unknown[]) {
    if (a === b) {
        return true;
    }
    if (a.length != b.length) {
        return false;
    }
    return a.every((v, index) => isDeepEqual(v, b[index]));
}

/**
 * Check if the object values are equivalent.
 */
export function objectEquals(a: object, b: object) {
    if (
        !arrayEquals(
            Object.keys(a).filter((item) => isDefined(a[item])),
            Object.keys(b).filter((item) => isDefined(b[item])),
        )
    ) {
        return false;
    }
    for (const key in a) {
        if (!isDeepEqual(a[key], b[key])) {
            return false;
        }
    }
    return true;
}

/**
 * Check if the values are equivalent.
 * Recursively compare arrays and objects.
 */
export function isDeepEqual(a, b) {
    if (a == null || b == null) {
        return a == b;
    }
    if (Array.isArray(a) || Array.isArray(b)) {
        if (!Array.isArray(a) || !Array.isArray(b)) {
            return false;
        }
        return arrayEquals(a, b);
    }
    if (typeof a == 'object' && typeof b == 'object') {
        return objectEquals(a, b);
    }
    return a == b;
}

export function convertToArray<T>(enumObj: T): T[keyof T][] {
    return Object.values(enumObj);
}

/**
 * Check if the value is non-null and has a length greater than 0.
 */
export function isNonEmpty(value?: unknown[]) {
    return !!value && value.length > 0;
}

/**
 * Update some fields of the object.
 */
export function update(obj, updates) {
    for (const key in updates) {
        obj[key] = updates[key];
    }
}

/**
 * Check if value is not undefined.
 */
export const isDefined = (value) => typeof value != 'undefined';

/**
 * Check if the value is not null.
 */
export const isNotNull = (value) => value !== null;

/**
 * Return a function that will filter an array by the given filters.
 */
export const filterBy =
    <GetType, ParamsType>(filters: Partial<ParamsType>) =>
    (item: GetType) =>
        !Object.keys(filters).some((field) => item[field] != filters[field]);

/**
 * Return a function that will filter an array by the given search term, using
 * the given fields.
 */
export const searchBy = <GetType>(fields: string[], term: string) => {
    term = term.toLowerCase();
    return term.length == 0
        ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
          (item: GetType) => true
        : (item: GetType) =>
              fields.some((field) =>
                  (item[field] ?? '').toString().toLowerCase().includes(term),
              );
};

/**
 * Return a comparator that can be used to sort an array by the given field.
 */
export const sortBy = (field: string, descendingOrder: boolean) => (a, b) => {
    if (a[field] < b[field]) {
        return descendingOrder ? 1 : -1;
    } else if (a[field] > b[field]) {
        return descendingOrder ? -1 : 1;
    }
    return 0;
};

/**
 * Return a function that will by filter an array by applying the offset and
 * limit values.
 */
export const offsetAndLimitBy = (
    offset: number | null,
    limit: number | null,
) => {
    if (limit == null) {
        if (offset == null) {
            return () => true;
        }
        return (_, index) => index >= offset;
    }
    offset ??= 0;
    return (_, index) => index >= offset && index < offset + limit;
};

/**
 * Parse comma-separated list from a value given in a query parameter.
 * Return null if the value isn't specified.
 */
export const parseQueryArray = (value: string) => {
    if (isDefined(value)) {
        return value
            ? value.split(',').map((idString) => tryParseNumber(idString))
            : [];
    }
    return null;
};

/**
 * Parse a number if possible, otherwise return the string.
 */
export const tryParseNumber = (valueString: string) => {
    if (valueString && !isNaN(+valueString)) {
        return +valueString;
    }
    return valueString;
};

/**
 * Convert camelCase keys of an object to snake_case keys.
 */
export const camelToSnakeKeys = (object: object) =>
    Object.fromEntries(
        Object.entries(object).map(([key, value]) => [snakeCase(key), value]),
    );

/**
 * Check if the two arrays have any intersections.
 */
export const hasIntersection = (a1, a2) => a1.some((item) => a2.includes(item));

/**
 * Generate a unique hash.
 * Based off of https://stackoverflow.com/a/7616484
 */
export const hash = (a: { toString: () => string }) => {
    const s = a.toString();
    let hash = 0,
        i,
        chr;
    if (s.length === 0) {
        return hash.toString();
    }
    for (i = 0; i < s.length; i++) {
        chr = s.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
};

/**
 * Generate a random identifier.
 */
export const generateRandomId = () => Math.random().toString(16).substr(2, 8);

/**
 * Round off a number to the defined number of decimal places.
 * @param {number} numValue - number to round
 * @param {number} decimalPlaces - number of decimal places to round
 * @returns {number} -  rounded number
 */
export function roundNumber(numValue: number, decimalPlaces = 5): number {
    if (!isDefined(numValue) || !isNotNull(numValue)) {
        return;
    }
    const decimalValue: number = parseInt('1' + '0'.repeat(decimalPlaces));
    return Math.round(numValue * decimalValue) / decimalValue;
}

/**
 * Use an async function in an observable pipe.
 */
export const asyncMap =
    <InputType, OutputType>(
        transform: (input: InputType) => Promise<OutputType>,
    ) =>
    (source: Observable<InputType>): Observable<OutputType> =>
        new Observable((subscriber) => {
            source.subscribe({
                next(input) {
                    transform(input).then((output) => subscriber.next(output));
                },
                error(error) {
                    subscriber.error(error);
                },
                complete() {
                    subscriber.complete();
                },
            });
        });

export const transformBooleanParamsToNumbers = (
    params: Record<string, string | number | boolean>,
): Record<string, string> => {
    const transformed = clone(params);
    Object.entries(params).forEach(([key, value]) => {
        if (value === true || value === false) {
            transformed[key] = +value;
        }
    });
    return transformed;
};

export const baseCenter = {
    lat: environment.baseLatitude ?? 0,
    lng: environment.baseLongitude ?? 0,
};

export type BackendError = {
    type: string;
    message: string;
    details: Record<string, unknown>;
};
