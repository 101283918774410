import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import 'datatables.net';
import $ from 'jquery';
import { pause } from '../../../shared/common';

@Component({
    selector: 'sz-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
    @Input() caption;
    @Input() options;
    @Input() striped = true;

    @ViewChild('table', { static: true }) tableElement: ElementRef;

    get tableClasses(): string {
        let classes = 'nowrap';
        if (this.striped) {
            classes += ' display';
        }
        return classes;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ngOnChanges(changes: SimpleChanges): void {
        this.setupTable();
    }

    async setupTable() {
        // Run on the next macrotask.
        await pause();
        if (this.options) {
            $(this.tableElement.nativeElement).DataTable(this.options);
        }
    }
}
