import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { snakeCase } from 'change-case';

interface ConfirmDeleteDialogData {
    entityType: string;
    count: number;
}

@Component({ templateUrl: './confirm-delete-dialog.component.html' })
export class ConfirmDeleteDialog {
    get typeTerm() {
        const entityTerm = snakeCase(this.data.entityType);
        if (this.data.count > 1) {
            return `${entityTerm}.plural`;
        }
        return entityTerm;
    }

    constructor(
        public dialogRef: MatDialogRef<ConfirmDeleteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteDialogData,
    ) {}
}
