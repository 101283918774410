import { EvaluationContext } from './evaluation-context';
import { Expression, IExpressionError } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionType } from './type';

/**
 * Represents a blank in an expression. Must be replaced before the expression
 * can be evaluated.
 */
export class ExpressionNull extends Expression {
    type: ExpressionType = ExpressionType.Null;

    constructor() {
        super();
    }

    get kind(): ExpressionKind {
        return ExpressionKind.Null;
    }

    get data(): unknown[] {
        return [this.kind];
    }

    get error(): IExpressionError {
        return { term: 'filter.error.empty_spot' };
    }

    clone(): ExpressionNull {
        return new ExpressionNull();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evaluate(context: EvaluationContext) {
        return null;
    }
}
