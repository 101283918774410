import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterOption } from 'src/app/common/layout/multi-select/multi-select.component';
import {
    CreateOrganization,
    Organization,
    OrganizationsAssociates,
    OrganizationsQueryParams,
} from 'src/app/shared/models/customer';
import { OrganizationPreview } from 'src/app/shared/models/preview';
import { environment } from 'src/environments/environment';
import { getCustomerRoleName } from '../constants';
import { ApiService } from './api.service';
import { DynamicApi, ensureLoaded } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelRouteApi } from './top-level-route-api';
import { PlatformConfigurationService } from '../platform-configuration.service';

const ORGANIZATIONS_ENDPOINT_NAME = 'organizations';

export const getOrganizationImageUrl = (
    id: number,
    type: 'logo' | 'icon' | 'thumbnail',
    withBaseUrl = true,
) => {
    return `${
        withBaseUrl ? environment.apiUrl : ''
    }/api/v2/organizations/${id}/${type}`;
};

class RawOrganizationsApi extends TopLevelRouteApi<
    Organization,
    CreateOrganization,
    OrganizationsQueryParams
> {
    get path() {
        return ORGANIZATIONS_ENDPOINT_NAME;
    }

    constructor(api: ApiService) {
        super(api);
    }

    async getAssociates(organizationId: number) {
        return await this.retrieve<OrganizationsAssociates>(
            `${organizationId}/associates`,
        );
    }
}

export class OrganizationsApi extends DynamicApi<
    Organization,
    CreateOrganization,
    OrganizationPreview,
    OrganizationsQueryParams
> {
    platformConfigurationService = inject(PlatformConfigurationService);

    private raw: RawOrganizationsApi;
    rawAccess: RawAccessAdapter<
        Organization,
        CreateOrganization,
        OrganizationsQueryParams
    >;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super('organizations');
        this.raw = new RawOrganizationsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
        this.translate = inject(TranslateService);
    }

    attachTriggers() {
        this.api.users.updated$.subscribe(async (user) =>
            this.reloadItem(user.customerId),
        );
    }

    async loadPreview(id: number): Promise<OrganizationPreview> {
        const organization = await this.getAsync(id);
        const roleTranslationTerm = getCustomerRoleName(organization.role);
        const subtitle =
            roleTranslationTerm.length > 0
                ? this.translate.instant(roleTranslationTerm)
                : '';
        const imageUrl = organization.logo
            ? getOrganizationImageUrl(id, 'thumbnail')
            : environment.apiUrl + this.platformConfigurationService.thumbnail;
        return {
            type: 'organization',
            id,
            title: organization.name,
            subtitle,
            imageUrl,
            item: organization,
        };
    }

    async asFilterOption(id: number): Promise<FilterOption> {
        const organization = await this.getAsync(id);
        return { value: id, name: organization?.name ?? '' };
    }

    async asFilterOptions(): Promise<FilterOption[]> {
        await ensureLoaded([this]);
        return this.current().map((organization) => ({
            value: organization.id,
            name: organization?.name ?? '',
        }));
    }

    async getAssociates(organizationId: number) {
        return (await this.raw.getAssociates(organizationId)).associates;
    }
}
