import {
    BaudRateValue,
    CanBus,
    CanDatabase,
    Protocol,
} from 'src/app/shared/models/can-database';
import { ApiService } from './api.service';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import { TopLevelQueryParams } from './top-level-route-api';

export interface CanDatabasesQueryParams extends TopLevelQueryParams {
    modelId?: number;
    isActive?: boolean;
}

export class CanDatabasesApi extends TopLevelFileRouteApi<
    CanDatabase,
    CanDatabase,
    CanDatabasesQueryParams,
    unknown
> {
    get path() {
        return 'can-databases';
    }

    get fileSubPath() {
        return 'file';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async downloadSymFile(id: number): Promise<Blob> {
        return (await this.api.client
            .get(this.basePath + '/' + id + '/sym-file', {
                responseType: 'blob',
            })
            .toPromise()) as Blob;
    }

    getBaudRates(): Promise<BaudRateValue[]> {
        return this.retrieve<BaudRateValue[]>('baud-rates');
    }

    getBusses(): Promise<CanBus[]> {
        return this.retrieve<CanBus[]>('busses');
    }

    getProtocols(): Promise<Protocol[]> {
        return this.retrieve<Protocol[]>('protocols');
    }
}
