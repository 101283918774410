import { Component, Input } from '@angular/core';

@Component({
    selector: 'sz-display-value',
    templateUrl: './display-value.component.html',
})
export class DisplayValueComponent {
    @Input() value;

    isNumber(value): boolean {
        return typeof value === 'number';
    }

    isBoolean(value): boolean {
        return typeof value === 'boolean';
    }

    get color(): string {
        if (this.isBoolean(this.value)) {
            return this.value ? 'seagreen' : 'crimson';
        }
        return null;
    }

    get icon(): string {
        if (this.isBoolean(this.value)) {
            return this.value ? 'check-bold' : 'close-thick';
        } else if (this.value === Infinity) {
            return 'infinity';
        }
        return null;
    }
}
