import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ApiService } from 'src/app/services/api/api.service';
import { PinType, pinTypes } from 'src/app/shared/models/pin';
import { BasePreview } from 'src/app/shared/models/preview';

/**
 * An item on a list page.
 */
@Component({
    selector: 'sz-list-item',
    templateUrl: 'list-item.component.html',
})
export class ListItemComponent {
    @Input({ required: true }) id: number;
    @Input({ required: true }) preview: BasePreview | null;
    @Input() isSelected = false;

    get canPin() {
        return this.preview == null
            ? false
            : pinTypes.includes(this.preview.type as PinType);
    }

    get isPinned() {
        return this.api.pins.has(this.preview.type as PinType, this.preview.id);
    }

    constructor(
        private api: ApiService,
        public app: AppService,
    ) {}

    togglePin() {
        this.api.pins.toggle(this.preview.type as PinType, this.preview.id);
    }
}
