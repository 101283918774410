import {
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { ImageService } from 'src/app/services/image/image.service';

@Component({
    selector: 'sz-image',
    templateUrl: './cached-image.component.html',
    styles: [':host { display: block; }'],
})
export class CachedImageComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) src: string;
    @Input({ required: true }) alt = '';
    @Input() classList = '';

    // Tiny blank image
    imageSrc =
        'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

    constructor(private imageService: ImageService) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.src) {
            this.imageSrc = await this.imageService.getImage(
                changes.src.currentValue
            );
        }
    }

    // Clean up any resources when the component is destroyed
    ngOnDestroy() {
        if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
        }
    }
}
