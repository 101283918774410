/**
 * Get data from local or session storage.
 */
export const getFromStorage = (key: string) => {
    return localStorage.getItem(key) ?? sessionStorage.getItem(key);
};

/**
 * Set data to local or session storage.
 */
export const setToStorage = (key: string, value: string) => {
    const storage = sessionStorage.getItem('token')
        ? sessionStorage
        : localStorage;
    storage.setItem(key, value);
};
