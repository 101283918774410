import { Chart, ChartType } from '../chart';
import { ChartTypeConfig } from '../chart-type';
import { SignalResourceType } from '../resource-type';
import { generateAggregateStatisticSignals } from '../signal';
import { Stat } from '../signals/log';

export class StatisticsComparisonChart extends Chart {
    type = ChartType.StatisticsComparison;

    get supportedResourceTypes() {
        return [SignalResourceType.Log];
    }

    get statisticSignals() {
        return generateAggregateStatisticSignals(this.signals);
    }
}

export const statisticsComparisonConfig: ChartTypeConfig = {
    icon: 'file-table-box',
    canToggleSignalVisibility: true,
    supportedResourceTypes: [SignalResourceType.Log],
    signalResourceDefaults: {
        [SignalResourceType.Log]: {
            stat: Stat.Count,
        },
    },
};
