import { Chart, ChartType } from '../chart';
import { ChartTypeConfig } from '../chart-type';
import { SignalResourceType } from '../resource-type';
import { generateSeriesStatisticSignals } from '../signal';

export class MapChart extends Chart {
    type = ChartType.Map;

    get statisticSignals() {
        return generateSeriesStatisticSignals(this.signals);
    }
}

export const mapConfig: ChartTypeConfig = {
    icon: 'earth',
    canToggleSignalVisibility: false,
    supportedResourceTypes: [SignalResourceType.Time, SignalResourceType.Log],
    signalResourceDefaults: {},
};
