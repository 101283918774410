import { Injectable } from '@angular/core';
import { detect } from 'detect-browser';
import hotkeys from 'hotkeys-js';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, fromEvent } from 'rxjs';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config';
import { CustomerRole } from './services/constants';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { FeatureFlag, SettingsService } from './services/user/settings.service';
import { getAccessToken, hasRole } from './shared/auth-utils';

const fullConfig = resolveConfig(tailwindConfig);
const screenSizes = Object.fromEntries(
    Object.entries(fullConfig.theme.screens).map(
        ([breakpoint, widthString]: [string, string]) => [
            breakpoint,
            +widthString.split('px')[0],
        ],
    ),
);

@Injectable({ providedIn: 'root' })
export class AppService {
    breakpoint$ = new BehaviorSubject<string>('xs');
    browser;

    constructor(
        public settings: SettingsService,
        private clipboard: ClipboardService,
        private snackBar: SnackBarService,
    ) {
        this.browser = detect();
        this.updateWidth();
        fromEvent(window, 'resize').subscribe(() => this.updateWidth());
    }

    updateWidth() {
        let breakpoint = 'xs';
        for (const key in screenSizes) {
            if (this.hasBreakpoint(key)) {
                breakpoint = key;
            }
        }
        if (this.breakpoint$.value !== breakpoint) {
            this.breakpoint$.next(breakpoint);
        }
    }

    hasBreakpoint(breakpoint: string) {
        return window.innerWidth >= screenSizes[breakpoint];
    }

    startHotKeys() {
        hotkeys.filter = (e) => {
            const tag = e.target['tagName'];
            if (tag == 'INPUT') {
                return !['email', 'text'].includes(e.target['type']);
            }
            return tag != 'TEXTAREA';
        };
        hotkeys('d', () => {
            const darkMode = !this.settings.get('extras.dark-mode');
            this.settings.set('extras.dark-mode', darkMode);
            // Force new settings to save.
            this.settings.save();
        });
        hotkeys('t', () => {
            if (this.settings.useFeature(FeatureFlag.TokenHotkey)) {
                const userToken = getAccessToken();
                this.clipboard.copy(userToken);
                this.snackBar.open('snackbar.user_token_copied');
            }
        });
    }

    pauseHotKeys() {
        hotkeys.unbind();
    }
}
