import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { snakeCase } from 'change-case';

enum Status {
    Deleting,
    Complete,
    Fail,
}

const CHUNK_SIZE = 20;

@Component({
    templateUrl: './delete-many-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteManyDialog implements OnInit {
    statusOptions = Status;
    status: Status = Status.Deleting;
    count = 0;
    total = 0;

    get typeTerm() {
        const entityTerm = snakeCase(this.data.entityType);
        const isPlural = this.data.selectedIds.length > 1;
        return isPlural ? `${entityTerm}.plural` : entityTerm;
    }

    get loadingValue() {
        if (this.total == 0) {
            return 0;
        }
        return (this.count / this.total) * 100;
    }

    constructor(
        public dialogRef: MatDialogRef<DeleteManyDialog>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            entityType: string;
            selectedIds: number[];
            delete: (ids: number[]) => void;
        },
        private cdr: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        this.count = 0;
        this.total = this.data.selectedIds.length;
        this.cdr.detectChanges();
        const numChunks = Math.ceil(this.total / CHUNK_SIZE);
        try {
            // Iterate through the chunks.
            for (let chunkIndex = 0; chunkIndex < numChunks; chunkIndex++) {
                // Get the chunk of file IDs.
                const chunk = this.data.selectedIds.slice(
                    chunkIndex * CHUNK_SIZE,
                    (chunkIndex + 1) * CHUNK_SIZE,
                );
                // Delete the chunk of files.
                await this.data.delete(chunk);
                // Update the progress bar.
                this.count += chunk.length;
                this.cdr.detectChanges();
            }
            this.status = Status.Complete;
            this.cdr.detectChanges();
        } catch (err) {
            console.error(err);
            this.status = Status.Fail;
            this.cdr.detectChanges();
        }
    }
}
