import { lineGraphConfig } from './charts/line-graph';
import { mapConfig } from './charts/map';
import { statisticsComparisonConfig } from './charts/statistics-comparison';
import { tableConfig } from './charts/table';
import { SignalResourceType } from './resource-type';
import { ChartSignalResource } from './signal';

export enum ChartType {
    LineGraph = 'line_graph',
    Table = 'table',
    Map = 'map',
    StatisticsComparison = 'statistics_comparison',
}

// Configure options for one specific chart type.
// Add fields here as required to configure different UI options that need
// chart-type-specific values.
export type ChartTypeConfig = {
    icon: string;
    canToggleSignalVisibility: boolean;
    supportedResourceTypes: SignalResourceType[];
    signalResourceDefaults: Partial<
        Record<SignalResourceType, Partial<ChartSignalResource>>
    >;
};

// Configure different UI options for each of the chart types.
export const chartTypeConfigs: Record<ChartType, ChartTypeConfig> = {
    [ChartType.LineGraph]: lineGraphConfig,
    [ChartType.Table]: tableConfig,
    [ChartType.Map]: mapConfig,
    [ChartType.StatisticsComparison]: statisticsComparisonConfig,
};

export const chartTypes = Object.values(ChartType) as ChartType[];
export const chartTypeNames = Object.fromEntries(
    chartTypes.map((key) => [key, `chart.type.${key}`]),
);

export function getTypeIcon(type: ChartType) {
    return chartTypeConfigs[type].icon;
}
