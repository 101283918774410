import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

@Injectable()
export class DialogService {
    constructor(private dialog: MatDialog) {}

    open<T, D = any, R = any>(
        component: ComponentType<T>,
        config?: MatDialogConfig<D>
    ): Promise<R> {
        return this.dialog.open(component, config).afterClosed().toPromise();
    }
}
