import { Signal, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, mergeAll, take } from 'rxjs/operators';
import { getCacheKey } from 'src/app/shared/cache';
import { isDefined, isEmpty } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { notNull } from 'src/app/shared/rxjs-operators';
import { applyParams } from './apply-params';
import { RawAccessAdapter } from './raw-access';
import {
    CountResult,
    CreateResult,
    TopLevelQueryParams,
} from './top-level-route-api';

const CHUNK_SIZE = 100;

enum ApiStatus {
    Unloaded, // Nothing is ready
    Preloading, // We are loading the chunk map
    Preloaded, // We have a chunk map
    Loading, // We are loading the items
    Loaded, // All items are loaded
}

export class DynamicApi<
    GetType,
    CreateType,
    PreviewType,
    QueryParamsType extends TopLevelQueryParams,
> {
    protected _name;
    private _cachedKey = '';
    protected _cache: Record<number, GetType> = {};
    private _previews: Record<number, WritableSignal<PreviewType>> = {};
    private _countCache$ = new BehaviorSubject<Record<string, CountResult>>({});
    private _chunkMap: Record<number, number> = {};
    private _status$ = new BehaviorSubject<ApiStatus>(ApiStatus.Unloaded);
    private _chunkIsLoading: Record<number, BehaviorSubject<boolean>> = {};
    private _current$ = new BehaviorSubject<GetType[]>(null);
    rawAccess: RawAccessAdapter<GetType, CreateType, QueryParamsType> = null;
    // Broadcast items when they are updated
    updated$ = new Subject<GetType>();
    // Broadcast items when they are deleted.
    deleted$ = new Subject<GetType>();
    // Broadcast items when they are updated OR deleted
    // Useful for triggers
    changed$ = of(this.updated$, this.deleted$).pipe(mergeAll());
    // Broadcast the ID of items when their previews are loaded.
    previewLoaded$ = new Subject<number>();

    get isStale() {
        return this.activeKey !== this._cachedKey;
    }

    get status() {
        return this._status$.value;
    }

    get isLoaded() {
        return this.status == ApiStatus.Loaded;
    }

    private get activeKey() {
        return getCacheKey();
    }

    private get _current() {
        return Object.values(this._cache);
    }

    constructor(name: string) {
        this._name = name;
    }

    /**
     * Get the count results from the backend, using the given params.
     * The results are cached. When `forceFetch` is true, the cache is ignored
     * and the results are re-fetched from the backend.
     */
    async count(
        params: Partial<QueryParamsType> = {},
        forceFetch = false,
    ): Promise<CountResult> {
        const serialized = JSON.stringify(params);
        const isNew = !isDefined(this._countCache$.value[serialized]);
        if (forceFetch || isNew) {
            this.updateCountCache(serialized, null);
            log.tag(this._name).info('wait for count');
            this.rawAccess.count(params).then((result) => {
                log.tag(this._name).info('got count');
                this.updateCountCache(serialized, result);
            });
        }
        // Fetch the count results from the cache.
        return new Promise((resolve) => {
            this._countCache$
                .pipe(
                    map((c) => c[serialized] ?? null),
                    notNull,
                    take(1),
                )
                .subscribe(resolve);
        });
    }

    /**
     * Load items and return an observable that will watch for updates.
     */
    listen(params: Partial<QueryParamsType> = {}): Observable<GetType[]> {
        if (
            this.isStale ||
            (this.status !== ApiStatus.Loading &&
                this.status !== ApiStatus.Loaded)
        ) {
            this.load();
        }
        const subject = this._current$.pipe(notNull);
        return isEmpty(params)
            ? subject
            : subject.pipe(map(() => this.current(params)));
    }

    current(params: Partial<QueryParamsType> = {}): GetType[] {
        return applyParams(this._current, params);
    }

    get(id: number) {
        return this._cache[id] ?? null;
    }

    // Asynchronously load an item.
    // The skipCache option indicates that we should skip the cache and
    // load directly from the backend.
    async getAsync(
        id: number,
        options: { skipCache?: boolean } = {},
    ): Promise<GetType> {
        options.skipCache ??= false;
        if (options.skipCache) {
            return this.rawAccess.get(id);
        }
        await this.preload();
        await this.loadChunk(this._chunkMap[id]);
        return this.get(id);
    }

    getId(item: GetType): number {
        // Override this method for `GetType` that don't have an id field.
        return item['id'];
    }

    /**
     * Get the preview signal for the given id.
     * Trigger loading the preview if it hasn't been loaded yet.
     */
    getPreview(id: number): Signal<PreviewType | null> {
        if (!isDefined(id)) {
            return signal(null);
        }
        if (!this._previews[id]) {
            this._previews[id] = signal(null);
            this.loadPreview(id).then((preview) => {
                this._previews[id].set(preview);
                this.previewLoaded$.next(id);
            });
        }
        return this._previews[id].asReadonly();
    }

    /**
     * Get a current snapshot of all the previews.
     * Filter by the given IDs, if provided.
     * Don't trigger loading any previews.
     */
    getPreviewsSnapshot(ids?: number[]): Record<number, PreviewType | null> {
        if (ids) {
            return Object.fromEntries(
                ids.map((id) => {
                    const signal = this._previews[id];
                    return [id, signal ? signal() : null];
                }),
            );
        } else {
            return Object.fromEntries(
                Object.entries(this._previews).map(([id, signal]) => [
                    id,
                    signal(),
                ]),
            );
        }
    }

    async create(data: CreateType): Promise<CreateResult> {
        const result = await this.rawAccess.create(data);
        // Clear the count cache
        this._countCache$.next({});
        if (result.success) {
            await this.reloadItem(result.id);
            await this.loadChunkMap();
        }
        return result;
    }

    async update(id: number, updates: Partial<CreateType>) {
        await this.batchUpdate({ [id]: updates });
    }

    async batchUpdate(allUpdates: Record<number, Partial<CreateType>>) {
        const ids = Object.keys(allUpdates).map((id) => +id);
        // Check that all IDs are valid
        for (const id of ids) {
            const item = this.get(id);
            if (!item) {
                throw Error(`Unknown ID: ${id}`);
            }
        }
        // Sync changes to the backend.
        for (const id of ids) {
            await this.rawAccess.update(id, allUpdates[id]);
        }
        // Update cache
        await this.loadChunkMap();
        for (const id of ids) {
            await this.reloadItem(id, true);
            this.updated$.next(this.get(id));
        }
        // Only broadcast update after all items have been updated
        this.broadcastUpdate();
    }

    async delete(ids: number[]) {
        await this.rawAccess.delete(ids);
        // Clear the count cache
        this._countCache$.next({});
        // Update the items
        ids.forEach((id) => {
            const item = this._cache[id];
            delete this._cache[id];
            this._previews[id]?.set(null);
            this.deleted$.next(item);
        });
        this.broadcastUpdate();
    }

    reset() {
        this._cachedKey = '';
        this._cache = {};
        this._previews = {};
        this._countCache$.next({});
        this._chunkMap = {};
        this._status$.next(ApiStatus.Unloaded);
        this._chunkIsLoading = {};
        this._current$.next(null);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadPreview(id: number): Promise<PreviewType> {
        throw new Error('The child class must implement this function.');
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    attachTriggers(): void {}

    async preload(): Promise<void> {
        if (this.status >= ApiStatus.Preloaded && !this.isStale) {
            // Already preloaded.
            return;
        } else if (this.status == ApiStatus.Preloading) {
            // Await for other preload call to complete
            await new Promise((resolve) =>
                this._status$
                    .pipe(
                        filter((s) => s == ApiStatus.Preloaded),
                        take(1),
                    )
                    .subscribe(resolve),
            );
            return;
        }
        this._status$.next(ApiStatus.Preloading);
        this._cache = {};
        this._cachedKey = this.activeKey;
        await this.loadChunkMap({ clearCountCache: false });
        this._status$.next(ApiStatus.Preloaded);
    }

    protected async loadChunkMap(options: { clearCountCache?: boolean } = {}) {
        log.tag(this._name).info('loadChunkMap start');
        if (options.clearCountCache ?? true) {
            this._countCache$.next({});
        }
        const count = await this.count();
        this._chunkMap = Object.fromEntries(
            count.ids.map((id, i) => [id, Math.floor(i / CHUNK_SIZE)]),
        );
    }

    private async load() {
        await this.preload();
        if (this.status == ApiStatus.Loaded) {
            return;
        } else if (this.status == ApiStatus.Loading) {
            // Await for other preload call to complete
            await new Promise((resolve) =>
                this._status$
                    .pipe(
                        filter((s) => s == ApiStatus.Loaded),
                        take(1),
                    )
                    .subscribe(resolve),
            );
            return;
        }
        this._status$.next(ApiStatus.Loading);
        const count = await this.count();
        const numChunks = Math.ceil(count.count / CHUNK_SIZE);
        for (let chunkId = 0; chunkId < numChunks; chunkId++) {
            await this.loadChunk(chunkId);
        }
        this._status$.next(ApiStatus.Loaded);
        // Broadcast final complete results.
        this.broadcastUpdate();
    }

    private async loadChunk(chunkId: number) {
        if (!isDefined(chunkId)) {
            return;
        }
        if (this._chunkIsLoading[chunkId]) {
            // Wait for the chunk to be loaded
            await new Promise((resolve) =>
                this._chunkIsLoading[chunkId]
                    .pipe(
                        filter((loaded) => loaded),
                        take(1),
                    )
                    .subscribe(resolve),
            );
        } else {
            log.tag(this._name).info('load chunk', chunkId);
            this._chunkIsLoading[chunkId] = new BehaviorSubject(false);
            const chunk = await this.rawAccess.loadChunk(chunkId, CHUNK_SIZE);
            chunk.forEach((item) => (this._cache[this.getId(item)] = item));
            this._chunkIsLoading[chunkId].next(true);
            this.broadcastUpdate();
        }
    }

    /**
     * This is useful for reloading a single item, then broadcasting the update.
     */
    protected async reloadItem(id: number, partOfBatch = false) {
        this._cache[id] = await this.rawAccess.get(id);
        try {
            const preview = await this.loadPreview(id);
            if (this._previews[id]) {
                this._previews[id].set(preview);
            } else {
                this._previews[id] = signal(preview);
            }
            this.previewLoaded$.next(id);
        } catch {
            // some API don't implement loadPreview, so do nothing
        }
        if (!partOfBatch) {
            this.broadcastUpdate();
        }
    }

    protected broadcastUpdate() {
        this._current$.next(this._current);
    }

    protected assertLoaded() {
        if (this.isStale || this.status != ApiStatus.Loaded) {
            throw new Error(
                'Cache not fully loaded. Please call .listen() before trying to access cache.',
            );
        }
    }

    // Update a key in the count cache, broadcast out the new count cache.
    private updateCountCache(key: string, value: CountResult) {
        this._countCache$.next({ ...this._countCache$.value, [key]: value });
    }
}

/**
 * A convenient way to make sure all the given dynamic APIs are completely
 * loaded before accessing them.
 */
export const ensureLoaded = (apis: { listen; isLoaded: boolean }[]) =>
    new Promise((resolve) =>
        combineLatest(
            apis.map((api) => api.listen().pipe(filter(() => api.isLoaded))),
        ).subscribe(resolve),
    );
