import { NgModule } from '@angular/core';
import { IntersectionObserverDirective } from './intersection-observer/intersection-observer';

const directives = [IntersectionObserverDirective];

@NgModule({
    imports: [],
    declarations: directives,
    exports: directives,
})
export class DirectivesModule {}
