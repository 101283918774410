import { BehaviorSubject } from 'rxjs';
import { generateRandomId } from '../../shared/common';
import { ChartSignal, StatisticSignal } from './signal';
import { SignalResourceType } from './resource-type';
import {
    ChartCanMessage,
    combineSignals,
    LogSignalResource,
} from './signals/log';
import { ChartType } from './chart-type';

export { ChartType };

export interface SerializedChart {
    title: string;
    type: ChartType;
    signals?: object[];
    settings?: any;
}

// TODO: remove this?
export interface SignalRawData {
    deviceId: number;
    signalId: number;
    values: Map<number, string | number | boolean>;
}

// TODO: remove this?
type SignalDevicesValues = Record<string, string | number | boolean>;
export type SignalsSnapshot = Record<string, SignalDevicesValues>;

// TODO: move this to file for settings
export interface ChartThreshold {
    yValue: number;
}

export class Chart {
    id: string;

    toggleScale$ = new BehaviorSubject<void>(undefined);

    get serialized(): SerializedChart {
        return {
            title: this.title,
            type: this.type,
            signals: this.signals,
            settings: this.settings,
        };
    }

    get statisticSignals(): StatisticSignal[] {
        throw new Error('Not implemented');
    }

    constructor(
        public title: string,
        public type: ChartType,
        public signals: ChartSignal[],
        public settings: any,
    ) {
        this.id = generateRandomId();
    }

    // Use the array of messages to construct a list of the signals used by the
    // signals in this chart.
    allSignals(messages: Array<ChartCanMessage>) {
        const safeList = this.signals
            .filter((s) => s.resource.type === SignalResourceType.Log)
            .map((s) => (s.resource as LogSignalResource).signalId);
        return combineSignals(messages).filter((s) => safeList.includes(s.id));
    }
}

// Configuration for a value series on an eCharts chart.
export interface EChartSeriesConfig {
    id: string;
    name: string;
    type: string;
    showSymbol: boolean;
    smooth: boolean;
    lineStyle: { normal: { width: number } };
    data: [Date, number][];
}
