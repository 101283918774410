<sz-overlay
    [name]="name"
    [showTitle]="showTitle"
    (isCollapsedChange)="isCollapsed = $event"
>
    <div
        class="hidden flex-row gap-1 px-2 pb-1"
        [style.display]="options.length > 5 ? 'flex' : 'hidden'"
        header
    >
        <sz-input
            class="min-w-0 grow"
            icon="magnify"
            [formControl]="filterControl"
            [small]="true"
        />
        @if (!exclusive) {
            <input
                #toggleAllCheckbox
                type="checkbox"
                class="checkbox-accent checkbox checkbox-sm m-1"
                [class.hidden]="hideSelectAll"
                [disabled]="areAllOptionsDisabled"
                (change)="toggleAll()"
            />
        }
    </div>
    <div *ngIf="options.length === 0">
        {{ 'filter.options.none' | translate }}
    </div>
    @for (option of options | searchOptions: filter; track option.value) {
        <div class="form-control">
            <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
            <label class="label cursor-pointer gap-2">
                <span
                    [ngClass]="option.disabled ? 'opacity-50' : 'opacity-100'"
                    class="label-text flex items-center gap-1 grow truncate"
                    [matTooltip]="option.name | translate"
                >
                    {{ option.name | translate }}
                </span>
                @if (exclusive) {
                    <input
                        type="radio"
                        class="radio-accent radio radio-sm"
                        [disabled]="option.disabled"
                        [checked]="isChecked(option.value)"
                        (change)="toggleOption(option, $event.target)"
                    />
                } @else {
                    <input
                        type="checkbox"
                        class="checkbox-accent checkbox checkbox-sm"
                        [disabled]="option.disabled"
                        [checked]="isChecked(option.value)"
                        (change)="toggleOption(option, $event.target)"
                    />
                }
            </label>
        </div>
    }
</sz-overlay>
