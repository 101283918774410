import {
    AfterContentInit,
    Component,
    Injector,
    Input,
    Type,
    forwardRef,
    inject,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
    NgControl,
} from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { SelectGroup, SelectOption } from 'src/app/settings/settings.model';
import { isEmpty } from 'src/app/shared/common';

@Component({
    selector: 'sz-select',
    templateUrl: './select.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { overlayPanelClass: 'custom-select-overlay' },
        },
    ],
})
export class SelectComponent implements ControlValueAccessor, AfterContentInit {
    private injector = inject(Injector);
    private translate = inject(TranslateService);

    @Input({ required: true }) options: SelectOption[] = [];
    @Input() groups: SelectGroup[] = [];
    @Input() isMultiple = false;
    get errorMessage(): string {
        const errors = this.control.errors;
        if (isEmpty(errors)) {
            return;
        }
        const generators = {
            required: () => this.translate.instant('form.error.required'),
            ...(this.errorMessages ?? {}),
        };
        for (const errorCode in generators) {
            const error = errors[errorCode];
            if (error) {
                return generators[errorCode](error);
            }
        }
        return 'UNKNOWN ERROR';
    }
    @Input() errorMessages?: Record<
        string,
        (error: Record<string, unknown>) => string
    >;

    control = new FormControl();

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value: string | number) => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    ngAfterContentInit() {
        // This strategy comes from https://stackoverflow.com/a/51126965
        const ngControl = this.injector.get<NgControl>(
            NgControl as Type<NgControl>,
        );
        if (ngControl) {
            this.control = ngControl.control as FormControl;
        }
    }

    getGroupOptions(groupId: string) {
        return this.options.filter((option) => option.groupId === groupId);
    }

    writeValue(value) {
        this.onChange(value);
    }

    registerOnChange(fn: (value) => void) {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void) {
        this.onTouched = fn;
    }
}
