import {
    filterBy,
    isDefined,
    offsetAndLimitBy,
    searchBy,
    sortBy,
} from 'src/app/shared/common';

/**
 * Apply search, sort, offset, limit, and filters to the given list of items.
 */
export const applyParams = <T>(
    items: T[],
    params: Record<string, unknown>,
): T[] => {
    params = { ...params }; // Don't mutate the passed in params.
    let searchTerm = '';
    let searchFields = [];
    if (isDefined(params['searchTerm'])) {
        searchTerm = params['searchTerm'] as string;
        searchFields = (params['searchFields'] as string).split(',');
        delete params['searchTerm'];
        delete params['searchFields'];
    }
    let sortField = null;
    let descendingOrder = false;
    if (isDefined(params['sort'])) {
        sortField = params['sort'];
        descendingOrder = params['sortOrder'] === 'DESC';
        delete params['sort'];
        delete params['sortOrder'];
    }
    let offset = null;
    if (isDefined(params['offset'])) {
        offset = params['offset'];
        delete params['offset'];
    }
    let limit = null;
    if (isDefined(params['limit'])) {
        limit = params['limit'];
        delete params['limit'];
    }
    for (const filter of Object.keys(params)) {
        if (filter.includes('[')) {
            throw new Error(`Filter "${filter}" is not supported`);
        }
    }
    const output = items
        .filter(filterBy(params))
        .filter(searchBy(searchFields, searchTerm));
    if (sortField) {
        output.sort(sortBy(sortField, descendingOrder));
    }
    return output.filter(offsetAndLimitBy(offset, limit));
};
