import { SignalResourceType } from '../resource-type';

export type LogSignalResource = {
    type: SignalResourceType.Log;
    deviceIds: number[];
    signalId: number;
    stat: Stat;
    combineData: boolean;
    threshold?: Threshold;
};

export type Threshold = {
    value: number;
    operator: Operator;
};

export enum Operator {
    Eq = 'eq',
    Ne = 'ne',
    Lt = 'lt',
    Lte = 'lte',
    Gt = 'gt',
    Gte = 'gte',
}

export enum Stat {
    Sum = 'sum',
    Avg = 'avg',
    Min = 'min',
    Max = 'max',
    Count = 'count',
}

export interface ChartCanMessage {
    id: number;
    descriptor: string;
    signals: ChartCanSignal[];
    deviceIds: number[];
    isLogged: boolean;
    modelId: number;
    modelName: string;
}

export interface ChartCanSignal {
    id: number;
    name: string;
    unit: string;
    dataType: string;
}

export const combineSignals = (
    messages: Array<ChartCanMessage>,
): ChartCanSignal[] =>
    messages.reduce((signals, message) => signals.concat(message.signals), []);
