import { DestroyRef, Injectable, Signal, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    from,
    of,
} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    mergeAll,
    skip,
} from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { FilterOption } from 'src/app/common/layout/multi-select/multi-select.component';
import { ListFilterConfig } from 'src/app/common/list-page/common';
import { DeleteManyDialog } from 'src/app/common/list-page/delete-many-dialog/delete-many-dialog.component';
import { ConfirmDeleteDialog } from 'src/app/dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { ButtonConfig } from 'src/app/shared/button';
import { parseQueryArray } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import { PinType, pinTypes } from 'src/app/shared/models/pin';
import { BasePreview, ListOverlayDetail } from 'src/app/shared/models/preview';
import { ApiService } from '../api/api.service';
import { CountResult } from '../api/top-level-route-api';
import { DialogService } from '../dialog/dialog.service';
import { ListPageWorkerController } from './workers/controller';
import { canWrite } from 'src/app/shared/auth-utils';

export enum ListPageStatus {
    Unloaded = 'unloaded',
    Preloading = 'preloading',
    Loading = 'loading',
    Empty = 'empty',
    Loaded = 'loaded',
}

type ListPageApiRoute<GetType> = {
    getPreview: (id: number) => Signal<BasePreview>;
    getPreviewsSnapshot(ids?: number[]): Record<number, BasePreview | null>;
    preload: () => Promise<void>;
    count: (params) => Promise<CountResult>;
    delete: (ids: number[]) => Promise<void>;
    previewLoaded$: Observable<number>;
    updated$: Observable<GetType>;
    deleted$: Observable<GetType>;
};

type StartConfig<GetType> = {
    entityType: string;
    apiRoute: ListPageApiRoute<GetType>;
    destroyRef: DestroyRef;
    showNewButton: boolean;
    showDeleteButton: boolean;
    filters: ListFilterConfig[];
    searchFields?: string[];
    generateStats?: (sums: Record<string, number>) => ListOverlayDetail[];
    generateOverlayButtons?: (selectedIds: number[]) => ButtonConfig[];
};

@Injectable()
export class ListPageService<GetType> {
    log = log.tag('list service');
    entityType = 'unknown';
    currentCount: CountResult;
    persistScreen = false;
    destroyRef: DestroyRef;
    showNewButton = false;
    showDeleteButton = false;
    searchFields = [];
    generateStats: (sums: Record<string, number>) => ListOverlayDetail[];
    generateOverlayButtons: (selectedIds: number[]) => ButtonConfig[];
    filters: ListFilterConfig[] = [];
    overviewStats: ListOverlayDetail[] = [];
    overlayButtons: ButtonConfig[] = [];

    status$ = new BehaviorSubject<ListPageStatus>(ListPageStatus.Unloaded);
    selectedIds$ = new BehaviorSubject<number[]>([]);
    rows$ = new BehaviorSubject<number[][]>([]);

    private activatedRoute = inject(ActivatedRoute);
    private app = inject(AppService);
    private api = inject(ApiService);
    private dialog = inject(DialogService);
    private router = inject(Router);
    private pinMapping: Record<number, number> = {};
    private _workerController = new ListPageWorkerController();
    private _apiRoute: ListPageApiRoute<GetType>;
    private pins$: Observable<string>;
    private needFreshData$ = new Subject<void>();
    private preloadSubscription: Subscription;
    private loadSubscription: Subscription;
    private ids: number[] = [];

    get hasPins() {
        return pinTypes.includes(this.entityType as PinType);
    }

    set status(newStatus: ListPageStatus) {
        this.log.info('new status:', newStatus);
        this.status$.next(newStatus);
    }

    get numColumns() {
        const mapping = { '2xl': 3, xl: 2 };
        let numColumns = 1;
        for (const key in mapping) {
            if (this.app.hasBreakpoint(key)) {
                numColumns = mapping[key];
                break;
            }
        }
        return numColumns;
    }

    get selectedIds() {
        return this.selectedIds$.value;
    }

    /**
     * Trigger the whole loading process
     */
    start({
        entityType,
        apiRoute,
        destroyRef,
        showNewButton,
        showDeleteButton,
        filters,
        searchFields,
        generateStats,
        generateOverlayButtons,
    }: StartConfig<GetType>) {
        this.entityType = entityType;
        this._apiRoute = apiRoute;
        this.log = log.tag(`${entityType} list service`);
        this.destroyRef = destroyRef;
        this.showNewButton = showNewButton && canWrite();
        this.showDeleteButton = showDeleteButton && canWrite();
        this.filters = filters;
        this.searchFields = searchFields ?? ['name'];
        this.generateStats = generateStats ?? (() => []);
        this.generateOverlayButtons = generateOverlayButtons ?? (() => []);

        this.destroyRef.onDestroy(() => {
            this.preloadSubscription?.unsubscribe();
            this.loadSubscription?.unsubscribe();
            this.status = ListPageStatus.Unloaded;
            this.reset();
        });

        this.selectedIds$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.updateOverlays());

        this.pins$ = this.api.pins
            .listen({ type: this.entityType as PinType })
            .pipe(
                map((pins) => JSON.stringify(pins)),
                distinctUntilChanged(),
                map((pinsString) => {
                    const pins = this.api.pins.ofType(
                        this.entityType as PinType,
                    );
                    this.pinMapping = Object.fromEntries(
                        pins.map((pin) => [pin.itemId, pin.order]),
                    );
                    return pinsString;
                }),
            );
        this.preload();
    }

    requestFreshLoad() {
        this.needFreshData$.next();
    }

    /**
     * Preload the chunk map for the data
     */
    private preload() {
        this.preloadSubscription?.unsubscribe();
        this.loadSubscription?.unsubscribe();

        const freshLoad = async () => {
            this.status = ListPageStatus.Preloading;
            await this._apiRoute.preload();
            const params = this.getCountParams();
            this.currentCount = await this._apiRoute.count(params);
            this.updateOverlays();
            this.load();
        };

        this.preloadSubscription = combineObservables({
            deletes: this._apiRoute.deleted$,
            queryParams: this.activatedRoute.queryParams.pipe(
                map((params) => JSON.stringify(params)),
                // We only care about changes AFTER the first query params
                skip(1),
                distinctUntilChanged(),
            ),
            freshLoadRequest: this.needFreshData$,
        }).subscribe((update) => {
            // TODO: remove this once we have all performance issues worked out
            this.log.info('FRESH LOAD', update);
            this.reset();
            freshLoad();
        });

        freshLoad();
    }

    /**
     * Load the initial items
     */
    private load() {
        this.loadSubscription?.unsubscribe();

        this.status = ListPageStatus.Loading;
        if (this.currentCount.count == 0) {
            this.status = ListPageStatus.Empty;
            return;
        }

        if (this.persistScreen) {
            // Jump straight to showing the previously loaded data
            this.status = ListPageStatus.Loaded;
        }

        const updateDisplayedData = async () => {
            if (this.currentCount == null) {
                return;
            }
            const currentIds = this.currentCount.ids;
            // TODO: this call can take up a lot of time if currentIds is very large
            //       figure out how to improve performance
            const items = this._apiRoute.getPreviewsSnapshot(currentIds);
            // Handle intensive processing on a background thread
            const { ids, rows } =
                await this._workerController.prepareForDisplay({
                    ids: currentIds,
                    items,
                    pinMapping: this.pinMapping,
                    numColumns: this.numColumns,
                });
            this.ids = ids;
            this.rows$.next(rows);
            this.status = ListPageStatus.Loaded;
            this.persistScreen = true;
        };

        this.loadSubscription = combineObservables({
            firstTrigger: of(null),
            previewLoaded: this._apiRoute.previewLoaded$,
            pins: this.pins$,
            columns: this.app.breakpoint$,
            updates: this._apiRoute.updated$,
        }).subscribe(async (update) => {
            // TODO: remove this once we have all performance issues worked out
            this.log.info('UPDATE DISPLAY DATA', update);
            updateDisplayedData();
        });
    }

    getPreviewSignal(id: number) {
        return this._apiRoute.getPreview(id);
    }

    goTo(id: number) {
        this.router.navigate([`${this.entityType}s/${id}`]);
    }

    select(id: number, event: MouseEvent) {
        if (event.ctrlKey) {
            if (this.selectedIds.includes(id)) {
                // Remove the item from the selection if it is selected
                this.selectedIds$.next(
                    this.selectedIds.filter((rId) => rId != id),
                );
            } else {
                // Otherwise add the item to the selection
                this.selectedIds$.next([...this.selectedIds, id]);
            }
        } else if (event.shiftKey) {
            if (this.selectedIds.length > 0 && !this.selectedIds.includes(id)) {
                // Select every item between the last selected item and the
                // clicked item
                const lastId = this.selectedIds[this.selectedIds.length - 1];
                const index = this.ids.findIndex((oId) => oId == lastId);
                if (index == -1) {
                    return;
                }
                const thisIndex = this.ids.findIndex((oId) => oId == id);
                const delta = Math.sign(thisIndex - index);
                for (let i = index + delta; i != thisIndex; i += delta) {
                    if (!this.selectedIds.includes(this.ids[i])) {
                        this.selectedIds$.next([
                            ...this.selectedIds,
                            this.ids[i],
                        ]);
                    }
                }
                this.selectedIds$.next([...this.selectedIds, id]);
            }
        } else {
            if (this.selectedIds.length == 1 && this.selectedIds[0] == id) {
                // This item is already selected, so deselect it
                this.selectedIds$.next([]);
            } else {
                // Select only this item
                this.selectedIds$.next([id]);
            }
        }
    }

    clearSelection() {
        this.selectedIds$.next([]);
    }

    private getCountParams() {
        const options: Record<string, string> = {};
        const searchTerm =
            this.activatedRoute.snapshot.queryParams['search'] ?? '';
        if (searchTerm) {
            options.searchTerm = searchTerm;
            options.searchFields = this.searchFields.join(',');
        }
        for (const filter of Object.values(this.filters)) {
            const selected = this.getSelected(filter);
            if (selected != null) {
                if ('exclusive' in filter && filter.exclusive) {
                    options[filter.backendId] = selected[0].toString();
                } else if (filter.arrayField) {
                    options[filter.backendId + '[intersects]'] =
                        selected.join(',');
                } else {
                    options[filter.backendId + '[in]'] = selected.join(',');
                }
            }
        }
        return options;
    }

    async getSelectedOptions(
        filter: ListFilterConfig,
    ): Promise<FilterOption[]> {
        const selectedValues = this.getSelected(filter) ?? [];
        if ('pickList' in filter) {
            return filter.pickList.filter((option) =>
                selectedValues.includes(option.value),
            );
        } else {
            return await Promise.all(
                selectedValues.map((value) =>
                    this.api[`${filter.id}s`].asFilterOption(value),
                ),
            );
        }
    }

    /**
     * Get the currently selected options for a filter.
     */
    private getSelected(filter: ListFilterConfig) {
        const value = this.activatedRoute.snapshot.queryParams[filter.id];
        const selected = parseQueryArray(value);
        if ('exclusive' in filter && filter.exclusive) {
            if (selected == null) {
                // By default, select the first option
                return [filter.pickList[0].value];
            } else {
                // Only one option can be selected
                return selected.slice(0, 1);
            }
        }
        return selected;
    }

    private updateOverlays() {
        if (this.currentCount) {
            this.overviewStats = this.generateStats(this.currentCount.sums);
            this.overlayButtons = this.generateOverlayButtons(this.selectedIds);
        }
    }

    async deleteSelected() {
        const confirmResponse = await this.dialog.open(ConfirmDeleteDialog, {
            data: {
                entityType: this.entityType,
                count: this.selectedIds.length,
            },
        });
        if (!confirmResponse) {
            return;
        }
        await this.dialog.open(DeleteManyDialog, {
            width: '300px',
            disableClose: true,
            data: {
                entityType: this.entityType,
                selectedIds: this.selectedIds,
                delete: (ids) => this._apiRoute.delete(ids),
            },
        });
        this.clearSelection();
    }

    reset() {
        this.currentCount = null;
        this.ids = [];
        this.selectedIds$.next([]);
        this.rows$.next([]);
        this.persistScreen = false;
    }
}

/**
 * Merge a bunch of observables into a single main observable that only fires
 * at a limited rate. Also tags the trigger observable on each fire.
 */
const combineObservables = (observables: Record<string, Observable<unknown>>) =>
    from(
        Object.entries(observables).map(([trigger, observable]) =>
            observable.pipe(map((value) => ({ trigger, value }))),
        ),
    ).pipe(mergeAll(), debounceTime(250));
