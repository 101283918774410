import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { ListPageService } from 'src/app/services/list-page/list-page.service';

@Component({
    selector: 'sz-list-layout',
    templateUrl: './list-layout.component.html',
    styles: [
        `
            ::ng-deep .cdk-virtual-scroll-content-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 12px;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListLayoutComponent<GetType> {
    @Output() add = new EventEmitter();

    constructor(public listPageService: ListPageService<GetType>) {}
}
