import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class ButtonWidget extends Widget {
    type = WidgetType.ButtonWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'button_color',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.color',
                    previewValue: 'blue',
                    defaultValue: 'blue',
                },
                {
                    id: 'button_text',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.button_text',
                    previewValue: 'Button',
                    defaultValue: '',
                },
                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
            ],
            settings
        );
    }
}
