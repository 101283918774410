import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ImageService {
    private imageObservables: Record<string, Observable<Blob>> = {};

    constructor(private http: HttpClient) {}

    async getImage(url: string) {
        let image = this.imageObservables[url];
        if (!image) {
            image = this.http
                .get(url, { responseType: 'blob' })
                .pipe(shareReplay());
            this.imageObservables[url] = image;
        }
        return URL.createObjectURL(await image.toPromise());
    }
}
