import {
    Alert,
    AlertsQueryParams,
    CopyAlert,
    CreateAlert,
} from 'src/app/shared/models/alert';
import { AlertPreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { CreateResult, TopLevelRouteApi } from './top-level-route-api';

export class RawAlertsApi extends TopLevelRouteApi<
    Alert,
    CreateAlert,
    AlertsQueryParams
> {
    get path() {
        return 'alerts';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Alert {
        return {
            ...data,
            dateConditions: data.dateConditions?.map((d) => ({
                ...d,
                days: decodeDays(d.days),
            })),
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.split(','),
                phoneNumbers: a.phoneNumbers?.split(','),
            })),
            isActive: !!data.isActive,
        };
    }

    transformFrontendData(data): Alert {
        return {
            ...data,
            dateConditions: data.dateConditions?.map((d) => ({
                ...d,
                days: encodeDays(d.days),
            })),
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.join(','),
                phoneNumbers: a.phoneNumbers?.join(','),
            })),
        };
    }

    async copy(data: CopyAlert): Promise<CreateResult> {
        return (await this.api.client
            .post(this.basePath, data)
            .toPromise()) as CreateResult;
    }
}

function decodeDays(daysValue: string) {
    const binaryDaysArray = daysValue.padStart(7, '0').split('').reverse();
    const selectedDaysValues = [];
    binaryDaysArray.forEach((char, index) => {
        if (char !== '0') {
            selectedDaysValues.push(index);
        }
    });
    return selectedDaysValues;
}

function encodeDays(days: number[]) {
    const daysValueMap = [1, 10, 100, 1000, 10000, 100000, 1000000];
    return String(days.reduce((total, day) => total + daysValueMap[day], 0));
}

export class AlertsApi extends DynamicApi<
    Alert,
    CreateAlert,
    AlertPreview,
    AlertsQueryParams
> {
    raw: RawAlertsApi;
    rawAccess: RawAccessAdapter<Alert, CreateAlert, AlertsQueryParams>;

    constructor(private api: ApiService) {
        super('alerts');
        this.raw = new RawAlertsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
    }

    async loadPreview(id: number): Promise<AlertPreview> {
        const alert = await this.getAsync(id);
        const organization = await this.api.organizations.getAsync(
            alert.customerId,
        );
        return {
            type: 'alert',
            id,
            title: alert.name,
            subtitle: organization?.name ?? '',
            deviceIds: alert?.deviceIds,
            item: alert,
        };
    }

    async copy(data: CopyAlert): Promise<CreateResult> {
        const copy = await this.raw.copy(data);
        if (copy.success) {
            await this.reloadItem(copy.id);
            await this.loadChunkMap();
        }
        return copy;
    }
}
