<a
    id="btn"
    class="flex items-center justify-center outline-none px-3"
    [style]="dynamicStyles"
    [ngClass]="[
        variantClass,
        buttonSizeClasses,
        disabled ? 'opacity-25 pointer-events-none' : 'cursor-pointer'
    ]"
    [routerLink]="link"
    [queryParams]="queryParams"
    tabindex="0"
>
    @if (showSpinner) {
        <sz-spinner [size]="size" />
    } @else {
        @if (icon) {
            <div [ngClass]="iconContainerClasses" class="mr-2">
                <div>
                    <mat-icon [svgIcon]="icon" [inline]="true"></mat-icon>
                </div>
            </div>
        }
        <span>{{ text | translate }}</span>
    }
</a>
