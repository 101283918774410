import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { SpinnerComponent } from '../display/spinner/spinner.component';
import { CachedImageComponent } from './cached-image/cached-image.component';
import { IllustrationComponent } from './illustration/illustration.component';
import { ImagePickerComponent } from './image-picker/image-picker.component';
import { ButtonModule } from '../../common/buttons/buttons.module';

const components = [
    ImagePickerComponent,
    CachedImageComponent,
    IllustrationComponent,
];

@NgModule({
    imports: [AppMaterialModule, SpinnerComponent, ButtonModule],
    declarations: components,
    exports: components,
})
export class ImagesModule {}
