import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CreateModule, Module } from 'src/app/shared/models/module';
import { ModulePreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface ModulesQueryParams extends TopLevelQueryParams {
    modelId?: number;
}

class RawModulesApi extends TopLevelRouteApi<
    Module,
    CreateModule,
    ModulesQueryParams
> {
    get path() {
        return 'modules';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class ModulesApi extends DynamicApi<
    Module,
    CreateModule,
    ModulePreview,
    ModulesQueryParams
> {
    private raw: RawModulesApi;
    rawAccess: RawAccessAdapter<Module, CreateModule, ModulesQueryParams>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super('modules');
        this.raw = new RawModulesApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'modelId',
            sortOrder: 'ASC',
        });
        this.translate = inject(TranslateService);
    }

    async loadPreview(id: number): Promise<ModulePreview> {
        const module = await this.getAsync(id);
        const model = await this.api.models.getAsync(module.modelId);
        return {
            type: 'module',
            id,
            title: module.name,
            subtitle: model?.name,
            details: [
                { name: 'module.key', value: module.key },
                { name: 'module.order', value: module.order.toString() },
            ],
            item: module,
            model,
        };
    }

    async reorder(ids: number[]) {
        await this.batchUpdate(
            Object.fromEntries(ids.map((id, order) => [id, { order }])),
        );
    }
}
