import {
    CreateRoadmapEntry,
    RoadmapEntry,
} from 'src/app/shared/models/roadmap';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';
import { ApiService } from './api.service';

interface RoadmapEntriesQueryParams extends TopLevelQueryParams {
    isActive?: boolean;
}

export class RoadmapEntriesApi extends TopLevelRouteApi<
    RoadmapEntry,
    CreateRoadmapEntry,
    RoadmapEntriesQueryParams
> {
    get path() {
        return 'roadmap-entries';
    }

    constructor(api: ApiService) {
        super(api);
    }
}
