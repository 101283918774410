import { Component, EventEmitter, Input, Output } from '@angular/core';

export type OverlayButtonType = 'collapse' | 'close';

@Component({
    selector: 'sz-overlay-title-bar',
    templateUrl: 'overlay-title-bar.component.html',
})
export class OverlayTitleBarComponent {
    @Input({ required: true }) isCollapsed: boolean;
    @Input() buttonType: OverlayButtonType = 'collapse';
    @Input() collapsible = true;
    @Output() toggleCollapsed = new EventEmitter();
}
