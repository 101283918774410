import { EvaluationContext } from './evaluation-context';
import { Expression, IExpressionError } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionOperator } from './operator';
import { ExpressionType, isType, typeName } from './type';

export class ExpressionOperation extends Expression {
    operator: ExpressionOperator;

    constructor(operator: ExpressionOperator, children: Array<Expression>) {
        super();
        this.operator = operator;
        this._children = children;
    }

    get kind(): ExpressionKind {
        return ExpressionKind.Operation;
    }

    get data(): unknown[] {
        return [
            this.kind,
            this.operator.operator,
            this.children.map((c) => c.data),
        ];
    }

    get type(): ExpressionType {
        return this.operator.outputType;
    }

    get error(): IExpressionError {
        if (this.children.length != this.operator.inputTypes.length) {
            return {
                term: 'filter.error.wrong_number_children',
                params: {
                    count: this.operator.inputTypes.length,
                },
            };
        }
        for (let i = 0; i < this.operator.inputTypes.length; i++) {
            const child: Expression = this.children[i];
            const expectedType: ExpressionType = this.operator.inputTypes[i];
            if (
                child.type != ExpressionType.Null &&
                !isType(child.type, expectedType)
            ) {
                return {
                    term: 'filter.error.wrong_operand_type',
                    params: {
                        number: i + 1,
                        expectedType: typeName(expectedType),
                        actualType: typeName(child.type),
                    },
                };
            }
        }
        return {};
    }

    toJavascript(): string {
        return this.operator.toJavascript(
            this.children.map((c) => c.toJavascript()),
        );
    }

    clone(): ExpressionOperation {
        return new ExpressionOperation(
            this.operator,
            this.children.map((c) => c.clone()),
        );
    }

    evaluate(context: EvaluationContext) {
        const operandValues = this._children.map((c) => c.evaluate(context));
        if (operandValues.includes(null)) {
            // Missing values should propagate.
            return null;
        }
        return this.operator.evaluate(operandValues);
    }
}
