<sz-overlay
    name="common.preview"
    buttonType="close"
    (isCollapsedChange)="clearSelection.emit()"
>
    @if (preview) {
        <div class="flex flex-col gap-2">
            <div class="flex flex-row items-center gap-2">
                @if (preview.imageUrl) {
                    <sz-image
                        alt="model_editor.model_image"
                        class="h-12 w-12 min-w-[48px] me-1"
                        classList="rounded-full"
                        [src]="preview.imageUrl"
                    />
                } @else if (preview.icon) {
                    <div class="h-6 w-6 mx-2">
                        <mat-icon [svgIcon]="preview.icon" [inline]="true" />
                    </div>
                }
                <div class="truncate flex flex-col gap-1">
                    <div
                        class="font-title text-lg truncate leading-none"
                        [matTooltip]="preview.title"
                    >
                        {{ preview.title }}
                    </div>
                    <span
                        class="text-sm leading-none"
                        [matTooltip]="preview.subtitle"
                    >
                        {{ preview.subtitle }}
                    </span>
                </div>
            </div>
            @if (devices) {
                <div class="flex flex-row flex-wrap gap-1">
                    @for (device of devices | async; track device.id) {
                        @if (device) {
                            <sz-chip
                                class="cursor-pointer truncate"
                                icon="access-point"
                                [text]="device.identifier"
                                (click)="goToDevice(device.id)"
                            />
                        }
                    }
                </div>
            }
            @if (preview.details) {
                <sz-list-overlay-details [details]="preview.details" />
            }
            <div class="flex flex-row items-end">
                <div class="flex flex-row gap-1 flex-wrap">
                    <sz-icon-button
                        icon="open-in-app"
                        size="small"
                        color="primary"
                        [link]="'./' + preview.id"
                        [matTooltip]="'common.open' | translate"
                    />
                    @for (button of buttons; track button.text) {
                        @if (button.show ? button.show() : true) {
                            <sz-icon-button
                                [icon]="button.icon"
                                size="small"
                                [matTooltip]="button.text | translate"
                                [link]="button.link"
                                [queryParams]="button.linkParams"
                                (click)="
                                    button.onClick ? button.onClick() : null
                                "
                            />
                        }
                    }
                    @if (showDeleteButton) {
                        <sz-icon-button
                            icon="delete"
                            size="small"
                            color="warn"
                            [matTooltip]="'delete' | translate"
                            (click)="delete.emit()"
                        />
                    }
                </div>
            </div>
        </div>
    }
</sz-overlay>
