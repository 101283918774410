<div class="flex items-center justify-center">
    @if (isBoolean(value)) {
        <div class="size-6 flex items-center justify-center">
            <mat-icon [style.color]="color" [svgIcon]="icon" [inline]="true" />
        </div>
    } @else if (isNumber(value)) {
        {{ value | number: '0.1-5' }}
    } @else {
        {{ value }}
    }
</div>
