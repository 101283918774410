import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class MonitorGridWidget extends Widget {
    type = WidgetType.MonitorGridWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'datasets',
                    type: WidgetSettingType.Repeated,
                    name: 'widget.settings.datasets',
                    previewValue: [
                        {
                            title: 'Engine Speed',
                            data: '290',
                            unit: 'RPM',
                            background: '"green"',
                        },
                    ],
                    config: {
                        children: [
                            {
                                id: 'title',
                                type: WidgetSettingType.Text,
                                name: 'widget.settings.title',
                                defaultValue: 'Title',
                                previewValue: null,
                            },
                            {
                                id: 'data',
                                type: WidgetSettingType.Calculated,
                                name: 'widget.settings.value',
                                previewValue: null,
                            },
                            {
                                id: 'unit',
                                type: WidgetSettingType.Text,
                                name: 'widget.settings.unit',
                                defaultValue: '',
                                previewValue: null,
                            },
                            {
                                id: 'background',
                                type: WidgetSettingType.Calculated,
                                name: 'widget.settings.background',
                                defaultValue: '',
                                previewValue: null,
                            },
                        ],
                    },
                },
            ],
            transformOldSettings(settings),
        );
    }
}

function transformOldSettings(settings) {
    if (!settings || settings['datasets']) {
        return settings;
    }
    const datasets = [];
    for (let i = 1; i <= 6; i++) {
        const title = settings[`mg_title${i}`];
        const data = settings[`mg_data${i}`];
        const unit = settings[`mg_unit${i}`];
        const background = settings[`mg_bg${i}`];

        if (title && data) {
            datasets.push({
                title,
                data,
                unit,
                background,
            });
        }
    }
    return { datasets };
}
