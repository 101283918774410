import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ButtonModule } from '../buttons/buttons.module';
import { CanMessageInputComponent } from './can-message-input/can-message-input.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { TextBoxComponent } from './text-box/text-box.component';

const components = [
    CanMessageInputComponent,
    InputComponent,
    SelectComponent,
    TextBoxComponent,
];

@NgModule({
    imports: [AppMaterialModule, ButtonModule],
    exports: components,
    declarations: components,
})
export class FormModule {}
