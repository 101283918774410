<div class="h-content-no-tool-bar flex flex-row bg-base-200" #container>
    <div class="hidden min-w-[16rem] max-w-[16rem] flex-col gap-2 p-3 md:flex">
        <div class="flex flex-col gap-2">
            @for (filter of listPageService.filters; track filter.id) {
                <app-list-filter [filter]="filter" />
            }
        </div>
    </div>
    <div class="flex h-full grow flex-col md:max-lg:pe-3 md:max-lg:pt-3">
        <div
            class="flex flex-col gap-2 max-md:p-2 lg:hidden w-full max-w-[400px] ms-auto"
        >
            <ng-container *ngTemplateOutlet="primaryColumn"></ng-container>
        </div>
        <div
            class="relative grow bg-base-100 md:my-3 md:rounded-xl overflow-hidden"
        >
            @switch (listPageService.status$ | async) {
                @case ('preloading') {
                    <sz-list-loading />
                }
                @case ('loading') {
                    <sz-list-loading />
                }
                @case ('empty') {
                    <div
                        class="absolute w-full h-full bg-base-100 top-0 flex justify-center items-center"
                    >
                        <sz-illustration
                            name="no-data"
                            heading="list.empty"
                            description="list.empty.description"
                        />
                    </div>
                }
                @case ('loaded') {
                    <!-- TODO: move to a separate component -->
                    <cdk-virtual-scroll-viewport
                        itemSize="72"
                        minBufferPx="1000"
                        maxBufferPx="2000"
                        class="w-full h-full"
                    >
                        <div
                            *cdkVirtualFor="let row of listPageService.rows$"
                            class="flex flex-row gap-2 w-full mb-2"
                        >
                            @for (id of row; track id) {
                                @if (id > 0) {
                                    <sz-list-item
                                        class="flex-1 min-w-0"
                                        [id]="id"
                                        [preview]="
                                            listPageService.getPreviewSignal(
                                                id
                                            )()
                                        "
                                        [isSelected]="
                                            listPageService.selectedIds.includes(
                                                id
                                            )
                                        "
                                        (click)="
                                            listPageService.select(id, $event)
                                        "
                                        (dblclick)="listPageService.goTo(id)"
                                    />
                                } @else {
                                    <!-- Negative number represents a spacer -->
                                    <div class="flex-1 min-w-0"></div>
                                }
                            }
                        </div>
                    </cdk-virtual-scroll-viewport>
                }
            }
        </div>
    </div>
    <div class="hidden min-w-[16rem] max-w-[16rem] flex-col gap-2 p-3 lg:flex">
        <ng-container *ngTemplateOutlet="primaryColumn"></ng-container>
    </div>
</div>
<ng-template #primaryColumn>
    @if (listPageService.showNewButton) {
        <div class="flex justify-end">
            <sz-button
                size="large"
                text="common.new"
                bgColor="primary"
                icon="plus"
                (click)="add.emit()"
            />
        </div>
    }
    @if (listPageService.selectedIds.length == 0) {
        <sz-stats-overview
            [count]="listPageService.currentCount?.count"
            [details]="listPageService.overviewStats"
            [buttons]="listPageService.overlayButtons"
        />
    } @else if (listPageService.selectedIds.length == 1) {
        <sz-item-preview
            [preview]="
                listPageService.getPreviewSignal(
                    listPageService.selectedIds[0]
                )()
            "
            [selectedIds]="listPageService.selectedIds"
            [showDeleteButton]="listPageService.showDeleteButton"
            [buttons]="listPageService.overlayButtons"
            (clearSelection)="listPageService.clearSelection()"
            (delete)="listPageService.deleteSelected()"
        />
    } @else {
        <sz-selected-overview
            [count]="listPageService.selectedIds.length"
            [buttons]="listPageService.overlayButtons"
            [showDeleteButton]="listPageService.showDeleteButton"
            (clearSelection)="listPageService.clearSelection()"
            (delete)="listPageService.deleteSelected()"
        />
    }
</ng-template>
