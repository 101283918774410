import { CreateImage, Image } from 'src/app/shared/models/image';
import { ApiService } from './api.service';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import { TopLevelQueryParams } from './top-level-route-api';

export interface ImagesQueryParams extends TopLevelQueryParams {
    organizationId?: number;
    name?: string;
}

export class ImagesApi extends TopLevelFileRouteApi<
    Image,
    CreateImage,
    ImagesQueryParams,
    unknown
> {
    get path() {
        return 'images';
    }

    get fileSubPath() {
        return 'image';
    }

    constructor(api: ApiService) {
        super(api);
    }
}
