import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { ChartType, getTypeIcon } from 'src/app/chart/models/chart-type';
import {
    importDateString,
    isDefined,
    uniqueArray,
} from 'src/app/shared/common';
import { ReportPreview } from 'src/app/shared/models/preview';
import {
    CopyReport,
    CreateReport,
    Report,
    ReportsQueryParams,
} from 'src/app/shared/models/report';
import { DateTimeService } from '../date-time.service';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { CreateResult, TopLevelRouteApi } from './top-level-route-api';
import { StatisticType } from 'src/app/chart/models/signal';
import { SignalResourceType } from 'src/app/chart/models/resource-type';
import { TimeFormat } from 'src/app/chart/models/signals/time';

class RawReportsApi extends TopLevelRouteApi<
    Report,
    CreateReport,
    ReportsQueryParams
> {
    get path() {
        return 'reports';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Report {
        const content = importContent(data.charts);
        // TODO: remove this logic when we rename the field in the backend
        delete data.charts;
        return {
            ...data,
            content,
            startDate: importDateString(data.startDate),
            endDate: importDateString(data.endDate),
            modifyDate: importDateString(data.modifyDate),
            lastViewed: importDateString(data.lastViewed) ?? new Date(0),
        };
    }

    transformFrontendData(report: Report) {
        // TODO: remove this logic when we rename the field in the backend
        const charts = report.content;
        delete report.content;
        return { ...report, charts };
    }

    async copy(data: CopyReport): Promise<CreateResult> {
        return (await this.api.client
            .post(this.basePath, data)
            .toPromise()) as CreateResult;
    }
}

export class ReportsApi extends DynamicApi<
    Report,
    CreateReport,
    ReportPreview,
    ReportsQueryParams
> {
    private raw: RawReportsApi;
    rawAccess: RawAccessAdapter<Report, CreateReport, ReportsQueryParams>;
    dateString: (date: Date) => string;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super('reports');
        this.raw = new RawReportsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'lastViewed',
            sortOrder: 'DESC',
        });
        const dateTime = inject(DateTimeService);
        this.dateString = (date: Date) => dateTime.dateString(date);
        this.translate = inject(TranslateService);
    }

    attachTriggers(): void {
        this.api.pageViews.updated$
            .pipe(
                filter(
                    ({ feature, itemId }) =>
                        feature === 'reports' && isDefined(itemId),
                ),
            )
            .subscribe(({ itemId }) => this.reloadItem(itemId));
    }

    async loadPreview(id: number): Promise<ReportPreview> {
        const report = await this.getAsync(id);
        const organization = await this.api.organizations.getAsync(
            report.organizationId,
        );
        const startDateString = this.dateString(report.startDate);
        const endDateString = report.endDate
            ? this.dateString(report.endDate)
            : this.translate.instant('common.today');
        const dateRangeString = `${startDateString} - ${endDateString}`;
        const typeIcons = uniqueArray(
            report.content.charts.map((c) => c.type),
        ).map(getTypeIcon);
        return {
            type: 'report',
            id,
            title: report.name,
            subtitle: dateRangeString,
            details: [
                {
                    name: 'report.chart.type.plural',
                    value: '',
                    icon: typeIcons,
                },
                {
                    name: 'organization',
                    value: organization?.name ?? '',
                },
            ],
            deviceIds: report?.deviceIds,
            item: report,
        };
    }

    async copy(data: CopyReport): Promise<CreateResult> {
        const copy = await this.raw.copy(data);
        if (copy.success) {
            await this.reloadItem(copy.id);
            await this.loadChunkMap();
        }
        return copy;
    }
}

const importContent = (content) => {
    const version = content['version'];
    if (version === undefined) {
        const charts = content;
        charts.forEach((chart) => {
            // Convert old number-style types to the new string types.
            if ([0, 1, 2].includes(chart.type as unknown as number)) {
                chart.type = [
                    ChartType.LineGraph,
                    ChartType.Table,
                    ChartType.Map,
                ][chart.type];
            }
            // Insert timestamp signal for each chart.
            chart.signals.unshift({
                uuid: 'automatic-timestamp',
                type: StatisticType.Series,
                name: 'Timestamp',
                hidden: false,
                resource: {
                    type: SignalResourceType.Time,
                    format: TimeFormat.Timestamp,
                },
            });
            chart.settings = {};
            if (chart.type === ChartType.LineGraph) {
                // Move the thresholds field
                chart.settings.thresholds = chart.thresholds;
            }
        });
        return { charts };
    } else if (version == '2024-07-02') {
        return content;
    }
    throw new Error('Unsupported version: ' + version);
};
