import { SignalResourceType } from '../resource-type';

export type TimeSignalResource = {
    type: SignalResourceType.Time;
    format: TimeFormat;
    offset?: number;
};

export enum TimeFormat {
    Timestamp = 'timestamp',
    DateTime = 'dateTimeString',
    Date = 'dateString',
    Time = 'timeString',
}
