import { filter } from 'rxjs/operators';
import { importDateString, isDefined } from 'src/app/shared/common';
import {
    CopyDashboard,
    CreateDashboard,
    Dashboard,
    DashboardContext,
    exportDashboardContent,
    parseDashboardContent,
} from 'src/app/shared/models/dashboard';
import { DashboardPreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import {
    CreateResult,
    TopLevelQueryParams,
    TopLevelRouteApi,
} from './top-level-route-api';

export interface DashboardsQueryParams extends TopLevelQueryParams {
    customerId?: number;
    'deviceIds[contains]'?: number;
    modelId?: number;
}

class RawDashboardsApi extends TopLevelRouteApi<
    Dashboard,
    CreateDashboard,
    DashboardsQueryParams
> {
    get path() {
        return 'dashboards';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): Dashboard {
        return {
            ...data,
            deviceIds: data.deviceIds ?? [],
            content: parseDashboardContent(data.content),
            lastViewed: importDateString(data.lastViewed) ?? new Date(0),
        };
    }

    transformFrontendData(data: Partial<CreateDashboard>) {
        const output: unknown = {};
        if (data.name != undefined) {
            output['name'] = data.name;
        }
        if (data.customerId != undefined) {
            output['customerId'] = data.customerId;
        }
        if (data.content != undefined) {
            output['content'] = exportDashboardContent(data.content);
        }
        if (data.deviceIds != undefined) {
            output['deviceIds'] = data.deviceIds;
        }
        return output;
    }

    async copy(data: CopyDashboard) {
        return (await this.api.client
            .post(this.basePath, data)
            .toPromise()) as CreateResult;
    }

    async context(id: number): Promise<DashboardContext> {
        return await this.retrieve<DashboardContext>(id + '/context');
    }
}

export class DashboardsApi extends DynamicApi<
    Dashboard,
    CreateDashboard,
    DashboardPreview,
    DashboardsQueryParams
> {
    private raw: RawDashboardsApi;
    rawAccess: RawAccessAdapter<
        Dashboard,
        CreateDashboard,
        DashboardsQueryParams
    >;

    constructor(private api: ApiService) {
        super('dashboards');
        this.raw = new RawDashboardsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'lastViewed',
            sortOrder: 'DESC',
        });
    }

    attachTriggers(): void {
        this.api.pageViews.updated$
            .pipe(
                filter(
                    ({ feature, itemId }) =>
                        feature === 'dashboards' && isDefined(itemId),
                ),
            )
            .subscribe(({ itemId }) => this.reloadItem(itemId));
    }

    async loadPreview(id: number): Promise<DashboardPreview> {
        const dashboard = await this.getAsync(id);
        return {
            type: 'dashboard',
            id,
            title: dashboard.name,
            subtitle: dashboard?.customerName ?? '',
            deviceIds: dashboard?.deviceIds,
            item: dashboard,
        };
    }

    async copy(data: CopyDashboard) {
        const copy = await this.raw.copy(data);
        if (copy.success) {
            await this.reloadItem(copy.id);
            await this.loadChunkMap();
        }
        return copy;
    }

    context(id: number) {
        return this.raw.context(id);
    }
}
