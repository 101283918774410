import {
    Component,
    DestroyRef,
    Input,
    OnInit,
    Pipe,
    PipeTransform,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { ListPageService } from 'src/app/services/list-page/list-page.service';
import { FilterOption } from '../../layout/multi-select/multi-select.component';
import { ListFilterConfig } from '../common';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
    selector: 'app-list-filter',
    templateUrl: './list-filter.component.html',
})
export class ListFilterComponent<GetType> implements OnInit {
    listPageService = inject(ListPageService<GetType>);
    activatedRoute = inject(ActivatedRoute);
    api = inject(ApiService);
    destroyRef = inject(DestroyRef);

    @Input() filter: ListFilterConfig;
    isLoadingOptions = true;
    selectedOptions: FilterOption[] = [];
    canLoadMoreOptions = false;
    allOptions$ = new BehaviorSubject<FilterOption[]>([]);

    get hasPickList() {
        return 'pickList' in this.filter;
    }

    get isExclusive() {
        return 'exclusive' in this.filter && this.filter.exclusive;
    }

    get selectedValues() {
        return this.selectedOptions.map(({ value }) => value);
    }

    ngOnInit() {
        this.canLoadMoreOptions = !('pickList' in this.filter);
        this.activatedRoute.queryParams
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(async () => {
                this.isLoadingOptions = true;
                this.selectedOptions =
                    await this.listPageService.getSelectedOptions(this.filter);
                this.isLoadingOptions = false;
                if ('pickList' in this.filter) {
                    this.allOptions$.next(this.filter.pickList);
                }
            });
    }

    /**
     * Return the updated query parameters if the given option was selected
     */
    getParamsAfterAddOption(option: FilterOption) {
        const params = new URLSearchParams(location.search);
        const result = {};
        for (const [key, value] of params) {
            if (key != this.filter.id) {
                result[key] = value;
            }
        }
        if (this.isExclusive) {
            // set the filter to the option exclusively
            result[this.filter.id] = option.value;
        } else {
            // add the option to the end of the list
            result[this.filter.id] = [...this.selectedOptions, option]
                .map(({ value }) => value)
                .join(',');
        }
        return result;
    }

    /**
     * Return the updated query parameters if the given option was deselected
     */
    getParamsAfterRemoveOption(option: FilterOption) {
        const params = new URLSearchParams(location.search);
        const result = {};
        for (const [key, value] of params) {
            if (key == this.filter.id) {
                const afterRemove = this.selectedOptions.filter(
                    ({ value }) => value != option.value,
                );
                if (afterRemove.length > 0) {
                    result[key] = afterRemove
                        .map(({ value }) => value)
                        .join(',');
                }
            } else {
                result[key] = value;
            }
        }
        return result;
    }

    async loadMoreOptions() {
        this.canLoadMoreOptions = false;
        if ('dynamic' in this.filter) {
            const allOptions =
                await this.api[`${this.filter.id}s`].asFilterOptions();
            allOptions.sort((a, b) => a.name.localeCompare(b.name));
            this.allOptions$.next(allOptions);
        }
    }
}

@Pipe({ name: 'unselected' })
export class UnselectedPipe implements PipeTransform {
    transform = (
        options: FilterOption[],
        selectedValues: (string | number)[],
    ) => options.filter(({ value }) => !selectedValues.includes(value));
}
