let colorObject = {
  "transparent": "transparent",
  "current": "currentColor",
  "primary": "hsl(var(--p) / <alpha-value>)",
  "primary-focus": "hsl(var(--pf) / <alpha-value>)",
  "primary-content": "hsl(var(--pc) / <alpha-value>)",
  "secondary": "hsl(var(--s) / <alpha-value>)",
  "secondary-focus": "hsl(var(--sf) / <alpha-value>)",
  "secondary-content": "hsl(var(--sc) / <alpha-value>)",
  "accent": "hsl(var(--a) / <alpha-value>)",
  "accent-focus": "hsl(var(--af) / <alpha-value>)",
  "accent-content": "hsl(var(--ac) / <alpha-value>)",
  "neutral": "hsl(var(--n) / <alpha-value>)",
  "neutral-focus": "hsl(var(--nf) / <alpha-value>)",
  "neutral-content": "hsl(var(--nc) / <alpha-value>)",
  "base-100": "hsl(var(--b1) / <alpha-value>)",
  "base-200": "hsl(var(--b2) / <alpha-value>)",
  "base-300": "hsl(var(--b3) / <alpha-value>)",
  "base-content": "hsl(var(--bc) / <alpha-value>)",
  "info": "hsl(var(--in) / <alpha-value>)",
  "info-content": "hsl(var(--inc) / <alpha-value>)",
  "success": "hsl(var(--su) / <alpha-value>)",
  "success-content": "hsl(var(--suc) / <alpha-value>)",
  "warning": "hsl(var(--wa) / <alpha-value>)",
  "warning-content": "hsl(var(--wac) / <alpha-value>)",
  "error": "hsl(var(--er) / <alpha-value>)",
  "error-content": "hsl(var(--erc) / <alpha-value>)"
};
module.exports = colorObject;