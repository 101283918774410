export const palette = {
    Default: '#3f51b5',
    Red: '#f44336',
    Pink: '#e91e63',
    Purple: '#9c27b0',
    'Deep-Purple': '#673ab7',
    Blue: '#2196f3',
    'Light-Blue': '#03a9f4',
    Cyan: '#00BCD4',
    Teal: '#009688',
    Green: '#4CAF50',
    'Light-Green': '#8BC34A',
    Lime: '#CDDC39',
    Yellow: '#FFEB3B',
    Amber: '#FFC107',
    Orange: '#FF9800',
    'Deep-Orange': '#FF5722',
    Brown: '#795548',
    Grey: '#9E9E9E',
    'Blue-Grey': '#607D8B'
};
