<div class="mx-0 my-1 flex w-[270px] flex-col overflow-clip rounded-xl">
    <div id="image-container">
        <div
            *ngIf="!isLoading && imageUrl !== null; else loader"
            class="flex h-full w-full items-center justify-center"
            [style.backgroundColor]="backgroundColorControl.value"
        >
            <div
                *ngIf="isFavicon; else secureImage"
                [style.background-image]="'url(' + imageUrl + ')'"
                class="inline-block h-[calc(100%-32px)] w-[calc(100%-32px)] bg-contain bg-center bg-no-repeat"
            ></div>
            <ng-template #secureImage>
                <div
                    [style.background-image]="
                        'url(' + (imageUrl | secure | async) + ')'
                    "
                    class="inline-block h-[calc(100%-32px)] w-[calc(100%-32px)] bg-contain bg-center bg-no-repeat"
                ></div>
            </ng-template>
        </div>
        <ng-template #loader>
            <div class="spinner-wrap">
                <sz-spinner size="huge" />
            </div>
        </ng-template>
    </div>
    <div
        *ngIf="!disabled"
        class="button-bar flex flex-row items-center justify-end bg-gray-200 dark:bg-gray-700"
    >
        <sz-icon-button
            icon="upload"
            size="medium"
            (click)="upload(fileInput)"
        />
        <sz-icon-button
            icon="close-circle-outline"
            size="medium"
            (click)="reset()"
        />
        <ngx-colors
            ngx-colors-trigger
            class="mx-1 mb-[1px] mt-0"
            format="hex"
            overlayClassName="mat-typography"
            [palette]="backgroundPalette"
            [formControl]="backgroundColorControl"
        ></ngx-colors>
    </div>
</div>
<input
    #fileInput
    name="files"
    hidden
    [accept]="isFavicon ? 'image/x-icon' : 'image/*'"
    type="file"
    (change)="onImageChange($event)"
/>
