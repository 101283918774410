export class WorkerController {
    protected workers: Record<string, Worker> = {};

    terminate() {
        for (const worker of Object.values(this.workers)) {
            worker.terminate();
        }
        this.workers = {};
    }
}
