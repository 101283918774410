import { isDefined } from 'src/app/shared/common';
import { v4 as uuidv4 } from 'uuid';
import { Widget, WidgetType } from './widget';
import { BarGraphWidget } from './widgets/bar-graph';
import { ButtonWidget } from './widgets/button';
import { CustomWidget } from './widgets/custom';
import { DonutWidget } from './widgets/donut-chart';
import { GaugeWidget } from './widgets/gauge';
import { HexWidget } from './widgets/hex';
import { IndicatorWidget } from './widgets/indicator';
import { InteractiveIndicatorWidget } from './widgets/interactive-indicator';
import { LineGraphWidget } from './widgets/line-graph';
import { LinkWidget } from './widgets/link';
import { MapWidget } from './widgets/map';
import { MonitorGridWidget } from './widgets/monitor-grid';
import { NumberSenderWidget } from './widgets/number-sender';
import { PictureWidget } from './widgets/picture';
import { PieWidget } from './widgets/pie-chart';
import { RebootCommandWidget } from './widgets/reboot-command';
import { SingleMonitorWidget } from './widgets/single-monitor';
import { SwitchWidget } from './widgets/switch';
import { TextWidget } from './widgets/text';
import { TextSenderWidget } from './widgets/text-sender';
import { TwoActionWidget } from './widgets/two-action';

export class WidgetFactory {
    /* Create or rebuild a widget. */
    static createWidget(args: {
        type;
        uuid?;
        settings?;
        customWidgetId?;
        height?;
    }): Widget {
        const { type, settings, customWidgetId, height } = args;
        let { uuid } = args;
        if (!uuid) {
            uuid = uuidv4();
        }
        const instance = this._createInstance(type, settings, customWidgetId);
        // If there is a saved height, load it.
        if (height) {
            instance.height = height;
        }
        instance.uuid = uuid;
        return instance;
    }

    static _createInstance(type, settings, customWidgetId): Widget {
        switch (type) {
            case WidgetType.BarGraph:
                return new BarGraphWidget(settings);
            case WidgetType.GaugeWidget:
                return new GaugeWidget(settings);
            case WidgetType.ButtonWidget:
                return new ButtonWidget(settings);
            case WidgetType.SwitchWidget:
                return new SwitchWidget(settings);
            case WidgetType.NumberSenderWidget:
                return new NumberSenderWidget(settings);
            case WidgetType.Indicator:
                return new IndicatorWidget(settings);
            case WidgetType.LineGraph:
                return new LineGraphWidget(settings);
            case WidgetType.Text:
                return new TextWidget(settings);
            case WidgetType.PictureWidget:
                return new PictureWidget(settings);
            case WidgetType.MapWidget:
                return new MapWidget(settings);
            case WidgetType.InteractiveIndicatorWidget:
                return new InteractiveIndicatorWidget(settings);
            case WidgetType.TextSenderWidget:
                return new TextSenderWidget(settings);
            case WidgetType.MonitorGridWidget:
                return new MonitorGridWidget(settings);
            case WidgetType.SingleMonitorWidget:
                return new SingleMonitorWidget(settings);
            case WidgetType.TwoActionWidget:
                return new TwoActionWidget(settings);
            case WidgetType.DonutWidget:
                return new DonutWidget(settings);
            case WidgetType.PieWidget:
                return new PieWidget(settings);
            case WidgetType.HexWidget:
                return new HexWidget(settings);
            case WidgetType.LinkWidget:
                return new LinkWidget(settings);
            case WidgetType.RebootCommandWidget:
                return new RebootCommandWidget(settings);
            default:
                if (!isDefined(customWidgetId)) {
                    throw Error(`Invalid widget type: ${type}`);
                }
                return new CustomWidget(settings, customWidgetId);
        }
    }
}
