import { WorkerController } from 'src/app/common/workers/controller';
import { workerPromise } from 'src/app/common/workers/utils';
import { CountResult } from '../top-level-route-api';
import { getAccessToken } from 'src/app/shared/auth-utils';

export type QueryParams = Record<string, string | number | boolean>;

export class ApiWorkerController extends WorkerController {
    constructor(private baseUrl: string) {
        super();
        this.workers.count = new Worker(
            new URL('./count.worker', import.meta.url),
        );
        this.workers.query = new Worker(
            new URL('./query.worker', import.meta.url),
        );
    }

    async count(params?: QueryParams): Promise<CountResult> {
        return workerPromise(this.workers['count'], {
            accessToken: getAccessToken(),
            baseUrl: this.baseUrl,
            params: params ?? {},
        });
    }

    async query(params?: QueryParams): Promise<unknown[]> {
        return workerPromise(this.workers['query'], {
            accessToken: getAccessToken(),
            baseUrl: this.baseUrl,
            params: params ?? {},
        });
    }
}
