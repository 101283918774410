module.exports = {
  ".artboard-demo": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))",
    "-TwTextOpacity": "1",
    "color": "hsl(var(--bc) / var(--tw-text-opacity))",
    "borderRadius": "var(--rounded-box, 1rem)",
    "boxShadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1),\n      0 1px 2px 0 rgba(0, 0, 0, 0.06)"
  },
  ".avatar.online:before": {
    "content": "\"\"",
    "position": "absolute",
    "zIndex": "10",
    "display": "block",
    "borderRadius": "9999px",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--su) / var(--tw-bg-opacity))",
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineColor": "hsl(var(--b1) / 1)",
    "width": "15%",
    "height": "15%",
    "top": "7%",
    "right": "7%"
  },
  ".avatar.offline:before": {
    "content": "\"\"",
    "position": "absolute",
    "zIndex": "10",
    "display": "block",
    "borderRadius": "9999px",
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b3) / var(--tw-bg-opacity))",
    "outlineStyle": "solid",
    "outlineWidth": "2px",
    "outlineColor": "hsl(var(--b1) / 1)",
    "width": "15%",
    "height": "15%",
    "top": "7%",
    "right": "7%"
  },
  ".btn-group .btn:not(:first-child):not(:last-child)": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "0"
  },
  ".btn-group .btn:first-child:not(:last-child)": {
    "marginLeft": "-1px",
    "marginTop": "-0px",
    "borderTopLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomRightRadius": "0"
  },
  ".btn-group .btn:last-child:not(:first-child)": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".btn-group-horizontal .btn:not(:first-child):not(:last-child)": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "0"
  },
  ".btn-group-horizontal .btn:first-child:not(:last-child)": {
    "marginLeft": "-1px",
    "marginTop": "-0px",
    "borderTopLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomRightRadius": "0"
  },
  ".btn-group-horizontal .btn:last-child:not(:first-child)": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".btn-group-vertical .btn:first-child:not(:last-child)": {
    "marginLeft": "-0px",
    "marginTop": "-1px",
    "borderTopLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderTopRightRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "0"
  },
  ".btn-group-vertical .btn:last-child:not(:first-child)": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "var(--rounded-btn, 0.5rem)",
    "borderBottomRightRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".card-compact .card-body": {
    "padding": "1rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".card-compact .card-title": {
    "marginBottom": "0.25rem"
  },
  ".card-normal .card-body": {
    "padding": "var(--padding-card, 2rem)",
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  ".card-normal .card-title": {
    "marginBottom": "0.75rem"
  },
  ".divider-horizontal": {
    "marginLeft": "1rem",
    "marginRight": "1rem",
    "marginTop": "0px",
    "marginBottom": "0px",
    "height": "auto",
    "width": "1rem"
  },
  ".divider-vertical": {
    "marginLeft": "0px",
    "marginRight": "0px",
    "marginTop": "1rem",
    "marginBottom": "1rem",
    "height": "1rem",
    "width": "auto"
  },
  ".drawer-open > .drawer-toggle ~ .drawer-side > .drawer-overlay": {
    "cursor": "default",
    "backgroundColor": "transparent"
  },
  ".join.join-vertical > :where(*:not(:first-child))": {
    "marginLeft": "0px",
    "marginRight": "0px",
    "marginTop": "-1px"
  },
  ".join.join-horizontal > :where(*:not(:first-child))": {
    "marginTop": "0px",
    "marginBottom": "0px",
    "marginLeft": "-1px"
  },
  ".menu-horizontal > li:not(.menu-title) > details > ul": {
    "marginLeft": "0px",
    "marginTop": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "paddingRight": "0.5rem"
  },
  ".menu-horizontal > li > details > ul:before": {
    "content": "none"
  },
  ":where(.menu-horizontal > li:not(.menu-title) > details > ul)": {
    "-TwBgOpacity": "1",
    "backgroundColor": "hsl(var(--b1) / var(--tw-bg-opacity))",
    "-TwShadow": "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
    "-TwShadowColored": "0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
    "borderRadius": "var(--rounded-box, 1rem)"
  },
  ".menu-vertical > li:not(.menu-title) > details > ul": {
    "marginLeft": "1rem",
    "marginTop": "0px",
    "paddingTop": "0px",
    "paddingBottom": "0px",
    "paddingRight": "0px"
  },
  ".menu-xs :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n  .menu-xs :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "borderRadius": "0.25rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".menu-xs .menu-title": {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem"
  },
  ".menu-sm :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n  .menu-sm :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".menu-sm .menu-title": {
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  },
  ".menu-md :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n  .menu-md :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".menu-md .menu-title": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  },
  ".menu-lg :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),\n  .menu-lg :where(li:not(.menu-title) > details > summary:not(.menu-title))": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem",
    "borderRadius": "var(--rounded-btn, 0.5rem)"
  },
  ".menu-lg .menu-title": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem"
  },
  ".modal-top :where(.modal-box)": {
    "width": "100%",
    "maxWidth": "none",
    "-TwTranslateY": "-2.5rem",
    "-TwScaleX": "1",
    "-TwScaleY": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderTopLeftRadius": "0px",
    "borderTopRightRadius": "0px",
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)",
    "borderBottomRightRadius": "var(--rounded-box, 1rem)"
  },
  ".modal-middle :where(.modal-box)": {
    "width": "91.666667%",
    "maxWidth": "32rem",
    "-TwTranslateY": "0px",
    "-TwScaleX": ".9",
    "-TwScaleY": ".9",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderTopLeftRadius": "var(--rounded-box, 1rem)",
    "borderTopRightRadius": "var(--rounded-box, 1rem)",
    "borderBottomLeftRadius": "var(--rounded-box, 1rem)",
    "borderBottomRightRadius": "var(--rounded-box, 1rem)"
  },
  ".modal-bottom :where(.modal-box)": {
    "width": "100%",
    "maxWidth": "none",
    "-TwTranslateY": "2.5rem",
    "-TwScaleX": "1",
    "-TwScaleY": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "borderBottomRightRadius": "0px",
    "borderBottomLeftRadius": "0px",
    "borderTopLeftRadius": "var(--rounded-box, 1rem)",
    "borderTopRightRadius": "var(--rounded-box, 1rem)"
  },
  ".stats-horizontal > :not([hidden]) ~ :not([hidden])": {
    "-TwDivideXReverse": "0",
    "borderRightWidth": "calc(1px * var(--tw-divide-x-reverse))",
    "borderLeftWidth": "calc(1px * calc(1 - var(--tw-divide-x-reverse)))",
    "-TwDivideYReverse": "0",
    "borderTopWidth": "calc(0px * calc(1 - var(--tw-divide-y-reverse)))",
    "borderBottomWidth": "calc(0px * var(--tw-divide-y-reverse))"
  },
  ".stats-horizontal": {
    "overflowX": "auto"
  },
  ".stats-vertical > :not([hidden]) ~ :not([hidden])": {
    "-TwDivideXReverse": "0",
    "borderRightWidth": "calc(0px * var(--tw-divide-x-reverse))",
    "borderLeftWidth": "calc(0px * calc(1 - var(--tw-divide-x-reverse)))",
    "-TwDivideYReverse": "0",
    "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
    "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))"
  },
  ".stats-vertical": {
    "overflowY": "auto"
  },
  ".steps-horizontal .step": {
    "gridTemplateRows": "40px 1fr",
    "gridTemplateColumns": "auto",
    "minWidth": "4rem"
  },
  ".steps-horizontal .step:before": {
    "height": "0.5rem",
    "width": "100%",
    "-TwTranslateX": "0px",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "content": "\"\"",
    "marginLeft": "-100%"
  },
  ".steps-vertical .step": {
    "gap": "0.5rem",
    "gridTemplateColumns": "40px 1fr",
    "gridTemplateRows": "auto",
    "minHeight": "4rem",
    "justifyItems": "start"
  },
  ".steps-vertical .step:before": {
    "height": "100%",
    "width": "0.5rem",
    "-TwTranslateX": "-50%",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "marginLeft": "50%"
  },
  "[dir=\"rtl\"] .steps-vertical .step:before": {
    "marginRight": "auto"
  },
  ".table-xs :not(thead):not(tfoot) tr": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".table-xs :where(th, td)": {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem"
  },
  ".table-sm :not(thead):not(tfoot) tr": {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".table-sm :where(th, td)": {
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.5rem",
    "paddingBottom": "0.5rem"
  },
  ".table-md :not(thead):not(tfoot) tr": {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".table-md :where(th, td)": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem"
  },
  ".table-lg :not(thead):not(tfoot) tr": {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  ".table-lg :where(th, td)": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem"
  },
  ".tooltip": {
    "position": "relative",
    "display": "inline-block",
    "textAlign": "center",
    "-TooltipTail": "0.1875rem",
    "-TooltipColor": "hsl(var(--n))",
    "-TooltipTextColor": "hsl(var(--nc))",
    "-TooltipTailOffset": "calc(100% + 0.0625rem - var(--tooltip-tail))"
  },
  ".tooltip:before,\n.tooltip:after": {
    "opacity": "0",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionDelay": "100ms",
    "transitionDuration": "200ms",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)"
  },
  ".tooltip:after": {
    "position": ["absolute", "absolute"],
    "content": "\"\"",
    "borderStyle": "solid",
    "borderWidth": "var(--tooltip-tail, 0)",
    "width": "0",
    "height": "0",
    "display": "block"
  },
  ".tooltip:before": {
    "maxWidth": "20rem",
    "borderRadius": "0.25rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "backgroundColor": "var(--tooltip-color)",
    "color": "var(--tooltip-text-color)",
    "width": "max-content"
  },
  ".tooltip.tooltip-open:before,\n.tooltip.tooltip-open:after,\n.tooltip:hover:before,\n.tooltip:hover:after": {
    "opacity": "1",
    "transitionDelay": "75ms"
  },
  ".tooltip:has(:focus-visible):after,\n.tooltip:has(:focus-visible):before": {
    "opacity": "1",
    "transitionDelay": "75ms"
  },
  ".tooltip:not([data-tip]):hover:before,\n.tooltip:not([data-tip]):hover:after": {
    "visibility": "hidden",
    "opacity": "0"
  },
  ".tooltip:after, .tooltip-top:after": {
    "transform": "translateX(-50%)",
    "borderColor": "var(--tooltip-color) transparent transparent transparent",
    "top": "auto",
    "left": "50%",
    "right": "auto",
    "bottom": "var(--tooltip-tail-offset)"
  },
  ".tooltip-bottom:after": {
    "transform": "translateX(-50%)",
    "borderColor": "transparent transparent var(--tooltip-color) transparent",
    "top": "var(--tooltip-tail-offset)",
    "left": "50%",
    "right": "auto",
    "bottom": "auto"
  },
  ".tooltip-left:after": {
    "transform": "translateY(-50%)",
    "borderColor": "transparent transparent transparent var(--tooltip-color)",
    "top": "50%",
    "left": "auto",
    "right": "calc(var(--tooltip-tail-offset) + 0.0625rem)",
    "bottom": "auto"
  },
  ".tooltip-right:after": {
    "transform": "translateY(-50%)",
    "borderColor": "transparent var(--tooltip-color) transparent transparent",
    "top": "50%",
    "left": "calc(var(--tooltip-tail-offset) + 0.0625rem)",
    "right": "auto",
    "bottom": "auto"
  },
  ".tooltip-primary": {
    "-TooltipColor": "hsl(var(--p))",
    "-TooltipTextColor": "hsl(var(--pc))"
  },
  ".tooltip-secondary": {
    "-TooltipColor": "hsl(var(--s))",
    "-TooltipTextColor": "hsl(var(--sc))"
  },
  ".tooltip-accent": {
    "-TooltipColor": "hsl(var(--a))",
    "-TooltipTextColor": "hsl(var(--ac))"
  },
  ".tooltip-info": {
    "-TooltipColor": "hsl(var(--in))",
    "-TooltipTextColor": "hsl(var(--inc))"
  },
  ".tooltip-success": {
    "-TooltipColor": "hsl(var(--su))",
    "-TooltipTextColor": "hsl(var(--suc))"
  },
  ".tooltip-warning": {
    "-TooltipColor": "hsl(var(--wa))",
    "-TooltipTextColor": "hsl(var(--wac))"
  },
  ".tooltip-error": {
    "-TooltipColor": "hsl(var(--er))",
    "-TooltipTextColor": "hsl(var(--erc))"
  }
};