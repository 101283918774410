import { inject } from '@angular/core';
import byteSize from 'byte-size';
import { ChartCanMessage } from 'src/app/chart/models/signals/log';
import { importDateString } from 'src/app/shared/common';
import {
    DataFile,
    DataFileStats,
    DataFileStatsParams,
    DataFilesDownloadParams,
    DataFilesQueryParams,
    GpsDataPoint,
} from 'src/app/shared/models/data-file';
import { DataFilePreview } from 'src/app/shared/models/preview';
import { TimeSpan } from 'src/app/shared/time-utils';
import { DateTimeService } from '../date-time.service';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelFileRouteApi } from './top-level-file-route-api';

export class RawDataFilesApi extends TopLevelFileRouteApi<
    DataFile,
    DataFile,
    DataFilesQueryParams,
    DataFilesDownloadParams
> {
    get path() {
        return 'data-files';
    }

    get fileSubPath() {
        return 'file';
    }

    constructor(api: ApiService) {
        super(api);
    }

    get(id: number) {
        return this.retrieve<DataFile>(`${id}`);
    }

    transformBackendData(data): DataFile {
        return {
            ...data,
            receivedOnDate: importDateString(data.receivedOnDate),
            startLog: importDateString(data.startLog),
            endLog: importDateString(data.endLog),
        };
    }

    getMessages(id: number) {
        return this.retrieve<ChartCanMessage[]>(`${id}/messages`);
    }

    async getGpsData(id: number): Promise<GpsDataPoint[]> {
        type RawGpsDataPoint = {
            timestamp: string;
            lat: number;
            lon: number;
        };
        return (await this.retrieve<RawGpsDataPoint[]>(`${id}/gps-data`)).map(
            (data) => ({
                timestamp: importDateString(data.timestamp),
                lat: data.lat,
                lon: data.lon,
            }),
        );
    }

    getStats(params: DataFileStatsParams) {
        return this.retrieve<DataFileStats[]>(`stats`, params);
    }

    async generateBulkData(id: number, timeSpan: TimeSpan) {
        return await this.send(`${id}/generate-bulk-data`, timeSpan);
    }
}

export class DataFilesApi extends DynamicApi<
    DataFile,
    DataFile,
    DataFilePreview,
    DataFilesQueryParams
> {
    private fileTypeIcon = new Map([
        ['log', 'chart-line-variant'],
        ['debug', 'bug'],
        ['gps', 'earth'],
        ['event', 'alert-decagram'],
        ['dmesg', 'receipt-outline'],
        ['journal', 'script-text-outline'],
        ['txt', 'file-document'],
    ]);
    private raw: RawDataFilesApi;
    private dateTime: DateTimeService;
    rawAccess: RawAccessAdapter<DataFile, DataFile, DataFilesQueryParams>;

    constructor(private api: ApiService) {
        super('data-files');
        this.raw = new RawDataFilesApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'id',
            sortOrder: 'DESC',
        });
        this.dateTime = inject(DateTimeService);
    }

    async loadPreview(id: number): Promise<DataFilePreview> {
        const dataFile = await this.getAsync(id);
        const device = await this.api.devices.getAsync(dataFile.deviceId);
        let organization = null;
        if (dataFile.organizationId) {
            organization = await this.api.organizations.getAsync(
                dataFile.organizationId,
            );
        }
        const organizationName = organization?.name ?? '';
        const subtitle = this.dateTime.dateTimeString(dataFile.receivedOnDate);
        const details = [
            { name: 'organization', value: organizationName },
            { name: 'type', value: dataFile.type },
            {
                name: 'size',
                value: byteSize(dataFile.byteSize, { precision: 2 }),
            },
        ];
        if (dataFile.startLog) {
            details.push({
                name: 'data_files.start',
                value: this.dateTime.dateTimeString(dataFile.startLog),
            });
        }
        if (dataFile.endLog) {
            details.push({
                name: 'data_files.end',
                value: this.dateTime.dateTimeString(dataFile.endLog),
            });
        }
        return {
            type: 'data-file',
            id,
            title: device.identifier,
            subtitle,
            icon: this.fileTypeIcon.get(dataFile.type),
            iconTooltip: dataFile.type,
            details,
            item: dataFile,
        };
    }

    getMessages(id: number) {
        return this.raw.retrieve<ChartCanMessage[]>(`${id}/messages`);
    }

    async getGpsData(id: number): Promise<GpsDataPoint[]> {
        type RawGpsDataPoint = {
            timestamp: string;
            lat: number;
            lon: number;
        };
        return (
            await this.raw.retrieve<RawGpsDataPoint[]>(`${id}/gps-data`)
        ).map((data) => ({
            timestamp: importDateString(data.timestamp),
            lat: data.lat,
            lon: data.lon,
        }));
    }

    getStats(params: DataFileStatsParams) {
        return this.raw.retrieve<DataFileStats[]>(`stats`, params);
    }

    upload(id: number, data: Blob) {
        return this.raw.upload(id, data);
    }

    download(id: number, params?: DataFilesDownloadParams) {
        return this.raw.download(id, params);
    }

    downloadParquets(id: number) {
        return this.raw.downloadParquets(id);
    }

    query(params?: DataFilesQueryParams) {
        return this.raw.query(params);
    }

    async generateBulkData(id: number, timeSpan: TimeSpan) {
        return this.raw.generateBulkData(id, timeSpan);
    }
}
