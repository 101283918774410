<div
    class="h-full w-full flex items-center justify-center"
    style="container-type: size"
>
    <div
        class="flex flex-col items-center justify-center gap-4 rounded-2xl bg-gray-50 p-4 dark:bg-gray-800"
        style="
            width: 100cqmin;
            height: 100cqmin;
            max-width: 50vmin;
            max-height: 50vmin;
        "
    >
        <img
            class="flex-grow min-w-0 min-h-0 max-w-80 max-h-80"
            src="assets/img/illustrations/{{ name }}.svg"
            [alt]="heading | translate"
        />
        <div class="flex flex-col gap-4 items-center">
            <div class="flex flex-col items-center">
                <div class="text-center text-lg">{{ heading | translate }}</div>
                <div class="text-center text-sm sm:text-base mx-4">
                    {{ description | translate }}
                </div>
            </div>
            <ng-content></ng-content>
        </div>
    </div>
</div>
