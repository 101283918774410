module.exports = {
  "primary": "--p",
  "primary-focus": "--pf",
  "primary-content": "--pc",
  "secondary": "--s",
  "secondary-focus": "--sf",
  "secondary-content": "--sc",
  "accent": "--a",
  "accent-focus": "--af",
  "accent-content": "--ac",
  "neutral": "--n",
  "neutral-focus": "--nf",
  "neutral-content": "--nc",
  "base-100": "--b1",
  "base-200": "--b2",
  "base-300": "--b3",
  "base-content": "--bc",
  "info": "--in",
  "info-content": "--inc",
  "success": "--su",
  "success-content": "--suc",
  "warning": "--wa",
  "warning-content": "--wac",
  "error": "--er",
  "error-content": "--erc"
};