export const pinTypes = [
    'alert',
    'dashboard',
    'device',
    'geo-fence',
    'model',
    'organization',
    'report',
    'user',
    'widget',
] as const;
// I don't know why this works, but it does.
// https://stackoverflow.com/a/45486495
export type PinType = (typeof pinTypes)[number];

export type CreatePin = {
    type: PinType;
    itemId: number;
    order: number;
};

export type Pin = CreatePin & {
    id: number;
    userId: number;
    name: string;
};

/**
 * Create a Pin from a partial Pin, for unit tests.
 */
export function fakePin(partial: Partial<Pin>): Pin {
    return {
        id: 1,
        type: 'alert',
        itemId: 1,
        order: 0,
        userId: 1,
        name: '',
        ...partial,
    };
}
