import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class SingleMonitorWidget extends Widget {
    type = WidgetType.SingleMonitorWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'sm_Title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    defaultValue: '',
                    previewValue: 'Engine Speed',
                },
                {
                    id: 'sm_Data',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.value',
                    defaultValue: '',
                    previewValue: '1299',
                },
                {
                    id: 'sm_Unit',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.unit',
                    defaultValue: '',
                    previewValue: 'RPM',
                },
                {
                    id: 'applyRounding',
                    type: WidgetSettingType.Checkbox,
                    name: 'widget.settings.apply_rounding',
                    defaultValue: false,
                    previewValue: false,
                    toggles: 'decimalPlaces',
                },
                {
                    id: 'decimalPlaces',
                    type: WidgetSettingType.Number,
                    name: 'widget.settings.decimal',
                    defaultValue: 2,
                    previewValue: 2,
                },
            ],
            settings,
        );
    }
}
