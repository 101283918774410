import { isDefined } from 'src/app/shared/common';
import { ExpressionLoader } from 'src/app/shared/models/expression/loader';
import { v4 as uuidv4 } from 'uuid';
import { prepSignals } from '../services/chart-data.service';
import { generateUuid, SignalResourceType } from './resource-type';
import {
    ChartCanMessage,
    combineSignals,
    LogSignalResource,
    Stat,
} from './signals/log';
import { TimeFormat, TimeSignalResource } from './signals/time';
import { log } from 'src/app/shared/log';

export type ChartSignal = Omit<StatisticSignal, 'type'> & {
    name: string;
    hidden: boolean;
};

export type StatisticSignal = {
    uuid: string;
    type: StatisticType;
    resource: ChartSignalResource;
};

export enum StatisticType {
    Series = 'series',
    Aggregate = 'aggregate',
}

export type ChartSignalResource = TimeSignalResource | LogSignalResource;

export const loadSignals = (
    signalsData,
    availableDeviceIds: number[],
    canMessages: ChartCanMessage[],
): ChartSignal[] =>
    signalsData
        .map((signalData) => loadSignal(signalData, canMessages))
        .map((signal: ChartSignal) => {
            if (signal.resource.type === SignalResourceType.Log) {
                // Filter out any unavailable devices
                const deviceIds = signal.resource.deviceIds.filter((id) =>
                    availableDeviceIds.includes(id),
                );
                return {
                    ...signal,
                    resource: { ...signal.resource, deviceIds },
                };
            }
            return signal;
        })
        .filter((signal: ChartSignal) => {
            if (signal.resource.type === SignalResourceType.Log) {
                // Filter out any signals that have no devices or CAN signals
                return (
                    signal.resource.deviceIds.length > 0 &&
                    signal.resource.signalId != undefined
                );
            }
            return true;
        });

export const loadSignal = (signalData, canMessages: ChartCanMessage[]) => {
    // The `resource` field is only present in current signal format
    const isLegacyFormat = signalData['resource'] === undefined;
    if (isLegacyFormat) {
        return loadLegacySignalFormat(signalData, canMessages);
    }
    return fillInSignal(signalData);
};

// TODO: remove this once all reports are using new format
const loadLegacySignalFormat = (
    signalData,
    canMessages: ChartCanMessage[],
): ChartSignal => {
    log.info('legacy signal data:', signalData);
    let signalId = signalData['canSignalId'] ?? signalData['signalIds']?.[0];
    if (!isDefined(signalId)) {
        const parsedExpression = ExpressionLoader.load(
            signalData['expression'],
            combineSignals(canMessages),
        );
        log.info('parsed expression:', parsedExpression.data);
        log.info('parsed signal IDs:', parsedExpression.signalIds);
        signalId = parsedExpression.signalIds[0] ?? null;
    }
    return fillInSignal({
        uuid: signalData.uuid,
        name: signalData.name,
        hidden: signalData.hidden,
        resource: {
            type: SignalResourceType.Log,
            signalId,
            deviceIds: signalData['deviceIds'],
            combineData: signalData['combineData'],
            stat: Stat.Avg,
        },
    });
};

/**
 * Fill in default values for a chart signal.
 */
export const fillInSignal = (
    partialSignal: Partial<ChartSignal>,
): ChartSignal => ({
    uuid: partialSignal.uuid ?? uuidv4(),
    name: partialSignal.name ?? '--',
    hidden: partialSignal.hidden ?? false,
    resource: fillInResource(partialSignal.resource ?? {}),
});

export const fillInResource = (
    resource: Partial<ChartSignalResource>,
): ChartSignalResource => {
    const type = resource.type ?? SignalResourceType.Log;
    switch (type) {
        case SignalResourceType.Log: {
            resource = resource as LogSignalResource;
            return {
                type: SignalResourceType.Log,
                deviceIds: resource.deviceIds ?? [],
                // Don't need to provide default value here
                // Invalid signal IDs will get filtered out in `loadSignals`
                signalId: resource.signalId,
                stat: resource.stat ?? Stat.Avg,
                combineData: resource.combineData ?? false,
                threshold: resource.threshold,
            };
        }
        case SignalResourceType.Time:
            resource = resource as TimeSignalResource;
            return {
                type: SignalResourceType.Time,
                format: resource.format ?? TimeFormat.Timestamp,
            };
        default:
            throw Error(`Unknown signal type: ${type}`);
    }
};

/**
 * Generate the statistics signals for a series chart.
 */
export const generateSeriesStatisticSignals = (
    signals: ChartSignal[],
): StatisticSignal[] =>
    prepSignals(signals).map((signal) => ({
        uuid: generateUuid(signal, StatisticType.Series),
        type: StatisticType.Series,
        resource: signal.resource,
    }));

/**
 * Generate the statistics signals for a aggregate chart.
 */
export const generateAggregateStatisticSignals = (
    signals: ChartSignal[],
): StatisticSignal[] =>
    prepSignals(signals).map((signal) => ({
        uuid: generateUuid(signal, StatisticType.Aggregate),
        type: StatisticType.Aggregate,
        resource: signal.resource,
    }));
