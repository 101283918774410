<h1 mat-dialog-title>{{ 'delete.confirm' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div>
        {{
            'delete.confirm.description'
                | translate
                    : { entityType: (typeTerm | translate).toLowerCase() }
        }}
    </div>
    <sz-dialog-button-bar
        primaryText="delete"
        primaryColor="warn"
        (primaryClick)="dialogRef.close(true)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
