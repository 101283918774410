import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { getAccessToken } from './auth-utils';
import { isDefined } from './common';

export type CacheKeyOptions = {
    args?: unknown[];
    refresh?: number;
};
export const getCacheKey = (options: CacheKeyOptions = {}) => {
    const { args = [], refresh = 0 } = options;
    // If the refresh field is given, generate a time segment that will expire
    // every `refresh` seconds.
    const timeSegment =
        refresh > 0 ? Math.floor(+new Date() / (refresh * 1000)) : '0';
    return getAccessToken() + '-' + timeSegment + '-' + JSON.stringify(args);
};

/**
 * Cache the results of a function.
 */
export function cached(
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
) {
    const cache = {};
    const pending: Record<string, BehaviorSubject<unknown>> = {};
    const originalMethod = descriptor.value;
    descriptor.value = async function (this: unknown, ...args: unknown[]) {
        const cacheKey = getCacheKey({ args });
        const cachedResult = cache[cacheKey];
        if (cachedResult) {
            return cachedResult;
        }
        const pendingResult = pending[cacheKey];
        if (pendingResult) {
            return new Promise((resolve) =>
                pendingResult
                    .pipe(filter(isDefined), take(1))
                    .subscribe((result) => resolve(result)),
            );
        }
        const pendingItem = new BehaviorSubject(undefined);
        pending[cacheKey] = pendingItem;
        const result = await originalMethod.apply(this, args);
        cache[cacheKey] = result;
        pendingItem.next(result);
        delete pending[cacheKey];
        return result;
    };
    return descriptor;
}
