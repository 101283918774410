import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class LinkWidget extends Widget {
    type = WidgetType.LinkWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'title',
                    previewValue: 'Title',
                },
                {
                    id: 'link',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.link',
                    previewValue: 'https://spoke.zone',
                },
            ],
            settings,
        );
    }
}
