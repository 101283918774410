import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class RebootCommandWidget extends Widget {
    type = WidgetType.RebootCommandWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Title',
                },
                {
                    id: 'datasource',
                    type: WidgetSettingType.Datasource,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
            ],
            settings,
        );
    }
}
