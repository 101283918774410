export interface LogOptions {
    tag?: string;
}

let _startTimestamp = 0;

class Logger {
    constructor(private options: LogOptions = {}) {}

    start() {
        if (_startTimestamp) {
            throw Error('Logger start timestamp can only be set once.');
        }
        _startTimestamp = Date.now();
    }

    tag(tag: string) {
        return new Logger({ ...this.options, tag });
    }

    info(...message) {
        console.log(getPrefixString(this.options), ...message);
    }

    error(...message) {
        console.error(getPrefixString(this.options), ...message);
    }
}

const getPrefixString = (options: LogOptions = {}) => {
    const diff = Date.now() - _startTimestamp;
    const tag = options.tag ?? 'unknown';
    return diff.toString().padStart(5) + ': ' + tag.padEnd(16) + ' > ';
};

export const log = new Logger();
