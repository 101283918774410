import {
    ModelDocumentationFile,
    CreateModelDocumentationFile,
} from 'src/app/shared/models/model-documentation-file';
import { ApiService } from './api.service';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import { TopLevelQueryParams } from './top-level-route-api';

export interface ModelDocumentationFilesQueryParams
    extends TopLevelQueryParams {
    modelId?: number;
    isActive?: boolean;
}

export class ModelDocumentationFilesApi extends TopLevelFileRouteApi<
    ModelDocumentationFile,
    CreateModelDocumentationFile,
    ModelDocumentationFilesQueryParams,
    unknown
> {
    get path() {
        return 'model-documentation-files';
    }

    get fileSubPath() {
        return 'file';
    }

    constructor(api: ApiService) {
        super(api);
    }
}
