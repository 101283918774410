import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class MapWidget extends Widget {
    type = WidgetType.MapWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'lat',
                    type: WidgetSettingType.Calculated,
                    name: 'coordinates.latitude',
                    previewValue: '39.76',
                },
                {
                    id: 'lon',
                    type: WidgetSettingType.Calculated,
                    name: 'coordinates.longitude',
                    previewValue: '-84.19',
                },
            ],
            settings
        );
    }
}
