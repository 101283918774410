import { filter } from 'rxjs/operators';
import { WidgetPreview } from 'src/app/shared/models/preview';
import {
    CreateWidget,
    Widget,
    WidgetsQueryParams,
} from 'src/app/shared/models/widget';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelFileRouteApi } from './top-level-file-route-api';

export class RawCustomWidgetsApi extends TopLevelFileRouteApi<
    Widget,
    CreateWidget,
    WidgetsQueryParams,
    unknown
> {
    get path() {
        return 'widgets';
    }

    get fileSubPath() {
        return 'script';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class CustomWidgetsApi extends DynamicApi<
    Widget,
    CreateWidget,
    WidgetPreview,
    WidgetsQueryParams
> {
    private raw: RawCustomWidgetsApi;
    rawAccess: RawAccessAdapter<Widget, CreateWidget, WidgetsQueryParams>;

    constructor(private api: ApiService) {
        super('widgets');
        this.raw = new RawCustomWidgetsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
    }

    async loadPreview(id: number): Promise<WidgetPreview> {
        const widget = await this.getAsync(id);
        return {
            type: 'widget',
            id,
            title: widget.name,
            subtitle: widget.organizationName,
            details: [],
            item: widget,
        };
    }

    upload(id: number, data: Blob) {
        return this.raw.upload(id, data);
    }

    download(id: number) {
        return this.raw.download(id);
    }
}
