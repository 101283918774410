import { Component, Input, booleanAttribute } from '@angular/core';

@Component({
    selector: 'sz-details-card',
    templateUrl: 'details-card.component.html',
    styleUrls: ['details-card.component.scss'],
})
export class DetailsCardComponent {
    @Input() type: 'default' | 'button' = 'default';
    @Input({ transform: booleanAttribute }) wrapTitle = false;
}
