import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterOption } from 'src/app/common/layout/multi-select/multi-select.component';
import { cached } from 'src/app/shared/cache';
import {
    CreateModel,
    Model,
    ModelType,
    ModelsQueryParams,
    getTypeName,
} from 'src/app/shared/models/model';
import { ModelPreview } from 'src/app/shared/models/preview';
import { environment } from 'src/environments/environment';
import { urlBaseV2 } from '../constants';
import { ApiService } from './api.service';
import { DynamicApi, ensureLoaded } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelRouteApi } from './top-level-route-api';

class RawModelsApi extends TopLevelRouteApi<
    Model,
    CreateModel,
    ModelsQueryParams
> {
    get path() {
        return 'models';
    }

    constructor(api: ApiService) {
        super(api);
    }

    getTypes() {
        return this.retrieve<ModelType[]>('types');
    }
}

export class ModelsApi extends DynamicApi<
    Model,
    CreateModel,
    ModelPreview,
    ModelsQueryParams
> {
    private raw: RawModelsApi;
    rawAccess: RawAccessAdapter<Model, CreateModel, ModelsQueryParams>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super('models');
        this.raw = new RawModelsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
        this.translate = inject(TranslateService);
    }

    @cached
    async getTypes(): Promise<ModelType[]> {
        return await this.raw.getTypes();
    }

    async loadPreview(id: number): Promise<ModelPreview> {
        await ensureLoaded([this.api.devices]);
        const model = await this.getAsync(id);
        const organization = await this.api.organizations.getAsync(
            model.customerId,
        );
        const devices = this.api.devices.current({ modelId: id });
        const imageUrl = model.productImage
            ? urlBaseV2 + '/models/' + id + '/thumbnail'
            : environment.apiUrl + '/images/Placeholder-model.png';
        return {
            type: 'model',
            id,
            title: model.name,
            subtitle: organization?.name ?? '',
            imageUrl,
            details: [
                // TODO: get type name from API
                { name: 'type', value: getTypeName(model.deviceType) },
                { name: 'device.plural', value: devices.length.toString() },
            ],
            item: model,
        };
    }

    async asFilterOption(id: number): Promise<FilterOption> {
        const model = await this.getAsync(id);
        return {
            value: id,
            name: model?.name ?? '',
        };
    }

    async asFilterOptions(): Promise<FilterOption[]> {
        await ensureLoaded([this]);
        return this.current().map((model) => ({
            value: model.id,
            name: model?.name ?? '',
        }));
    }
}
