module.exports = {
  ".artboard-demo": {
    "display": "flex",
    "flex": "none",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center"
  },
  ".artboard.phone": {
    "width": "320px"
  },
  ".artboard.phone-1": {
    "width": "320px",
    "height": "568px"
  },
  ".artboard.phone-1.horizontal,\n      .artboard.phone-1.artboard-horizontal": {
    "width": "568px",
    "height": "320px"
  },
  ".artboard.phone-2": {
    "width": "375px",
    "height": "667px"
  },
  ".artboard.phone-2.horizontal,\n      .artboard.phone-2.artboard-horizontal": {
    "width": "667px",
    "height": "375px"
  },
  ".artboard.phone-3": {
    "width": "414px",
    "height": "736px"
  },
  ".artboard.phone-3.horizontal,\n      .artboard.phone-3.artboard-horizontal": {
    "width": "736px",
    "height": "414px"
  },
  ".artboard.phone-4": {
    "width": "375px",
    "height": "812px"
  },
  ".artboard.phone-4.horizontal,\n      .artboard.phone-4.artboard-horizontal": {
    "width": "812px",
    "height": "375px"
  },
  ".artboard.phone-5": {
    "width": "414px",
    "height": "896px"
  },
  ".artboard.phone-5.horizontal,\n      .artboard.phone-5.artboard-horizontal": {
    "width": "896px",
    "height": "414px"
  },
  ".artboard.phone-6": {
    "width": "320px",
    "height": "1024px"
  },
  ".artboard.phone-6.horizontal,\n      .artboard.phone-6.artboard-horizontal": {
    "width": "1024px",
    "height": "320px"
  },
  ".badge-xs": {
    "height": "0.75rem",
    "fontSize": "0.75rem",
    "lineHeight": ".75rem",
    "paddingLeft": "0.313rem",
    "paddingRight": "0.313rem"
  },
  ".badge-sm": {
    "height": "1rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "paddingLeft": "0.438rem",
    "paddingRight": "0.438rem"
  },
  ".badge-md": {
    "height": "1.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "paddingLeft": "0.563rem",
    "paddingRight": "0.563rem"
  },
  ".badge-lg": {
    "height": "1.5rem",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "paddingLeft": "0.688rem",
    "paddingRight": "0.688rem"
  },
  ".btm-nav-xs": {
    "height": "2.5rem"
  },
  ".btm-nav-xs > *:where(.active)": {
    "borderTopWidth": "1px"
  },
  ".btm-nav-xs .btm-nav-label": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".btm-nav-sm": {
    "height": "3rem"
  },
  ".btm-nav-sm > *:where(.active)": {
    "borderTopWidth": "2px"
  },
  ".btm-nav-sm .btm-nav-label": {
    "fontSize": "0.75rem",
    "lineHeight": "1rem"
  },
  ".btm-nav-md": {
    "height": "4rem"
  },
  ".btm-nav-md > *:where(.active)": {
    "borderTopWidth": "2px"
  },
  ".btm-nav-md .btm-nav-label": {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem"
  },
  ".btm-nav-lg": {
    "height": "5rem"
  },
  ".btm-nav-lg > *:where(.active)": {
    "borderTopWidth": "4px"
  },
  ".btm-nav-lg .btm-nav-label": {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  },
  ".btn-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "minHeight": "1.5rem",
    "fontSize": "0.75rem"
  },
  ".btn-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "minHeight": "2rem",
    "fontSize": "0.875rem"
  },
  ".btn-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "minHeight": "3rem",
    "fontSize": "0.875rem"
  },
  ".btn-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "minHeight": "4rem",
    "fontSize": "1.125rem"
  },
  ".btn-wide": {
    "width": "16rem"
  },
  ".btn-block": {
    "width": "100%"
  },
  ".btn-square:where(.btn-xs)": {
    "height": "1.5rem",
    "width": "1.5rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-sm)": {
    "height": "2rem",
    "width": "2rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-md)": {
    "height": "3rem",
    "width": "3rem",
    "padding": "0px"
  },
  ".btn-square:where(.btn-lg)": {
    "height": "4rem",
    "width": "4rem",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-xs)": {
    "height": "1.5rem",
    "width": "1.5rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-sm)": {
    "height": "2rem",
    "width": "2rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-md)": {
    "height": "3rem",
    "width": "3rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-circle:where(.btn-lg)": {
    "height": "4rem",
    "width": "4rem",
    "borderRadius": "9999px",
    "padding": "0px"
  },
  ".btn-group-horizontal": {
    "flexDirection": "row"
  },
  ".btn-group-vertical": {
    "flexDirection": "column"
  },
  ".card-side": {
    "alignItems": "stretch",
    "flexDirection": "row"
  },
  ".card-side :where(figure:first-child)": {
    "overflow": "hidden",
    "borderStartStartRadius": "inherit",
    "borderStartEndRadius": "unset",
    "borderEndStartRadius": "inherit",
    "borderEndEndRadius": "unset"
  },
  ".card-side :where(figure:last-child)": {
    "overflow": "hidden",
    "borderStartStartRadius": "unset",
    "borderStartEndRadius": "inherit",
    "borderEndStartRadius": "unset",
    "borderEndEndRadius": "inherit"
  },
  ".card-side figure > *": {
    "maxWidth": "unset"
  },
  ":where(.card-side figure > *)": {
    "width": "100%",
    "height": "100%",
    "objectFit": "cover"
  },
  ".checkbox-xs": {
    "height": "1rem",
    "width": "1rem"
  },
  ".checkbox-sm": {
    "height": "1.25rem",
    "width": "1.25rem"
  },
  ".checkbox-md": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".checkbox-lg": {
    "height": "2rem",
    "width": "2rem"
  },
  ".divider-horizontal": {
    "flexDirection": "column"
  },
  ".divider-horizontal:before": {
    "height": "100%",
    "width": "0.125rem"
  },
  ".divider-horizontal:after": {
    "height": "100%",
    "width": "0.125rem"
  },
  ".divider-vertical": {
    "flexDirection": "row"
  },
  ".divider-vertical:before": {
    "height": "0.125rem",
    "width": "100%"
  },
  ".divider-vertical:after": {
    "height": "0.125rem",
    "width": "100%"
  },
  ".drawer-open > .drawer-toggle": {
    "display": "none"
  },
  ".drawer-open > .drawer-toggle ~ .drawer-side": {
    "pointerEvents": "auto",
    "visibility": "visible",
    "position": "sticky",
    "display": "block",
    "width": "auto",
    "overscrollBehavior": "auto"
  },
  ".drawer-open > .drawer-toggle ~ .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(0%)"
  },
  "[dir=\"rtl\"] .drawer-open > .drawer-toggle ~ .drawer-side > *:not(.drawer-overlay)": {
    "transform": "translateX(0%)"
  },
  ".drawer-open > .drawer-toggle:checked ~ .drawer-side": {
    "pointerEvents": "auto",
    "visibility": "visible"
  },
  ".file-input-xs": {
    "height": "1.5rem",
    "paddingRight": "0.5rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"]
  },
  ".file-input-xs::file-selector-button": {
    "marginRight": "0.5rem",
    "fontSize": "0.75rem"
  },
  ".file-input-sm": {
    "height": "2rem",
    "paddingRight": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".file-input-sm::file-selector-button": {
    "marginRight": "0.75rem",
    "fontSize": "0.875rem"
  },
  ".file-input-md": {
    "height": "3rem",
    "paddingRight": "1rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".file-input-md::file-selector-button": {
    "marginRight": "1rem",
    "fontSize": "0.875rem"
  },
  ".file-input-lg": {
    "height": "4rem",
    "paddingRight": "1.5rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"]
  },
  ".file-input-lg::file-selector-button": {
    "marginRight": "1.5rem",
    "fontSize": "1.125rem"
  },
  ".indicator :where(.indicator-item)": {
    "bottom": "auto",
    "left": "auto",
    "right": "0px",
    "top": "0px",
    "-TwTranslateY": "-50%",
    "-TwTranslateX": "50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-start)": {
    "left": "0px",
    "right": "auto",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-center)": {
    "left": "50%",
    "right": "50%",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-end)": {
    "left": "auto",
    "right": "0px",
    "-TwTranslateX": "50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-bottom)": {
    "bottom": "0px",
    "top": "auto",
    "-TwTranslateY": "50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-middle)": {
    "bottom": "50%",
    "top": "50%",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".indicator :where(.indicator-item.indicator-top)": {
    "bottom": "auto",
    "top": "0px",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".input-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"]
  },
  ".input-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".input-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"]
  },
  ".input-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": "2rem"
  },
  ".join.join-vertical": {
    "flexDirection": "column"
  },
  ".join.join-vertical .join-item:first-child:not(:last-child),\n  .join.join-vertical *:first-child:not(:last-child) .join-item": {
    "borderBottomLeftRadius": "0",
    "borderBottomRightRadius": "0",
    "borderTopLeftRadius": "inherit",
    "borderTopRightRadius": "inherit"
  },
  ".join.join-vertical .join-item:last-child:not(:first-child),\n  .join.join-vertical *:last-child:not(:first-child) .join-item": {
    "borderTopLeftRadius": "0",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "inherit",
    "borderBottomRightRadius": "inherit"
  },
  ".join.join-horizontal": {
    "flexDirection": "row"
  },
  ".join.join-horizontal .join-item:first-child:not(:last-child),\n  .join.join-horizontal *:first-child:not(:last-child) .join-item": {
    "borderBottomRightRadius": "0",
    "borderTopRightRadius": "0",
    "borderBottomLeftRadius": "inherit",
    "borderTopLeftRadius": "inherit"
  },
  ".join.join-horizontal .join-item:last-child:not(:first-child),\n  .join.join-horizontal *:last-child:not(:first-child) .join-item": {
    "borderBottomLeftRadius": "0",
    "borderTopLeftRadius": "0",
    "borderBottomRightRadius": "inherit",
    "borderTopRightRadius": "inherit"
  },
  ".kbd-xs": {
    "paddingLeft": "0.25rem",
    "paddingRight": "0.25rem",
    "fontSize": "0.75rem",
    "lineHeight": "1rem",
    "minHeight": "1.2em",
    "minWidth": "1.2em"
  },
  ".kbd-sm": {
    "paddingLeft": "0.25rem",
    "paddingRight": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "minHeight": "1.6em",
    "minWidth": "1.6em"
  },
  ".kbd-md": {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "fontSize": "1rem",
    "lineHeight": "1.5rem",
    "minHeight": "2.2em",
    "minWidth": "2.2em"
  },
  ".kbd-lg": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "fontSize": "1.125rem",
    "lineHeight": "1.75rem",
    "minHeight": "2.5em",
    "minWidth": "2.5em"
  },
  ".menu-horizontal": {
    "display": "inline-flex",
    "flexDirection": "row"
  },
  ".menu-horizontal > li:not(.menu-title) > details > ul": {
    "position": "absolute"
  },
  ".menu-vertical": {
    "display": "flex",
    "flexDirection": "column"
  },
  ".menu-vertical > li:not(.menu-title) > details > ul": {
    "position": "relative"
  },
  ".modal-top": {
    "placeItems": "start"
  },
  ".modal-middle": {
    "placeItems": "center"
  },
  ".modal-bottom": {
    "placeItems": "end"
  },
  ".radio-xs": {
    "height": "1rem",
    "width": "1rem"
  },
  ".radio-sm": {
    "height": "1.25rem",
    "width": "1.25rem"
  },
  ".radio-md": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".radio-lg": {
    "height": "2rem",
    "width": "2rem"
  },
  ".range-xs": {
    "height": "1rem"
  },
  ".range-xs::-webkit-slider-runnable-track": {
    "height": "0.25rem"
  },
  ".range-xs::-moz-range-track": {
    "height": "0.25rem"
  },
  ".range-xs::-webkit-slider-thumb": {
    "height": "1rem",
    "width": "1rem",
    "-FillerOffset": "0.4rem"
  },
  ".range-xs::-moz-range-thumb": {
    "height": "1rem",
    "width": "1rem",
    "-FillerOffset": "0.4rem"
  },
  ".range-sm": {
    "height": "1.25rem"
  },
  ".range-sm::-webkit-slider-runnable-track": {
    "height": "0.25rem"
  },
  ".range-sm::-moz-range-track": {
    "height": "0.25rem"
  },
  ".range-sm::-webkit-slider-thumb": {
    "height": "1.25rem",
    "width": "1.25rem",
    "-FillerOffset": "0.5rem"
  },
  ".range-sm::-moz-range-thumb": {
    "height": "1.25rem",
    "width": "1.25rem",
    "-FillerOffset": "0.5rem"
  },
  ".range-md": {
    "height": "1.5rem"
  },
  ".range-md::-webkit-slider-runnable-track": {
    "height": "0.5rem"
  },
  ".range-md::-moz-range-track": {
    "height": "0.5rem"
  },
  ".range-md::-webkit-slider-thumb": {
    "height": "1.5rem",
    "width": "1.5rem",
    "-FillerOffset": "0.6rem"
  },
  ".range-md::-moz-range-thumb": {
    "height": "1.5rem",
    "width": "1.5rem",
    "-FillerOffset": "0.6rem"
  },
  ".range-lg": {
    "height": "2rem"
  },
  ".range-lg::-webkit-slider-runnable-track": {
    "height": "1rem"
  },
  ".range-lg::-moz-range-track": {
    "height": "1rem"
  },
  ".range-lg::-webkit-slider-thumb": {
    "height": "2rem",
    "width": "2rem",
    "-FillerOffset": "1rem"
  },
  ".range-lg::-moz-range-thumb": {
    "height": "2rem",
    "width": "2rem",
    "-FillerOffset": "1rem"
  },
  ".rating-xs input": {
    "height": "0.75rem",
    "width": "0.75rem"
  },
  ".rating-sm input": {
    "height": "1rem",
    "width": "1rem"
  },
  ".rating-md input": {
    "height": "1.5rem",
    "width": "1.5rem"
  },
  ".rating-lg input": {
    "height": "2.5rem",
    "width": "2.5rem"
  },
  ".rating-half.rating-xs input:not(.rating-hidden)": {
    "width": "0.375rem"
  },
  ".rating-half.rating-sm input:not(.rating-hidden)": {
    "width": "0.5rem"
  },
  ".rating-half.rating-md input:not(.rating-hidden)": {
    "width": "0.75rem"
  },
  ".rating-half.rating-lg input:not(.rating-hidden)": {
    "width": "1.25rem"
  },
  ".select-md": {
    "height": "3rem",
    "paddingLeft": "1rem",
    "paddingRight": "2.5rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "minHeight": "3rem"
  },
  "[dir=\"rtl\"] .select-md": {
    "paddingLeft": "2.5rem",
    "paddingRight": "1rem"
  },
  ".select-lg": {
    "height": "4rem",
    "paddingLeft": "1.5rem",
    "paddingRight": "2rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"],
    "minHeight": "4rem"
  },
  "[dir=\"rtl\"] .select-lg": {
    "paddingLeft": "2rem",
    "paddingRight": "1.5rem"
  },
  ".select-sm": {
    "height": "2rem",
    "paddingLeft": "0.75rem",
    "paddingRight": "2rem",
    "fontSize": "0.875rem",
    "lineHeight": "2rem",
    "minHeight": "2rem"
  },
  "[dir=\"rtl\"] .select-sm": {
    "paddingLeft": "2rem",
    "paddingRight": "0.75rem"
  },
  ".select-xs": {
    "height": "1.5rem",
    "paddingLeft": "0.5rem",
    "paddingRight": "2rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"],
    "minHeight": "1.5rem"
  },
  "[dir=\"rtl\"] .select-xs": {
    "paddingLeft": "2rem",
    "paddingRight": "0.5rem"
  },
  ".stats-horizontal": {
    "gridAutoFlow": "column"
  },
  ".stats-vertical": {
    "gridAutoFlow": "row"
  },
  ".steps-horizontal": {
    "gridAutoColumns": "1fr",
    "display": "inline-grid",
    "gridAutoFlow": "column",
    "overflow": "hidden",
    "overflowX": "auto"
  },
  ".steps-horizontal .step": {
    "display": "grid",
    "gridTemplateColumns": "repeat(1, minmax(0, 1fr))",
    "gridTemplateRows": "repeat(2, minmax(0, 1fr))",
    "placeItems": "center",
    "textAlign": "center"
  },
  ".steps-vertical": {
    "gridAutoRows": "1fr",
    "gridAutoFlow": "row"
  },
  ".steps-vertical .step": {
    "display": "grid",
    "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
    "gridTemplateRows": "repeat(1, minmax(0, 1fr))"
  },
  ".tab-md": {
    "height": "2rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"],
    "-TabPadding": "1rem"
  },
  ".tab-lg": {
    "height": "3rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"],
    "-TabPadding": "1.25rem"
  },
  ".tab-sm": {
    "height": "1.5rem",
    "fontSize": "0.875rem",
    "lineHeight": ".75rem",
    "-TabPadding": "0.75rem"
  },
  ".tab-xs": {
    "height": "1.25rem",
    "fontSize": "0.75rem",
    "lineHeight": ".75rem",
    "-TabPadding": "0.5rem"
  },
  ".textarea-xs": {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.75rem",
    "lineHeight": ["1rem", "1.625"]
  },
  ".textarea-sm": {
    "paddingLeft": "0.75rem",
    "paddingRight": "0.75rem",
    "paddingTop": "0.25rem",
    "paddingBottom": "0.25rem",
    "fontSize": "0.875rem",
    "lineHeight": "2rem"
  },
  ".textarea-md": {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "0.75rem",
    "paddingBottom": "0.75rem",
    "fontSize": "0.875rem",
    "lineHeight": ["1.25rem", "2"]
  },
  ".textarea-lg": {
    "paddingLeft": "1.5rem",
    "paddingRight": "1.5rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "fontSize": "1.125rem",
    "lineHeight": ["1.75rem", "2"]
  },
  ":where(.toast)": {
    "bottom": "0px",
    "left": "auto",
    "right": "0px",
    "top": "auto",
    "-TwTranslateX": "0px",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-start)": {
    "left": "0px",
    "right": "auto",
    "-TwTranslateX": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-center)": {
    "left": "50%",
    "right": "50%",
    "-TwTranslateX": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-end)": {
    "left": "auto",
    "right": "0px",
    "-TwTranslateX": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-bottom)": {
    "bottom": "0px",
    "top": "auto",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-middle)": {
    "bottom": "auto",
    "top": "50%",
    "-TwTranslateY": "-50%",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toast:where(.toast-top)": {
    "bottom": "auto",
    "top": "0px",
    "-TwTranslateY": "0px",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  },
  ".toggle-xs": {
    "-Handleoffset": "0.5rem",
    "height": "1rem",
    "width": "1.5rem"
  },
  ".toggle-sm": {
    "-Handleoffset": "0.75rem",
    "height": "1.25rem",
    "width": "2rem"
  },
  ".toggle-md": {
    "-Handleoffset": "1.5rem",
    "height": "1.5rem",
    "width": "3rem"
  },
  ".toggle-lg": {
    "-Handleoffset": "2rem",
    "height": "2rem",
    "width": "4rem"
  },
  ".tooltip": {
    "position": "relative",
    "display": "inline-block",
    "-TooltipOffset": "calc(100% + 1px + var(--tooltip-tail, 0px))"
  },
  ".tooltip:before": {
    "position": "absolute",
    "pointerEvents": "none",
    "zIndex": "1",
    "content": "var(--tw-content)",
    "-TwContent": "attr(data-tip)"
  },
  ".tooltip:before, .tooltip-top:before": {
    "transform": "translateX(-50%)",
    "top": "auto",
    "left": "50%",
    "right": "auto",
    "bottom": "var(--tooltip-offset)"
  },
  ".tooltip-bottom:before": {
    "transform": "translateX(-50%)",
    "top": "var(--tooltip-offset)",
    "left": "50%",
    "right": "auto",
    "bottom": "auto"
  },
  ".tooltip-left:before": {
    "transform": "translateY(-50%)",
    "top": "50%",
    "left": "auto",
    "right": "var(--tooltip-offset)",
    "bottom": "auto"
  },
  ".tooltip-right:before": {
    "transform": "translateY(-50%)",
    "top": "50%",
    "left": "var(--tooltip-offset)",
    "right": "auto",
    "bottom": "auto"
  }
};