import {
    CreateGeoFence,
    GeoFence,
    GeoFenceQueryParams,
} from 'src/app/shared/models/geo-fence';
import { GeoFencePreview } from 'src/app/shared/models/preview';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelRouteApi } from './top-level-route-api';

export class RawGeoFencesApi extends TopLevelRouteApi<
    GeoFence,
    CreateGeoFence,
    GeoFenceQueryParams
> {
    get path() {
        return 'geo-fences';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): GeoFence {
        return {
            ...data,
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.split(','),
                phoneNumbers: a.phoneNumbers?.split(','),
            })),
        };
    }

    transformFrontendData(data): GeoFence {
        return {
            ...data,
            actions: data.actions?.map((a) => ({
                ...a,
                emailAddresses: a.emailAddresses?.join(','),
                phoneNumbers: a.phoneNumbers?.join(','),
            })),
        };
    }
}

export class GeoFencesApi extends DynamicApi<
    GeoFence,
    CreateGeoFence,
    GeoFencePreview,
    GeoFenceQueryParams
> {
    raw: RawGeoFencesApi;
    rawAccess: RawAccessAdapter<GeoFence, CreateGeoFence, GeoFenceQueryParams>;

    constructor(private api: ApiService) {
        super('geo-fences');
        this.raw = new RawGeoFencesApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
    }

    async loadPreview(id: number): Promise<GeoFencePreview> {
        const geoFence = await this.getAsync(id);
        const organization = await this.api.organizations.getAsync(
            geoFence.organizationId,
        );
        return {
            type: 'geo-fence',
            id,
            title: geoFence.name,
            subtitle: organization?.name ?? '',
            deviceIds: geoFence?.deviceIds,
            item: geoFence,
        };
    }
}
