import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class HexWidget extends Widget {
    type = WidgetType.HexWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Title',
                },
                {
                    id: 'datasource',
                    type: WidgetSettingType.Datasource,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
                {
                    id: 'withValues',
                    type: WidgetSettingType.Checkbox,
                    name: 'widget.type.hex.with_values',
                    previewValue: false,
                    defaultValue: false,
                },
            ],
            settings,
        );
    }
}
