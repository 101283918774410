import { log } from 'src/app/shared/log';
import { ApiWorkerController } from './workers/api-worker';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

export interface TopLevelQueryParams {
    searchTerm?: string;
    searchFields?: string;
    offset?: number;
    limit?: number;
    sort?: string;
    sortOrder?: 'ASC' | 'DESC';
    [key: string]: string | number | boolean;
}

export interface CountResult {
    count: number;
    ids: number[];
    sums: Record<string, number>;
}

export interface CreateResult {
    id: number;
    success: boolean;
}

export interface UpdateResult {
    success: boolean;
    message: string;
}

export interface DeleteResult {
    message: string;
    deleteCount: number;
}

export class TopLevelRouteApi<
    GetType,
    CreateType,
    QueryParamsType extends TopLevelQueryParams,
> extends BasicRouteApi {
    private _workerController: ApiWorkerController;

    constructor(protected api: ApiService) {
        super(api);
        this._workerController = new ApiWorkerController(this.basePath);
    }

    transformBackendData(data): GetType {
        return data;
    }

    transformFrontendData(data: Partial<CreateType>) {
        return data;
    }

    async count(queryParams?: QueryParamsType) {
        log.tag(this.basePath).info('COUNT STARTED');
        const result = await this._workerController.count(queryParams);
        log.tag(this.basePath).info('COUNT FINISHED');
        return result as CountResult;
    }

    async query(queryParams?: QueryParamsType) {
        log.tag(this.basePath).info('QUERY STARTED');
        const result = await this._workerController.query(queryParams);
        log.tag(this.basePath).info('QUERY FINISHED');
        return result.map((v) => this.transformBackendData(v)) as GetType[];
    }

    async get(id: number) {
        return this.transformBackendData(
            (await this.api.client
                .get(this.basePath + '/' + id)
                .toPromise()) as any,
        ) as GetType;
    }

    async create(data: CreateType) {
        return (await this.api.client
            .post(this.basePath, this.transformFrontendData(data))
            .toPromise()) as CreateResult;
    }

    async update(id: number, data: Partial<CreateType>) {
        return (await this.api.client
            .patch(this.basePath + '/' + id, this.transformFrontendData(data))
            .toPromise()) as UpdateResult;
    }

    async delete(ids: number[]) {
        return (await this.api.client
            .delete(this.basePath, {
                params: this.buildParams({ ids: ids.join(',') }),
            })
            .toPromise()) as DeleteResult;
    }
}
