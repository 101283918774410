import { Component, Input } from '@angular/core';

@Component({
    selector: 'sz-illustration',
    templateUrl: 'illustration.component.html',
    styles: `:host { height: 100%; width: 100% }`,
})
export class IllustrationComponent {
    @Input() name;
    @Input() heading;
    @Input() description;
}
