import { CustomerRole, UserRole } from '../services/constants';
import { getFromStorage } from './storage-utils';

/**
 * Check if the current user's customer role includes the given role.
 */
export function hasRole(customerRole: CustomerRole) {
    return getCustomerRole() <= customerRole;
}

/**
 * Check if the current user's customer role is the given role.
 */
export function isRole(customerRole: CustomerRole) {
    return getCustomerRole() == customerRole;
}

/**
 * Check if the current user's permission is ReadWrite.
 */
export function canWrite() {
    return getUserPermissions() == UserRole.ReadWrite;
}

/**
 * Returns the current user's customer role.
 */
export function getCustomerRole(): CustomerRole {
    return +getFromStorage('customerRole');
}

/**
 * Returns the current user's customer ID.
 */
export function getCustomerId(): number {
    return +getFromStorage('customerId');
}

/**
 * Returns the current user's email.
 */
export function getUserEmail(): string {
    return getFromStorage('user');
}

/**
 * Returns the current user's ID.
 */
export function getUserId(): number {
    return +getFromStorage('userId');
}

/**
 * Returns the current user's permissions.
 */
export function getUserPermissions(): number {
    return +getFromStorage('userRole');
}

/**
 * Returns the current user's access token.
 */
export function getAccessToken(): string {
    return getFromStorage('token');
}

/**
 * Get the current user's OEM ID.
 */
export function getOemId() {
    return +getFromStorage('oemCustomerId');
}
