import { generateRandomId } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';

const callbackCache = {};

export const workerPromise = <T>(
    worker: Worker,
    message,
    progressCallback?: (progress: number) => void,
): Promise<T> => {
    worker.onmessage = ({ data }) => {
        const callback = callbackCache[data.requestId];
        if (callback) {
            callback(data);
        } else {
            log.error(`No callback for request ${data.requestId}`);
        }
    };
    const requestId = generateRandomId();
    return new Promise((resolve) => {
        callbackCache[requestId] = ({ status, progress, result }) => {
            if (status === 'progress' && progressCallback) {
                progressCallback(progress);
            }
            if (status === 'finished') {
                resolve(result);
                delete callbackCache[requestId];
            }
        };
        worker.postMessage({ ...message, requestId });
    });
};
