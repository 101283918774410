// Modified from
// - https://github.com/ngstack/code-editor
// - https://stackoverflow.com/a/71217282
// - https://github.com/atularen/ngx-monaco-editor
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { first, throttleTime } from 'rxjs/operators';
import { MonacoEditorService } from '../../services/monaco-editor/monaco-editor-service';
import { SettingsService } from '../../services/user/settings.service';

declare let monaco;

@Component({
    selector: 'sz-code-editor',
    templateUrl: './monaco-editor.component.html',
})
export class MonacoEditorComponent
    implements OnChanges, OnDestroy, AfterViewInit
{
    @Input() language = 'javascript';
    @Input() code = '';
    @Input() readOnly = false;
    @Output() valueChanged = new EventEmitter<string>();

    private _editor;
    private _model;
    destroyRef = inject(DestroyRef);
    @ViewChild('editorContainer', { static: true })
    _editorContainer: ElementRef;
    _resizeObserver: ResizeObserver;
    sizeUpdates = new Subject<void>();

    constructor(
        private monacoEditorService: MonacoEditorService,
        private settings: SettingsService
    ) {
        this._resizeObserver = new ResizeObserver(() =>
            this.sizeUpdates.next()
        );
    }

    ngAfterViewInit() {
        // Don't try to re-layout too quickly.
        this.sizeUpdates.pipe(throttleTime(100)).subscribe(() => {
            if (this._editor) {
                this._editor.layout();
            }
        });

        this.monacoEditorService.loadingFinished
            .pipe(first(), takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.setupEditor());
    }

    ngOnDestroy() {
        if (this._resizeObserver) {
            this._resizeObserver.disconnect();
            this._resizeObserver = null;
        }
        if (this._editor) {
            this._editor.dispose();
            this._editor = null;
        }
        if (this._model) {
            this._model.dispose();
            this._model = null;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.language && !changes.language.firstChange) {
            this.updateLanguage(changes.language.currentValue);
        }
        if (changes.code && !changes.code.firstChange) {
            this.updateCode(changes.code.currentValue);
        }
    }

    private setupEditor(): void {
        this._model = monaco.editor.createModel(
            this.code,
            this.language,
            monaco.Uri.file('--' + Date.now())
        );

        const options = {
            theme: this.settings.get('extras.dark-mode')
                ? 'vs-dark'
                : 'vs-light',
            model: this._model,
            readOnly: this.readOnly,
        };
        this._editor = monaco.editor.create(
            this._editorContainer.nativeElement,
            options
        );

        this._model.onDidChangeContent(() => {
            const newValue = this._model.getValue();
            if (this.code) {
                this.code = newValue;
            }
            this.valueChanged.emit(newValue);
        });

        this._resizeObserver.observe(this._editorContainer.nativeElement);
    }

    private updateLanguage(language: string) {
        if (language && this._model && typeof monaco !== undefined) {
            monaco.editor.setModelLanguage(this._model, language);
        }
    }

    private updateCode(code: string) {
        if (code !== undefined) {
            setTimeout(() => {
                if (this._model) {
                    this._model.setValue(code);
                }
            });
        }
    }
}
