import { SettingsUpdate, UserSettings } from 'src/app/shared/models/settings';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';

export class SettingsApi extends BasicRouteApi {
    get path() {
        return 'settings';
    }

    constructor(api: ApiService) {
        super(api);
    }

    all(): Promise<UserSettings> {
        return this.retrieve<UserSettings>('');
    }

    async update(data: SettingsUpdate) {
        await this.patch('', data);
    }
}
