@if (label == null) {
    <div
        class="align-center flex flex-row gap-2 rounded-lg px-2 py-1 text-xs font-normal uppercase text-white"
        [ngClass]="'bg-' + color + '-600'"
    >
        <div *ngIf="icon !== null" class="h-4 w-4">
            <mat-icon [svgIcon]="icon" [inline]="true"></mat-icon>
        </div>
        <span class="truncate">{{ text | translate }}</span>
    </div>
} @else {
    <div
        class="flex h-6 flex-row overflow-clip rounded-lg border-2 text-xs uppercase dark:bg-gray-50/5"
        [ngClass]="'border-' + color + '-600'"
    >
        <span
            class="h-full truncate px-1 py-0.5 pr-1.5 text-white"
            [ngClass]="'bg-' + color + '-600'"
        >
            {{ label | translate }}
        </span>
        <span
            class="h-full truncate px-1 py-0.5 font-bold dark:text-white"
            [ngClass]="'text-' + color + '-600'"
        >
            {{ text | translate }}
        </span>
    </div>
}
