import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export enum IconType {
    Power = 'power',
    LedBulb = 'led_bulb',
}

export class IndicatorWidget extends Widget {
    type = WidgetType.Indicator;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Title',
                },
                {
                    id: 'value',
                    type: WidgetSettingType.Calculated,
                    name: 'widget.settings.value',
                    previewValue: 'true',
                    defaultValue: '',
                },
                {
                    id: 'onText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.on_text',
                    previewValue: 'On',
                },
                {
                    id: 'offText',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.off_text',
                    previewValue: 'Off',
                },
                {
                    id: 'icon',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.icon',
                    previewValue: IconType.Power,
                    defaultValue: IconType.Power,
                    config: {
                        options: Object.values(IconType).map((type) => ({
                            value: type,
                            name: `widget.settings.icon.${type}`,
                        })),
                    },
                },
                {
                    id: 'color',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.icon_color',
                    previewValue: 'orange',
                    defaultValue: 'orange',
                },
            ],
            settings,
        );
    }
}
