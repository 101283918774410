import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { SpinnerComponent } from '../display/spinner/spinner.component';
import { ButtonComponent } from './button/button.component';
import { DialogButtonBarComponent } from './dialog-button-bar/dialog-button-bar.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@NgModule({
    imports: [AppMaterialModule, RouterModule, SpinnerComponent],
    declarations: [
        ToggleButtonComponent,
        IconButtonComponent,
        DialogButtonBarComponent,
        ButtonComponent,
    ],
    exports: [
        ToggleButtonComponent,
        IconButtonComponent,
        DialogButtonBarComponent,
        ButtonComponent,
    ],
})
export class ButtonModule {}
