<sz-overlay [name]="filter.name ?? filter.id" [collapsible]="false">
    @if (isLoadingOptions) {
        <div class="bg-base-300 h-6 w-full rounded-lg animate-pulse"></div>
    } @else {
        <div class="flex flex-col gap-1 overflow-y-auto max-h-[25vh]">
            @if (
                selectedOptions.length == 0 && (allOptions$ | async).length == 0
            ) {
                <div class="h-6 align-middle">
                    {{ 'filter.selected.none' | translate }}
                </div>
            }
            @for (option of selectedOptions; track option.name) {
                <a
                    class="flex flex-row items-center gap-1"
                    routerLink="."
                    [queryParams]="getParamsAfterRemoveOption(option)"
                >
                    <div class="h-6 w-6">
                        <mat-icon
                            [svgIcon]="
                                isExclusive
                                    ? 'checkbox-marked-circle-outline'
                                    : 'checkbox-marked-outline'
                            "
                            [inline]="true"
                        />
                    </div>
                    <span class="truncate">
                        {{ option.name | translate }}
                    </span>
                </a>
            }
            @for (
                option of allOptions$ | async | unselected: selectedValues;
                track option.name
            ) {
                <a
                    class="flex flex-row items-center gap-1"
                    routerLink="."
                    [queryParams]="getParamsAfterAddOption(option)"
                >
                    <div class="h-6 w-6">
                        <mat-icon
                            [svgIcon]="
                                isExclusive
                                    ? 'checkbox-blank-circle-outline'
                                    : 'checkbox-blank-outline'
                            "
                            [inline]="true"
                        />
                    </div>
                    <span class="truncate">
                        {{ option.name | translate }}
                    </span>
                </a>
            }
            @if (!hasPickList) {
                <!-- TODO: show loading indicator while loading more options -->
                <!-- TODO: better UI -->
                <button
                    class="w-fit flex flex-row items-center gap-1 text-primary-500 disabled:opacity-25"
                    [disabled]="
                        !canLoadMoreOptions ||
                        (listPageService.status$ | async) !== 'loaded'
                    "
                    (click)="loadMoreOptions()"
                >
                    <mat-icon svgIcon="chevron-down" />
                    <span>
                        {{ 'filter.options.more' | translate }}
                    </span>
                </button>
            }
        </div>
    }
</sz-overlay>
