import { BillingData } from 'src/app/shared/models/billing';
import { RatePlan } from 'src/app/shared/models/device';
import { BasicRouteApi } from './basic-route-api';
import { importDateString } from 'src/app/shared/common';

export class BillingApi extends BasicRouteApi {
    get path(): string {
        return 'billing';
    }

    async getCurrent(customerId: number) {
        const data = await this.retrieve<object>('current', { customerId });
        return {
            ...data,
            startDate: importDateString(data['startDate']),
            endDate: importDateString(data['endDate']),
        } as BillingData;
    }

    getRatePlans() {
        return this.retrieve<RatePlan[]>('rate-plans');
    }
}
