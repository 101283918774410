<div class="flex flex-col">
    @for (detail of filteredDetails; track detail.name) {
        <div class="flex items-center gap-2 text-sm justify-between truncate">
            <span class="font-medium">
                {{ detail.name | translate }}
            </span>
            <span
                class="truncate select-all h-6 leading-6"
                [ngClass]="valueColorClass(detail)"
                tabindex="0"
                [matTooltip]="detail.value | translate"
                (click)="copy(detail.value)"
                (keyup.space)="copy(detail.value)"
            >
                @if (detail.icon !== undefined) {
                    @if (isString(detail.icon)) {
                        <mat-icon [svgIcon]="detail.icon" />
                    } @else {
                        <div class="flex flex-row gap-1">
                            @for (icon of detail.icon; track $index) {
                                <mat-icon [svgIcon]="icon" />
                            }
                        </div>
                    }
                } @else {
                    {{ detail.value | translate }}
                }
            </span>
        </div>
    }
</div>
