import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { ButtonModule } from '../buttons/buttons.module';
import { DirectivesModule } from '../directives/directives.module';
import { DisplayModule } from '../display/display.module';
import { ImagesModule } from '../images/images.module';
import { LayoutModule } from '../layout/layout.module';
import { DeleteManyDialog } from './delete-many-dialog/delete-many-dialog.component';
import { ItemPreviewComponent } from './item-preview/item-preview.component';
import {
    ListFilterComponent,
    UnselectedPipe,
} from './list-filter/list-filter.component';
import { ListItemPlaceholderComponent } from './list-item-placeholder/list-item-placeholder.component';
import { ListItemComponent } from './list-item/list-item.component';
import { ListLayoutComponent } from './list-layout/list-layout.component';
import { ListLoadingComponent } from './list-loading/list-loading.component';
import { ListOverlayDetailsComponent } from './list-overlay-details/list-overlay-details.component';
import { SelectedOverviewComponent } from './selected-overview/selected-overview.component';
import { StatsOverviewComponent } from './stats-overview/stats-overview.component';

const items = [
    DeleteManyDialog,
    ItemPreviewComponent,
    ListFilterComponent,
    ListItemPlaceholderComponent,
    ListItemComponent,
    ListLayoutComponent,
    ListLoadingComponent,
    ListOverlayDetailsComponent,
    SelectedOverviewComponent,
    StatsOverviewComponent,
    UnselectedPipe,
];

@NgModule({
    imports: [
        AppMaterialModule,
        DirectivesModule,
        LayoutModule,
        ButtonModule,
        DisplayModule,
        ImagesModule,
        ScrollingModule,
        RouterModule,
    ],
    declarations: items,
    exports: items,
})
export class ListPageModule {}
