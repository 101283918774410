import { ChartCanSignal } from 'src/app/chart/models/signals/log';
import { Expression } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionList } from './list';
import { ExpressionNull } from './null';
import { ExpressionOperation } from './operation';
import { Operators } from './operator';
import { ExpressionPrimitive } from './primitive';
import { ExpressionType } from './type';
import { ExpressionVariable } from './variable';

export class ExpressionLoader {
    /**
     * Serialize an expression to a JSON-like format
     */
    static serialize(expression: Expression) {
        return expression.data;
    }

    // Rebuild an expression from a JSON-like format.
    static load(data: unknown[], signals: ChartCanSignal[] = []): Expression {
        const kind = data[0] as ExpressionKind;
        let children = [];
        switch (kind) {
            case ExpressionKind.Null:
                return new ExpressionNull();
            case ExpressionKind.Primitive:
                return new ExpressionPrimitive(
                    data[1] as ExpressionType,
                    data[2] as string,
                );
            case ExpressionKind.Variable: {
                const signal = signals.find((s) => s.id == data[1]);
                if (signal) {
                    return new ExpressionVariable(signal);
                } else {
                    return new ExpressionNull();
                }
            }
            case ExpressionKind.List: {
                const items = data[1] as unknown[];
                children = items.map((item) =>
                    this.load(item as unknown[], signals),
                );
                return new ExpressionList(children);
            }
            case ExpressionKind.Operation: {
                const operatorString = data[1] as string;
                const operator = Object.values(Operators).find(
                    (o) => o.operator == operatorString,
                );
                if (!operator) {
                    throw new Error(`Operator not found: "${operatorString}".`);
                }
                children = (data[2] as unknown[]).map((item) =>
                    this.load(item as unknown[], signals),
                );
                return new ExpressionOperation(operator, children);
            }
            default:
                throw new Error('Attempted to load unknown expression.');
        }
    }
}
