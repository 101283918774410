import { EvaluationContext } from './evaluation-context';
import { Expression, IExpressionError } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionType } from './type';

export class ExpressionPrimitive extends Expression {
    type: ExpressionType;
    raw: string;

    constructor(type: ExpressionType, raw: string) {
        super();
        this.type = type;
        this.raw = raw;
    }

    get kind(): ExpressionKind {
        return ExpressionKind.Primitive;
    }

    get data(): unknown[] {
        return [this.kind, this.type, this.raw];
    }

    get error(): IExpressionError {
        if (this.type == ExpressionType.Number) {
            const isNumeric = !isNaN(Number(this.raw));
            if (!isNumeric) {
                return { term: 'filter.error.invalid_number' };
            }
        }
        return {};
    }

    toString(): string {
        return this.raw;
    }

    toJavascript(): string {
        if (this.type == ExpressionType.Number) {
            return this.raw;
        }
        let rawJsString = this.raw;
        // Escape all backslashes (\) and double quotes (") within the string
        rawJsString = rawJsString.replace(/\\/g, '\\\\');
        rawJsString = rawJsString.replace(/"/g, '\\"');
        return `"${rawJsString}"`;
    }

    clone(): ExpressionPrimitive {
        return new ExpressionPrimitive(this.type, this.raw);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    evaluate(context: EvaluationContext) {
        if (this.type == ExpressionType.Number) {
            return Number(this.raw);
        }
        return this.raw;
    }
}
