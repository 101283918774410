export enum WidgetSettingType {
    Calculated = 'calculated',
    Checkbox = 'checkbox',
    Datasource = 'datasource',
    Number = 'number',
    Repeated = 'repeated',
    Select = 'select',
    Text = 'text',
}

export type PrimitiveType = boolean | number | string;

export interface WidgetSettingDefinition {
    id: string;
    type: WidgetSettingType;
    name: string;
    defaultValue?;
    previewValue: PrimitiveType | Record<string, PrimitiveType>[] | null;
    config?: TextConfig | SelectConfig | RepeatedConfig;
    toggles?: string;
}

export interface TextConfig {
    suffix?: string;
    minimum?: number;
}

export interface SelectConfig {
    options: SelectOption[];
}

export interface RepeatedConfig {
    children: WidgetSettingDefinition[];
}

interface SelectOption {
    name: string;
    value: string | number;
}

/**
 * Get the value for a setting, given the saved value.
 */
export function getSettingValue(
    settingDefinition: WidgetSettingDefinition,
    savedValue,
) {
    switch (settingDefinition.type) {
        case WidgetSettingType.Checkbox:
        case WidgetSettingType.Datasource:
        case WidgetSettingType.Select:
        case WidgetSettingType.Number:
            return savedValue ?? settingDefinition.defaultValue;
        case WidgetSettingType.Text:
        case WidgetSettingType.Calculated:
            // If the saved value is empty, use the default value.
            return savedValue ? savedValue : settingDefinition.defaultValue;
        case WidgetSettingType.Repeated: {
            const children = (settingDefinition.config as RepeatedConfig)
                .children;
            const value = savedValue ? savedValue : [{}];
            return value.map((itemValue) =>
                Object.fromEntries(
                    children.map((c) => [
                        c.id,
                        getSettingValue(c, itemValue[c.id]),
                    ]),
                ),
            );
        }
        default:
            throw Error(`Setting type not handled: ${settingDefinition.type}`);
    }
}

/**
 * Get the preview setting values for an array of setting definitions.
 */
export function previewSettings(settingDefinitions: WidgetSettingDefinition[]) {
    return Object.fromEntries(
        settingDefinitions.map((def) => [def.id, def.previewValue]),
    );
}

export function getWidgetSettingTypeName(type: WidgetSettingType) {
    switch (type) {
        case WidgetSettingType.Checkbox:
            return 'widget.settings.type.checkbox';
        case WidgetSettingType.Datasource:
            return 'datasource';
        case WidgetSettingType.Select:
            return 'widget.settings.type.select';
        case WidgetSettingType.Number:
            return 'widget.settings.type.number';
        case WidgetSettingType.Text:
            return 'widget.settings.type.text';
        case WidgetSettingType.Calculated:
            return 'widget.settings.type.calculated';
        case WidgetSettingType.Repeated:
            return 'widget.settings.type.repeated';
    }
}

export function getWidgetSettingTypeIcon(type: WidgetSettingType) {
    switch (type) {
        case WidgetSettingType.Checkbox:
            return 'checkbox-marked-outline';
        case WidgetSettingType.Datasource:
            return 'chart-box-outline';
        case WidgetSettingType.Select:
            return 'form-dropdown';
        case WidgetSettingType.Number:
            return 'numeric';
        case WidgetSettingType.Text:
            return 'format-text';
        case WidgetSettingType.Calculated:
            return 'code-braces';
        case WidgetSettingType.Repeated:
            return 'repeat';
        default:
            return 'tune-vertical';
    }
}
