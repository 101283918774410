import { Component, Input } from '@angular/core';

type ColorName = 'green' | 'primary' | 'red' | 'yellow';

@Component({
    selector: 'sz-chip',
    templateUrl: './chip.component.html',
})
export class ChipComponent {
    @Input() icon?: string;
    @Input() label?: string;
    @Input() text: string;
    @Input() color: ColorName = 'primary';
}
