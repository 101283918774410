import { ExpressionType } from './type';

export class ExpressionOperator {
    operator: string;
    icon: string;
    outputType: ExpressionType;
    inputTypes: Array<ExpressionType>;
    evaluate: (values: unknown[]) => unknown;
    toJavascript: (values: unknown[]) => string;
    words: Array<string>;

    constructor(
        operator: string,
        icon: string,
        outputType: ExpressionType,
        inputTypes: Array<ExpressionType>,
        evaluate: (values: unknown[]) => unknown,
        toJavascript: (values: unknown[]) => string,
        words?: Array<string>,
    ) {
        this.operator = operator;
        this.icon = icon;
        this.outputType = outputType;
        this.inputTypes = inputTypes;
        this.evaluate = evaluate;
        this.toJavascript = toJavascript;
        this.words = words ?? [];
    }
}

export const Operators = {
    Equal: new ExpressionOperator(
        '=',
        'equal',
        ExpressionType.Boolean,
        [ExpressionType.Scalar, ExpressionType.Scalar],
        (values) => values[0] === values[1],
        (values: unknown[]) => `(${values[0]} === ${values[1]})`,
    ),
    NotEqual: new ExpressionOperator(
        '≠',
        'not-equal-variant',
        ExpressionType.Boolean,
        [ExpressionType.Scalar, ExpressionType.Scalar],
        (values) => values[0] !== values[1],
        (values: unknown[]) => `(${values[0]} !== ${values[1]})`,
    ),
    GreaterThanOrEqual: new ExpressionOperator(
        '≥',
        'greater-than-or-equal',
        ExpressionType.Boolean,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => values[0] >= values[1],
        (values: unknown[]) => `(${values[0]} >= ${values[1]})`,
    ),
    GreaterThan: new ExpressionOperator(
        '>',
        'greater-than',
        ExpressionType.Boolean,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => values[0] > values[1],
        (values: unknown[]) => `(${values[0]} > ${values[1]})`,
    ),
    LessThanOrEqual: new ExpressionOperator(
        '≤',
        'less-than-or-equal',
        ExpressionType.Boolean,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => values[0] <= values[1],
        (values: unknown[]) => `(${values[0]} <= ${values[1]})`,
    ),
    LessThan: new ExpressionOperator(
        '<',
        'less-than',
        ExpressionType.Boolean,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => values[0] < values[1],
        (values: unknown[]) => `(${values[0]} < ${values[1]})`,
    ),
    Add: new ExpressionOperator(
        '+',
        'plus',
        ExpressionType.Number,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => (values[0] as number) + (values[1] as number),
        (values: unknown[]) => `(${values[0]} + ${values[1]})`,
    ),
    Subtract: new ExpressionOperator(
        '-',
        'minus',
        ExpressionType.Number,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => (values[0] as number) - (values[1] as number),
        (values: unknown[]) => `(${values[0]} - ${values[1]})`,
    ),
    Multiply: new ExpressionOperator(
        '*',
        'close',
        ExpressionType.Number,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => (values[0] as number) * (values[1] as number),
        (values: unknown[]) => `(${values[0]} * ${values[1]})`,
    ),
    Divide: new ExpressionOperator(
        '/',
        'slash-forward',
        ExpressionType.Number,
        [ExpressionType.Number, ExpressionType.Number],
        (values) => (values[0] as number) / (values[1] as number),
        (values: unknown[]) => `(${values[0]} / ${values[1]})`,
    ),
    In: new ExpressionOperator(
        'in',
        '',
        ExpressionType.Boolean,
        [ExpressionType.Scalar, ExpressionType.List],
        (values) => (values[1] as unknown[]).includes(values[0]),
        (values: unknown[]) => `(${values[1]}.includes(${values[0]}))`,
    ),
    If: new ExpressionOperator(
        'if',
        '',
        ExpressionType.Scalar,
        [ExpressionType.Boolean, ExpressionType.Any, ExpressionType.Any],
        (values) => (values[0] ? values[1] : values[2]),
        (values: unknown[]) => `(${values[0]} ? ${values[1]} : ${values[2]})`,
        ['if', 'then', 'else'],
    ),
};
