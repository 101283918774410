import {
    CreateMqttCommand,
    MqttCommand,
} from 'src/app/shared/models/mqtt-command';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

export interface MqttCommandsQueryParams extends TopLevelQueryParams {
    modelId?: number;
}

class RawMqttCommandsApi extends TopLevelRouteApi<
    MqttCommand,
    CreateMqttCommand,
    MqttCommandsQueryParams
> {
    get path() {
        return 'mqtt-commands';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class MqttCommandsApi extends DynamicApi<
    MqttCommand,
    CreateMqttCommand,
    void,
    MqttCommandsQueryParams
> {
    private raw: RawMqttCommandsApi;
    rawAccess: RawAccessAdapter<
        MqttCommand,
        CreateMqttCommand,
        MqttCommandsQueryParams
    >;

    constructor(api: ApiService) {
        super('mqtt-commands');
        this.raw = new RawMqttCommandsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'name',
            sortOrder: 'ASC',
        });
    }
}
