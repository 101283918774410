import { importDateString } from 'src/app/shared/common';
import { CreateOtaFile, OtaFile } from 'src/app/shared/models/ota-file';
import { ApiService } from './api.service';
import { TopLevelFileRouteApi } from './top-level-file-route-api';
import { TopLevelQueryParams } from './top-level-route-api';

export interface OtaFilesQueryParams extends TopLevelQueryParams {
    modelId?: number;
    moduleId?: number;
}

export class OtaFilesApi extends TopLevelFileRouteApi<
    OtaFile,
    CreateOtaFile,
    OtaFilesQueryParams,
    unknown
> {
    get path() {
        return 'ota-files';
    }

    get fileSubPath() {
        return 'file';
    }

    constructor(api: ApiService) {
        super(api);
    }

    transformBackendData(data): OtaFile {
        return {
            ...data,
            createdDate: importDateString(data.createdDate),
            releaseDate: importDateString(data.releaseDate),
        };
    }
}
