import { Component, Input } from '@angular/core';

@Component({
    selector: 'sz-grid-item',
    templateUrl: 'grid-item.component.html',
    styleUrls: ['grid-item.component.scss'],
})
export class GridItemComponent {
    @Input() status: 'default' | 'highlight' | 'disabled' = 'default';
}
