import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { SettingsService } from 'src/app/services/user/settings.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sz-image-picker',
    templateUrl: './image-picker.component.html',
    styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {
    @Input() defaultPath: string;
    @Input() disabled = false;
    @Input() faviconCustomerId = -1;
    @Input() path: string;

    isLoading = true;
    transparent = '#00000000';
    backgroundColorControl = new FormControl(this.transparent);

    constructor(
        private settings: SettingsService,
        private translate: TranslateService,
        private snackBar: SnackBarService,
        private http: HttpClient,
    ) {}

    async ngOnInit() {
        this.settings.loaded$.subscribe(() => {
            this.backgroundColorControl.setValue(
                this.settings.get('customer.primary-color'),
            );
            this.isLoading = false;
        });
    }

    // Get the background color palette options.
    get backgroundPalette() {
        return [this.transparent, this.settings.get('customer.primary-color')];
    }

    // Get the image URL.
    get imageUrl() {
        if (this.isFavicon) {
            return environment.apiUrl + '/icons/' + this.faviconCustomerId;
        }
        if (this.path != undefined) {
            return environment.apiUrl + this.path;
        } else if (this.defaultPath != undefined) {
            return environment.apiUrl + this.defaultPath;
        }
        return null;
    }

    // Check if this is for a favicon.
    get isFavicon() {
        return (
            this.faviconCustomerId != undefined && this.faviconCustomerId > 0
        );
    }

    // Valid extensions for the image.
    get extensions() {
        return this.isFavicon ? ['ICO'] : ['PNG', 'JPG', 'JPEG', 'BMP'];
    }

    upload(fileInput) {
        fileInput.click();
    }

    async reset() {
        this.isLoading = true;
        await this.http.delete(environment.apiUrl + this.path).toPromise();
        this.sendUpdateSignal();
    }

    async onImageChange(event) {
        if (!event.target.files || event.target.files.length == 0) {
            return;
        }
        const imageFile = event.target.files[0];
        // Check the file size
        if (imageFile.size >= 1000000) {
            const errorString = await this.translate
                .get('image.error.size')
                .toPromise();
            alert(errorString);
            return;
        }
        const ext = imageFile.name.split('.').pop().toUpperCase();
        // Check if this is an invalid extension.
        if (!this.extensions.includes(ext)) {
            this.snackBar.open(
                this.isFavicon
                    ? 'image.error.icon_extension'
                    : 'image.error.normal_extensions',
            );
        } else {
            this.isLoading = true;
            // Upload the image
            const formData: FormData = new FormData();
            formData.append('files', imageFile);
            await this.http
                .post(environment.apiUrl + this.path, formData)
                .toPromise();
            this.sendUpdateSignal();
        }
    }

    sendUpdateSignal() {
        // This is kind of hacky, but it insures that the browser uses the new
        // image at all the correct places.
        document.location.reload();
    }
}
