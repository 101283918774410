import { Chart, ChartType } from '../chart';
import { type ChartTypeConfig } from '../chart-type';
import { SignalResourceType } from '../resource-type';
import { ChartSignal, generateSeriesStatisticSignals } from '../signal';

export class LineGraphChart extends Chart {
    type = ChartType.LineGraph;

    get statisticSignals() {
        return generateSeriesStatisticSignals(this.signals);
    }

    constructor(
        title: string,
        type: ChartType,
        signals: ChartSignal[],
        settings: any,
    ) {
        settings.thresholds ??= [];
        super(title, type, signals, settings);
    }
}

export const lineGraphConfig: ChartTypeConfig = {
    icon: 'chart-timeline-variant',
    canToggleSignalVisibility: true,
    supportedResourceTypes: [SignalResourceType.Time, SignalResourceType.Log],
    signalResourceDefaults: {},
};
