<a
    class="flex items-center justify-center outline-none"
    [ngClass]="[
        buttonSizeClasses,
        disabled
            ? 'opacity-25 pointer-events-none'
            : 'cursor-pointer hover:bg-gray-700/20 focus:bg-gray-700/20 dark:hover:bg-gray-400/20 dark:focus:bg-gray-400/20'
    ]"
    [routerLink]="link"
    [queryParams]="queryParams"
>
    <div [ngClass]="iconContainerClasses">
        <div [ngClass]="iconColorClasses">
            <mat-icon [svgIcon]="icon" [inline]="true"></mat-icon>
        </div>
    </div>
</a>
