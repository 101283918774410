import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class TextSenderWidget extends Widget {
    type = WidgetType.TextSenderWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Send Data',
                    defaultValue: '',
                },
                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
            ],
            settings
        );
    }
}
