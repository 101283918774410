<sz-overlay name="common.overview">
    <div class="flex flex-col relative">
        <sz-list-overlay-details [details]="allDetails" />
        @if (buttons.length > 0) {
            <div class="flex flex-row items-end gap-1 pt-2">
                @for (button of buttons; track button.text) {
                    <sz-icon-button
                        [icon]="button.icon"
                        size="small"
                        [matTooltip]="button.text | translate"
                        [link]="button.link"
                        [queryParams]="button.linkParams"
                        (click)="button.onClick ? button.onClick() : null"
                    />
                }
            </div>
        }
        @if (count == null) {
            <div class="absolute top-0 bg-base-100 min-h-6 h-full w-full">
                <div
                    class="bg-base-300 h-full w-full rounded-lg animate-pulse"
                ></div>
            </div>
        }
    </div>
</sz-overlay>
