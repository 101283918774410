import { Subject } from 'rxjs';
import {
    PageView,
    PageViewParams,
    PageViewStatsParams,
} from 'src/app/shared/models/page-view';
import { ApiService } from './api.service';
import { BasicRouteApi } from './basic-route-api';
import { Stats } from 'src/app/shared/models/stats';

export class PageViewsApi extends BasicRouteApi {
    updated$ = new Subject<PageView>();

    get path() {
        return 'page-views';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async query(params: PageViewParams): Promise<PageView[]> {
        return await this.retrieve<PageView[]>('', params);
    }

    getStats(params: PageViewStatsParams) {
        return this.retrieve<Stats>('stats', params);
    }

    record(pageView: PageView) {
        this.send('', pageView);
        this.updated$.next(pageView);
    }
}
