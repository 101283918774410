@defer (when preview !== null; prefetch on immediate) {
    <sz-grid-item
        [status]="isSelected ? 'highlight' : 'default'"
        class="h-[64px]"
    >
        <!-- We need this check because preview can go null when an item is
             deleted. -->
        @if (preview !== null) {
            <div class="flex flex-row items-center gap-2">
                @if (preview.imageUrl) {
                    <sz-image
                        alt="model_editor.model_image"
                        class="h-11 w-11 min-w-[44px] me-1"
                        classList="rounded-full"
                        [src]="preview.imageUrl"
                    />
                }
                @if (preview.icon) {
                    <div class="h-7 w-7 ms-2 me-2.5">
                        <mat-icon
                            [svgIcon]="preview.icon"
                            [title]="preview.iconTooltip"
                            [inline]="true"
                        />
                    </div>
                }
                <div>
                    <div class="h-6 font-title text-xl leading-6">
                        {{ preview.title }}
                    </div>
                    <div class="h-5 text-base font-title leading-5">
                        {{ preview.subtitle }}
                    </div>
                </div>
            </div>
        }
        @if (canPin) {
            <sz-icon-button
                actions
                [icon]="isPinned ? 'pin' : 'pin-outline'"
                [color]="isPinned ? 'pin' : 'default'"
                size="small"
                (click)="$event.stopPropagation(); togglePin()"
            />
        }
    </sz-grid-item>
} @placeholder {
    <sz-list-item-placeholder />
}
