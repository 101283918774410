import tailwindConfig from 'tailwind.config.js';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig(tailwindConfig);

export const commonColors = [
    'default', // TODO: give this a better name
    'primary',
    'secondary',
    'accent',
    'pin',
    'success',
    'warn',
    'base', // TODO: why is it called "base"? Could we use a better name?
    'on-primary',
] as const;

type ColorAsType = typeof commonColors;
export type CommonColor = ColorAsType[number];

export const colorClass = (color?: CommonColor) => {
    switch (color ?? 'default') {
        case 'default':
            return 'text-gray-700 dark:text-gray-300';
        case 'primary':
            return 'text-primary-700 dark:text-primary-300';
        case 'secondary':
            return 'text-secondary';
        case 'accent':
            return 'text-accent';
        case 'pin':
            return 'text-[goldenrod]';
        case 'success':
            return 'text-green-700 dark:text-green-300';
        case 'warn':
            return 'text-red-500';
        case 'on-primary':
            return 'text-on-primary-500';
    }
};
export const bgColorClass = (color?: CommonColor) => {
    switch (color) {
        case 'base':
            return 'bg-orange-500 text-white';
        case 'primary':
            return 'bg-primary-500 text-white';
        case 'secondary':
            return 'bg-secondary text-white';
        case 'accent':
            return 'bg-accent text-white';
        case 'success':
            return 'bg-green-500 text-white';
        case 'warn':
            return 'bg-red-500 text-white';
        default:
            return 'bg-transparent border-2 !border-[#0000001f] dark:border-[#ffffff1f]';
    }
};

// Return the CSS color value for this color
export const colorValue = (color?: CommonColor): string => {
    // TODO: use our own theming CSS variables so we don't depend on daisyUI
    switch (color) {
        case 'primary':
            return 'hsl(var(--p))';
        case 'secondary':
            return 'hsl(var(--s))';
        case 'accent':
            return 'hsl(var(--a))';
        case 'success':
            return fullConfig.theme.colors['green']['500'];
        case 'warn':
            return fullConfig.theme.colors['red']['500'];
        case 'pin':
            return 'goldenrod';
        case 'base':
            return fullConfig.theme.colors['orange']['500'];
        default:
            return 'hsl(var(--bc))';
    }
};

export const contrastColorValue = (color?: CommonColor) => {
    // TODO: use our own theming CSS variables so we don't depend on daisyUI
    switch (color) {
        case 'primary':
            return 'hsl(var(--pc))';
        case 'secondary':
            return 'hsl(var(--sc))';
        case 'accent':
            return 'hsl(var(--ac))';
        case 'success':
        case 'warn':
        case 'base':
            return 'white';
        default:
            return 'hsl(var(--b1))';
    }
};
