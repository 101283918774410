import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export enum BarDisplayMode {
    Count,
    Percent,
}

export class BarGraphWidget extends Widget {
    type = WidgetType.BarGraph;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    defaultValue: '',
                    previewValue: '',
                },
                {
                    id: 'datasets',
                    type: WidgetSettingType.Repeated,
                    name: 'widget.settings.datasets',
                    previewValue: [
                        { label: 'Value', value: 'Math.random() * 100' },
                    ],
                    config: {
                        children: [
                            {
                                id: 'label',
                                type: WidgetSettingType.Text,
                                name: 'widget.settings.label',
                                defaultValue: 'Value',
                                previewValue: null,
                            },
                            {
                                id: 'value',
                                type: WidgetSettingType.Calculated,
                                name: 'widget.settings.value',
                                previewValue: null,
                            },
                        ],
                    },
                },
                {
                    id: 'display',
                    type: WidgetSettingType.Select,
                    name: 'widget.settings.y_axis',
                    previewValue: BarDisplayMode.Count,
                    defaultValue: BarDisplayMode.Count,
                    config: {
                        options: [
                            {
                                value: BarDisplayMode.Count,
                                name: 'widget.settings.count',
                            },
                            {
                                value: BarDisplayMode.Percent,
                                name: 'widget.settings.percent',
                            },
                        ],
                    },
                },
                {
                    id: 'maxY',
                    type: WidgetSettingType.Number,
                    name: 'widget.settings.max_y_value',
                    previewValue: null,
                    defaultValue: null,
                },
            ],
            settings
        );
    }
}
