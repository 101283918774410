import { WorkerController } from 'src/app/common/workers/controller';
import { workerPromise } from 'src/app/common/workers/utils';

export type PrepareForDisplayConfig = {
    ids: number[];
    items: Record<number, unknown>;
    pinMapping: Record<number, number>;
    numColumns: number;
};
export type DisplayResult = { ids: number[]; rows: number[][] };

export class ListPageWorkerController extends WorkerController {
    constructor() {
        super();
        this.workers.prepareForDisplay = new Worker(
            new URL('./prepare-for-display.worker', import.meta.url),
        );
    }

    /**
     * Prepare list page data for display.
     */
    async prepareForDisplay(
        config: PrepareForDisplayConfig,
    ): Promise<DisplayResult> {
        return workerPromise(this.workers['prepareForDisplay'], config);
    }
}
