import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonConfig } from 'src/app/shared/button';
import { ListOverlayDetail } from 'src/app/shared/models/preview';

@Component({
    selector: 'sz-stats-overview',
    templateUrl: 'stats-overview.component.html',
})
export class StatsOverviewComponent implements OnChanges {
    @Input({ required: true }) count: number;
    @Input() details: ListOverlayDetail[];
    @Input() buttons: ButtonConfig[];

    allDetails: ListOverlayDetail[] = [];

    ngOnChanges(changes: SimpleChanges) {
        if (changes.count || changes.details) {
            this.allDetails = [
                {
                    name: 'common.count',
                    value: (this.count ?? '').toString(),
                },
                ...this.details,
            ];
        }
    }
}
