import { Component } from '@angular/core';

/**
 * A placeholder for an item on a list page.
 */
@Component({
    selector: 'sz-list-item-placeholder',
    templateUrl: 'list-item-placeholder.component.html',
})
export class ListItemPlaceholderComponent {}
