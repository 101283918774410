import { NgModule } from '@angular/core';
import { AppMaterialModule } from 'src/app/services/app-material/app-material.module';
import { DetailsCardComponent } from './details-card/details-card.component';
import {
    MultiSelectComponent,
    SearchOptionsPipe,
} from './multi-select/multi-select.component';
import { GridItemComponent } from './grid-item/grid-item.component';
import { OverlayTitleBarComponent } from './overlay-title-bar/overlay-title-bar.component';
import { OverlayComponent } from './overlay/overlay.component';
import { SectionSelectorComponent } from './section-selector/section-selector.component';
import { TableComponent } from './table/table.component';
import { ToolBarComponent } from './tool-bar/tool-bar.component';
import { FormModule } from '../form/form.module';
import { ImagesModule } from '../images/images.module';
import { DisplayModule } from '../display/display.module';
import { ButtonModule } from '../../common/buttons/buttons.module';

const items = [
    DetailsCardComponent,
    MultiSelectComponent,
    GridItemComponent,
    OverlayComponent,
    OverlayTitleBarComponent,
    SectionSelectorComponent,
    TableComponent,
    ToolBarComponent,
    SearchOptionsPipe,
];

@NgModule({
    imports: [
        AppMaterialModule,
        FormModule,
        ImagesModule,
        DisplayModule,
        ButtonModule,
    ],
    declarations: items,
    exports: items,
})
export class LayoutModule {}
