import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectOption } from 'src/app/settings/settings.model';
import { log } from 'src/app/shared/log';
import { Message, Signal } from 'src/app/shared/models/can-database';

export type SelectedCanMessageData = {
    messageId: number;
    values: Record<number, number>;
};

@Component({
    selector: 'sz-can-message-input',
    templateUrl: './can-message-input.component.html',
})
export class CanMessageInputComponent implements OnInit {
    @Input({ required: true }) messageId: number;
    @Input({ required: true }) values: Record<number, number>;
    @Input({ required: true }) messages: Message[];
    @Input() showSignals = true;
    @Output() valueChange = new EventEmitter<SelectedCanMessageData>();

    messageOptions: SelectOption[] = [];
    messageIdControl = new FormControl<number>(null);
    signalForm = new FormGroup({});
    signals: Signal[] = [];

    constructor(private fb: FormBuilder) {}

    ngOnInit() {
        this.messageOptions = this.messages.map(({ id, descriptor }) => ({
            value: id,
            name: descriptor,
        }));
        this.messageIdControl.setValue(this.messageId);
        this.loadSignals(this.messageId);
        this.messageIdControl.valueChanges.subscribe((id) => {
            if (id != this.messageId) {
                this.messageId = id;
                this.loadSignals(this.messageId);
                this.emitUpdate();
            }
        });
    }

    loadSignals(selectedId: number) {
        const message = this.messages.find(({ id }) => id == selectedId);
        if (message) {
            const controls = {};
            for (const signal of message.signals) {
                controls[signal.name] = new FormControl(
                    getDefault(this.convertType(signal.dataType))
                );
            }
            this.signalForm = this.fb.group(controls);
            if (this.messageId == selectedId) {
                this.signalForm.patchValue(this.values);
            }
            this.signalForm.valueChanges.subscribe(() => this.emitUpdate());
            this.signals = message.signals;
        } else {
            this.signalForm = this.fb.group({});
            this.signals = [];
        }
    }

    emitUpdate() {
        const messageId = this.messageId;
        const values = this.messageIdControl ? this.signalForm.value : {};
        this.valueChange.emit({ messageId, values });
    }

    /** Convert a signal data type to an input type. */
    convertType(dataType): InputType {
        switch (dataType) {
            case 'signed':
            case 'unsigned':
                return 'number';
            case 'bit':
                return 'boolean';
            default:
                // TODO: support more data types
                log.error(`unknown data type: ${dataType}`);
                // Just show the user a numeric input
                return 'number';
        }
    }
}

type InputType = 'number' | 'boolean';

function getDefault(type: InputType) {
    switch (type) {
        case 'number':
            return 0;
        case 'boolean':
            return false;
        default:
            log.error(`unknown type: ${type}`);
    }
}
