import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreview } from 'src/app/shared/models/preview';
import { CreateUser, User } from 'src/app/shared/models/user';
import { UserRole } from '../constants';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

type ResetResult = {
    status: number;
    message: string;
};

export interface UsersQueryParams extends TopLevelQueryParams {
    'userRole[lt]'?: UserRole;
    customerId?: number;
}

export class RawUsersApi extends TopLevelRouteApi<
    User,
    CreateUser,
    UsersQueryParams
> {
    get path() {
        return 'users';
    }

    constructor(api: ApiService) {
        super(api);
    }

    async resetPassword(email: string) {
        return (await this.send('credentials/reset', { email })) as ResetResult;
    }

    updatePassword(data: { password: string; access_token: string }) {
        return this.patch('credentials/update', data);
    }
}

export class UsersApi extends DynamicApi<
    User,
    CreateUser,
    UserPreview,
    UsersQueryParams
> {
    private raw: RawUsersApi;
    rawAccess: RawAccessAdapter<User, CreateUser, UsersQueryParams>;
    translate: TranslateService;

    constructor(private api: ApiService) {
        super('users');
        this.raw = new RawUsersApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'firstName',
            sortOrder: 'ASC',
        });
        this.translate = inject(TranslateService);
    }

    attachTriggers() {
        this.updated$.subscribe(async (updated) => {
            const users = this.current({ customerId: updated.customerId });
            for (const user of users) {
                // This updates the primary contact info
                this.reloadItem(user.id);
            }
        });
    }

    async loadPreview(id: number): Promise<UserPreview> {
        const user = await this.getAsync(id);
        const organization = await this.api.organizations.getAsync(
            user.customerId,
        );
        const fullName = `${user.firstName} ${user.lastName}`;
        return {
            type: 'user',
            id,
            title: fullName,
            subtitle: organization?.name ?? '',
            details: [
                {
                    name: 'common.email',
                    value: user.email,
                },
                {
                    name: 'status',
                    value: this.translate.instant(
                        user.isLocked ? 'user.locked' : 'status.active',
                    ),
                },
                {
                    name: 'common.phone',
                    value: user.phone,
                },
            ],
            item: user,
        };
    }

    async resetPassword(email: string) {
        return this.raw.resetPassword(email) as Promise<ResetResult>;
    }

    async updatePassword(data: { password: string; access_token: string }) {
        return this.raw.updatePassword(data);
    }
}
