<h1 mat-dialog-title>{{ 'delete' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <div class="flex flex-col gap-4">
        <ng-container [ngSwitch]="status">
            <ng-template [ngSwitchCase]="statusOptions.Deleting">
                <div class="mx-auto">{{ count }} / {{ total }}</div>
                <mat-progress-bar mode="determinate" [value]="loadingValue" />
            </ng-template>
            <ng-template [ngSwitchCase]="statusOptions.Complete">
                <div>
                    {{
                        'delete.result.success'
                            | translate
                                : {
                                      count: total,
                                      entityType: (
                                          typeTerm | translate
                                      ).toLowerCase()
                                  }
                    }}
                </div>
                <div class="flex flex-row justify-center">
                    <sz-button
                        size="large"
                        text="btn.ok"
                        bgColor="primary"
                        (click)="dialogRef.close(true)"
                    />
                </div>
            </ng-template>
            <ng-template [ngSwitchCase]="statusOptions.Fail">
                <div>
                    {{ 'delete.result.failure' | translate }}
                </div>
                <div class="flex flex-row justify-center">
                    <sz-button
                        size="large"
                        text="btn.ok"
                        bgColor="primary"
                        (click)="dialogRef.close(false)"
                    />
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
