<sz-grid-item status="disabled" class="h-[64px]">
    <div class="flex flex-col justify-center gap-2">
        <div
            class="h-5 w-[60%] max-w-[320px] rounded-full bg-base-300 animate-pulse"
        ></div>
        <div
            class="h-4 w-[45%] max-w-[240px] rounded-full bg-base-300 animate-pulse"
        ></div>
    </div>
</sz-grid-item>
