import { CreatePin, Pin, PinType } from 'src/app/shared/models/pin';
import { ApiService } from './api.service';
import { DynamicApi } from './dynamic-api';
import { RawAccessAdapter, createRawAccess } from './raw-access';
import { TopLevelQueryParams, TopLevelRouteApi } from './top-level-route-api';

interface PinsQueryParams extends TopLevelQueryParams {
    userId?: number;
    type?: PinType;
    itemId?: number;
}

class RawPinsApi extends TopLevelRouteApi<Pin, CreatePin, PinsQueryParams> {
    get path() {
        return 'pins';
    }

    constructor(api: ApiService) {
        super(api);
    }
}

export class PinsApi extends DynamicApi<Pin, CreatePin, void, PinsQueryParams> {
    raw: RawPinsApi;
    rawAccess: RawAccessAdapter<Pin, CreatePin, PinsQueryParams>;

    constructor(api: ApiService) {
        super('pins');
        this.raw = new RawPinsApi(api);
        this.rawAccess = createRawAccess(this.raw, {
            sortField: 'order',
            sortOrder: 'ASC',
            disable: { get: false, create: true, update: true, delete: false },
        });
    }

    ofType(type: PinType) {
        return this.current({ type });
    }

    has(type: PinType, itemId: number) {
        return this.current({ type, itemId }).length > 0;
    }

    add(type: PinType, itemId: number) {
        if (this.has(type, itemId)) {
            // Don't add a duplicate.
            return;
        }
        const pins = this.current();
        const lastPinOrder = pins[pins.length - 1]?.order ?? -1;
        this.raw
            .create({ type, itemId, order: lastPinOrder + 1 })
            .then((result) => {
                if (result.success) {
                    // refresh the cache with the new pin
                    this.reloadItem(result.id);
                }
            });
    }

    toggle(type: PinType, itemId: number) {
        if (this.has(type, itemId)) {
            this.delete([this.current({ type, itemId })[0].id]);
        } else {
            this.add(type, itemId);
        }
    }

    compare(type: PinType, itemId1: number, itemId2: number) {
        return this._getOrder(type, itemId1) - this._getOrder(type, itemId2);
    }

    async reorder(ids: number[]): Promise<void> {
        await this.raw.patch('reorder', { ids });
        ids.forEach((id) => this.reloadItem(id));
    }

    _getOrder(type: PinType, itemId: number) {
        this.assertLoaded();
        if (this.has(type, itemId)) {
            return this.current({ type, itemId })[0].order;
        } else {
            return Infinity;
        }
    }
}
