<div class="flex max-w-[300px] flex-col gap-1">
    <sz-select [formControl]="messageIdControl" [options]="messageOptions" />
    <ng-container *ngIf="showSignals">
        <div
            class="flex flex-row items-center gap-2"
            *ngFor="let signal of signals"
        >
            <div class="grow truncate" [matTooltip]="signal.name">
                {{ signal.name }}
            </div>
            <ng-container [ngSwitch]="convertType(signal.dataType)">
                <!-- TODO: enforce signal min/max -->
                <sz-input
                    *ngSwitchCase="'number'"
                    class="w-24"
                    [formControl]="signalForm.get(signal.name)"
                    [suffix]="
                        signal.unit && signal.unit.length <= 3
                            ? signal.unit
                            : null
                    "
                    [small]="true"
                    type="number"
                />
                <sz-toggle-button
                    *ngSwitchCase="'boolean'"
                    [formControl]="signalForm.get(signal.name)"
                />
            </ng-container>
        </div>
    </ng-container>
</div>
