import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayButtonType } from '../overlay-title-bar/overlay-title-bar.component';

@Component({
    selector: 'sz-overlay',
    templateUrl: './overlay.component.html',
    styles: [':host { overflow: clip }'],
})
export class OverlayComponent {
    @Input({ required: true }) name: string;
    @Input() buttonType: OverlayButtonType = 'collapse';
    @Input() collapsible = true;
    @Input() showTitle = true;
    @Output() isCollapsedChange = new EventEmitter<boolean>();
    isCollapsed = false;

    toggleCollapsed() {
        this.isCollapsed = !this.isCollapsed;
        this.isCollapsedChange.emit(this.isCollapsed);
    }
}
