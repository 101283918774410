import { TopLevelQueryParams } from 'src/app/services/api/top-level-route-api';

export type CreateModel = {
    name: string;
    deviceType: number;
    customerId: number;
    description: string;
};

export type Model = CreateModel & {
    id: number;
    productImage: string;
    productType: string;
    wakeupInterval: number;
    captureAfter: number;
    captureBefore: number;
    locationFrequency: number;
    allowTelematics: boolean;
    allowLogging: boolean;
    publishFrequencySeconds: number;
    maxLogSize: number;
    logRotationFrequency: number;
    logDays: string;
    logTime: string;
    logUploadPeriod: number;
    isDeleted: boolean;
    modelJson: string;
    thumb: string;
    logFrequency: number;
    logGpsFrequency: number;
};

export interface ModelType {
    id: number;
    name: string;
    binaryDataSupport: boolean;
    restrictions;
}

export interface ModelsQueryParams extends TopLevelQueryParams {
    isDeleted?: boolean;
    customerId?: number;
}

export const getTypeName = (type: number) => {
    switch (type) {
        case 1:
            return 'model.type.display';
        case 2:
            return 'model.type.telematics';
        case 3:
            return 'model.type.connected_logger';
        default:
            return 'common.unknown';
    }
};

/**
 * Create a Report from a partial Report, for unit tests.
 */
export function fakeModel(partial: Partial<Model>): Model {
    return {
        id: 1,
        customerId: 101,
        name: 'Example Model',
        productImage: '',
        productType: 'Display',
        wakeupInterval: 60,
        captureAfter: 10,
        captureBefore: 5,
        locationFrequency: 30,
        description: 'This is an example model for demonstration purposes.',
        allowTelematics: true,
        allowLogging: true,
        publishFrequencySeconds: 300,
        maxLogSize: 100,
        logRotationFrequency: 24,
        logDays: '',
        logTime: '',
        logUploadPeriod: null,
        isDeleted: false,
        modelJson: '',
        thumb: '',
        logFrequency: 3600,
        logGpsFrequency: 300,
        deviceType: 1,
        ...partial,
    };
}
