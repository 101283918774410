<button
    class="w-full h-full overflow-clip rounded-xl bg-base-200 p-2 outline-none"
    [ngClass]="
        'status-' +
        status +
        (status !== 'disabled' ? ' hover:bg-base-300 focus:bg-base-300' : '')
    "
    [disabled]="status === 'disabled'"
>
    <div class="flex flex-row gap-1 w-full">
        <div class="grow truncate text-start flex flex-col justify-center">
            <ng-content />
        </div>
        <div class="flex gap-1">
            <ng-content select="[actions]" />
        </div>
    </div>
</button>
