import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { ButtonConfig } from 'src/app/shared/button';

export type IconAnimation = 'spin' | 'pulse' | 'ping' | 'bounce';

export type NavBarIconConfig = {
    icon: string;
    label: string;
    animation?: IconAnimation;
    show?: () => boolean;
};

export type NavBarSetup = {
    buttons?: ButtonConfig[];
    canSearch?: boolean;
    icons?: NavBarIconConfig[];
    hasAutoSave?: boolean;
};

@Injectable({ providedIn: 'root' })
export class NavBarService {
    private _overrideTitle: string = null;
    private title = inject(Title);

    set overrideTitle(title: string) {
        this._overrideTitle = title;
        this.title.setTitle(title);
    }

    get overrideTitle() {
        return this._overrideTitle;
    }

    _buttons: ButtonConfig[] = [];
    _canSearch = false;
    _icons: NavBarIconConfig[] = [];
    _hasAutoSave = false;
    _isSaved$ = new BehaviorSubject<boolean>(true);

    get buttons() {
        return this._buttons;
    }

    get icons() {
        const icons = [...this._icons];
        if (this._hasAutoSave) {
            icons.push({
                icon: this.isSaved ? 'cloud-check-outline' : 'autorenew',
                label: this.isSaved ? 'changes.saved' : 'changes.saving',
                animation: this.isSaved ? null : 'spin',
            });
        }
        return icons;
    }

    get isSaved() {
        return this._isSaved$.value;
    }

    set isSaved(isSaved: boolean) {
        this._isSaved$.next(isSaved);
    }

    get canSearch() {
        return this._canSearch;
    }

    clear() {
        this._buttons = [];
        this._icons = [];
        this._hasAutoSave = false;
        this._canSearch = false;
        this._overrideTitle = null;
        this.isSaved = true;
    }

    setup({ buttons, icons, hasAutoSave, canSearch }: NavBarSetup) {
        this._buttons = buttons ?? [];
        this._icons = icons ?? [];
        this._hasAutoSave = hasAutoSave ?? false;
        this._canSearch = canSearch ?? false;
        this._overrideTitle = null;
    }
}
