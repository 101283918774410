import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

enum ReplaceKey {
    BULLET = 'bullet',
    SPACE = 'space',
}

@Injectable({
    providedIn: 'root',
})
export class PlatformConfigurationService {
    private isSpokeZone = environment?.isSpokeZone ?? false;
    private _name = environment?.platformName ?? 'Spoke.Zone';

    get name(): string {
        return this._name;
    }

    get favicon(): string {
        return `assets/img/favicon${this.isSpokeZone ? '' : '-MRS_CC'}.ico`;
    }

    get logo(): string {
        return `assets/img/${
            this.isSpokeZone ? 'SZ-logo_black' : 'MRS_CC-logo'
        }.png`;
    }

    get logoAlt(): string {
        return `${this._name} logo`;
    }

    get defaultFavicon(): string {
        return `/images/${this.isSpokeZone ? '' : 'MRS_CC-'}favicon.ico`;
    }

    get defaultLogo(): string {
        return `/images/${this.isSpokeZone ? 'SZ' : 'MRS_CC'}-logo.png`;
    }

    get thumbnail(): string {
        return `/images/${this.isSpokeZone ? 'SZ' : 'MRS_CC'}-thumbnail.png`;
    }

    getName(replaceKey: string): string {
        switch (replaceKey) {
            case ReplaceKey.BULLET:
                return this.name.replace('.', ' • ');
            case ReplaceKey.SPACE:
                return this.name.replace('.', ' ');

            default:
                return this.name;
        }
    }
}
