import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { ButtonVariant } from 'src/app/shared/button';
import {
    colorValue,
    CommonColor,
    contrastColorValue,
} from 'src/app/shared/color';
import { isMobile } from 'src/app/shared/screen-size-utils';

// TODO: move buttonSizes and ButtonSize to shared/button.ts
export const buttonSizes = ['small', 'medium', 'large'] as const;

type ButtonSizesAsType = typeof buttonSizes;
export type ButtonSize = ButtonSizesAsType[number];

@Component({
    selector: 'sz-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css'],
})
export class ButtonComponent {
    @Input() icon: string;
    // TODO: make the default 'large', it's much more commonly used
    @Input({ required: true }) size: ButtonSize = 'small';
    // TODO: rename to `color`
    @Input() bgColor: CommonColor = 'default';
    @Input() variant: ButtonVariant = 'filled';
    @Input() link: string;
    @Input() queryParams: Record<string, string>;
    @Input() disabled = false;
    @Input() text = 'btn.apply';
    @Input() showSpinner = false;

    isMobile = isMobile();

    private buttonSizeMap = {
        large: 'h-9 rounded-lg text-base',
        medium: 'h-7 rounded-md text-sm',
        small: 'h-6 rounded-md text-xs',
    };

    private iconSizeMap = {
        large: 'size-6',
        medium: 'size-4',
        small: 'size-3',
    };

    @HostBinding('style.pointer-events') get pointerEvents(): string {
        return this.disabled ? 'none' : 'auto';
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = isMobile();
    }

    // TODO: replace this with CSS breakpoints
    get adjustedSize() {
        return this.isMobile ? 'large' : this.size;
    }

    // TODO: use CSS variable consistently across S.Z for theming
    get dynamicStyles() {
        return {
            '--color': colorValue(this.bgColor),
            '--contrast-color': contrastColorValue(this.bgColor),
        };
    }

    get variantClass() {
        return 'variant-' + this.variant;
    }

    // TODO: replace this with CSS
    get buttonSizeClasses() {
        return (
            this.buttonSizeMap[this?.adjustedSize] ??
            this.buttonSizeMap['small']
        );
    }

    // TODO: replace this with CSS
    get iconContainerClasses() {
        return (
            this.iconSizeMap[this?.adjustedSize] ?? this.iconSizeMap['small']
        );
    }
}
