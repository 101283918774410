import { v4 as uuidv4 } from 'uuid';
import { EvaluationContext } from './evaluation-context';
import { ExpressionKind } from './kind';
import { ExpressionType } from './type';

export interface IExpressionError {
    term?: string;
    params?: Record<string, string | number>;
}

export abstract class Expression {
    private _uuid: string;
    protected _children: Expression[];
    abstract type: ExpressionType;

    constructor() {
        this._uuid = uuidv4();
        this._children = [];
    }

    abstract get kind(): ExpressionKind;

    get uuid(): string {
        return this._uuid;
    }

    get children(): Expression[] {
        return this._children;
    }

    get signalIds(): number[] {
        return this._children.reduce((a, child) => {
            return a.concat(child.signalIds);
        }, []);
    }

    get isSyntaxValid(): boolean {
        if (this.hasError) {
            return false;
        }
        for (const child of this._children) {
            if (!child.isSyntaxValid) {
                return false;
            }
        }
        return true;
    }

    abstract get data(): unknown[];

    get error(): IExpressionError {
        return {};
    }

    get hasError(): boolean {
        return !!this.error.term;
    }

    // Clone this child.
    abstract clone(): Expression;

    // Replace the descendent with the given uuid with the new child.
    replace(uuid: string, newChild: Expression): IReplaceResult {
        if (this.uuid == uuid) {
            if (this.children.length == newChild.children.length) {
                // Copy over the children.
                newChild._children = this._children;
            }
            return { expression: newChild, replaced: true };
        }
        for (let i = 0; i < this._children.length; i++) {
            const child = this._children[i];
            // If this child has the matching uuid, replace it.
            if (child.uuid == uuid) {
                if (child.children.length == newChild.children.length) {
                    // Copy over the children.
                    newChild._children = child._children;
                }
                this._children[i] = newChild;
                return { expression: this, replaced: true };
            }
            // Search the child for the matching uuid.
            if (child.replace(uuid, newChild).replaced) {
                return { expression: this, replaced: true };
            }
        }
        // Nothing was replaced.
        return { expression: this, replaced: false };
    }

    // Evaluate this expression.
    abstract evaluate(context: EvaluationContext);

    toJavascript(): string {
        return 'null';
    }
}

export interface IReplaceResult {
    expression: Expression;
    replaced: boolean;
}
