import { Widget, WidgetType } from '../widget';
import { WidgetSettingType } from '../widget-settings';

export class NumberSenderWidget extends Widget {
    type = WidgetType.NumberSenderWidget;

    constructor(settings?) {
        super(
            [
                {
                    id: 'title',
                    type: WidgetSettingType.Text,
                    name: 'widget.settings.title',
                    previewValue: 'Send Number',
                },
                {
                    id: 'callback',
                    type: WidgetSettingType.Calculated,
                    name: 'datasource',
                    previewValue: '',
                    defaultValue: '',
                },
            ],
            settings
        );
    }
}
