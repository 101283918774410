import { DatasourceConfig } from 'src/app/dashboard/models/datasource';
import { DatasourceType } from 'src/app/dashboard/models/datasource-type';
import { Pane } from 'src/app/dashboard/models/pane';
import { CanDatabase } from './can-database';
import { Device } from './device';
import { Widget } from './widget';
import { BackendError } from '../common';

export type CreateDashboard = {
    name: string;
    customerId: number;
    content?: DashboardContent;
    deviceIds?: number[];
};

export type CopyDashboard = {
    copyDashboardId: number;
    prefix: string;
};

export type Dashboard = CreateDashboard & {
    id: number;
    customerName: string;
    content: DashboardContent;
    lastViewed: Date;
};

export interface DashboardContext {
    widgets: Record<number, Widget>;
    widgetScripts: Record<number, string>;
    devices: Device[];
    canDatabases: CanDatabase[];
    canBuses: { id: number; name: string }[];
    errors?: BackendError[];
}

export interface DashboardContent {
    version: 1;
    numColumns: number;
    datasources: DatasourceConfig[];
    panes: Pane[];
    panePositions: Map<string, Position>;
    paneWidths: Map<string, number>;
}

export interface PaneConfig {
    id: string;
    title: string;
    is_minimize: boolean;
    // TODO: improve widget config handling
    widgets: {
        type;
        uuid?;
        settings?;
        id?;
        customWidgetId?;
        height?;
    }[];
}

export interface Position {
    x: number;
    y: number;
}

/**
 * Handle parsing out dashboard content by converting to the correct fields and
 * filling in default values.
 */
export function parseDashboardContent(data): DashboardContent {
    data ??= {};
    const panes = [];
    const panePositions = new Map();
    const paneWidths = new Map();
    for (const paneConfig of data['panes']) {
        const pane = new Pane({
            id: paneConfig.id,
            title: paneConfig.title ?? '',
            is_minimize: paneConfig.is_minimize ?? false,
            widgets: paneConfig.widgets ?? [],
        });
        panes.push(pane);
        panePositions.set(pane.id, {
            x: paneConfig.x ?? 0,
            y: paneConfig.y ?? 0,
        });
        paneWidths.set(pane.id, paneConfig.w ?? paneConfig.col_width ?? 1);
    }
    return {
        version: 1,
        numColumns: data['numColumns'] ?? data['columns'] ?? 4,
        datasources: data['datasources'] ?? [],
        panes,
        panePositions,
        paneWidths,
    };
}

/**
 * Handle exporting the dashboard content out to what the API can handle.
 */
export function exportDashboardContent(content: DashboardContent) {
    return {
        version: 1,
        numColumns: content.numColumns,
        datasources: content.datasources,
        panes: content.panes.map((p) => {
            const paneConfig = p.build();
            // Include pane position and width.
            if (content.panePositions.has(p.id)) {
                const { x, y } = content.panePositions.get(p.id);
                paneConfig['x'] = x;
                paneConfig['y'] = y;
            }
            if (content.paneWidths.has(p.id)) {
                paneConfig['w'] = content.paneWidths.get(p.id);
            }
            return paneConfig;
        }),
    };
}

/**
 * Check if a dashboard has a datasource of the given type.
 */
export function hasDatasourceType(dashboard: Dashboard, type: DatasourceType) {
    return dashboard.content.datasources.some((d) => d.type === type);
}

/**
 * Check if a widget has a widget of the given type or custom widget ID.
 */
export function hasWidget(dashboard: Dashboard, type: string): boolean {
    const customWidgetId = Number(type);
    if (isNaN(customWidgetId)) {
        // Normal widget type
        return dashboard.content.panes.some((p) =>
            p.widgets.some((w) => w.type == type),
        );
    } else {
        // Custom widget
        return dashboard.content.panes.some((p) =>
            p.widgets.some((w) => w.customWidgetId == customWidgetId),
        );
    }
}

export enum DashboardStatus {
    UNKNOWN = 'unknown',
    LIVE = 'live',
    LOADING = 'loading',
    OFFLINE = 'offline',
}

export const getDashboardStatus = (deltaMilliseconds: number) => {
    if (deltaMilliseconds < 3_000) {
        return DashboardStatus.LIVE;
    } else if (deltaMilliseconds <= 30_000) {
        return DashboardStatus.LOADING;
    } else {
        return DashboardStatus.OFFLINE;
    }
};

/**
 * Create an Dashboard from a partial Dashboard, for unit tests.
 */
export function fakeDashboard(partial: Partial<Dashboard>): Dashboard {
    return {
        id: 1,
        name: 'A fake dashboard',
        customerId: 1,
        customerName: '',
        content: null,
        lastViewed: new Date(),
        ...partial,
    };
}
