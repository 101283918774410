import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, DurationLikeObject, Settings as LuxonSettings } from 'luxon';
import { Device } from '../shared/models/device';

@Injectable({ providedIn: 'root' })
export class DateTimeService {
    // These are set by SettingsService.update
    timeFormat = '';
    dateFormat = '';
    isUtcTime = false;

    get dateTimeFormat(): string {
        return this.dateFormat + ' ' + this.timeFormat;
    }

    constructor(private translate: TranslateService) {}

    convertLocalToUTC(date) {
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds(),
        );
    }

    /**
     * Get date and time in string
     */
    dateTimeString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.dateTimeFormat, 'en', '+0000') +
                  ' (UTC)'
            : formatDate(dateTime, this.dateTimeFormat, 'en');
    }

    /**
     * Get time in string
     */
    timeString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.timeFormat, 'en', '+0000')
            : formatDate(dateTime, this.timeFormat, 'en');
    }

    /**
     * Get date in string
     */
    dateString(dateTime: Date = new Date()): string {
        return this.isUtcTime
            ? formatDate(dateTime, this.dateFormat, 'en', '+0000')
            : formatDate(dateTime, this.dateFormat, 'en');
    }

    getRelativeHumanDate(device: Device, isPrecise: boolean) {
        const lastOnline = DateTime.fromJSDate(device.lastOnline);
        const now = DateTime.now();
        let diff = lastOnline.diff(now, ['seconds']).negate();
        if (diff.as('seconds') < 1) {
            return this.translate.instant('device.last_online.now');
        }
        if (lastOnline.day == now.day && !isPrecise) {
            return this.translate.instant('common.today');
        }
        let durationString = '';
        const units = ['seconds', 'minutes', 'hours', 'days', 'weeks'];
        for (let i = 0; i < units.length; i++) {
            const lastUnit = units[i - 1];
            const unit = units[i];
            const nextUnit = units[i + 1];
            diff = diff.shiftTo(unit as keyof DurationLikeObject);
            if (nextUnit && diff.as(nextUnit as keyof DurationLikeObject) < 1) {
                if (lastUnit) {
                    diff = diff.shiftTo(
                        unit as keyof DurationLikeObject,
                        lastUnit as keyof DurationLikeObject,
                    );
                    if (diff.get(lastUnit as keyof DurationLikeObject) < 1) {
                        diff = diff.shiftTo(unit as keyof DurationLikeObject);
                    }
                }
                break;
            }
        }
        LuxonSettings.defaultLocale = this.translate.currentLang;
        durationString = diff.toHuman({ maximumFractionDigits: 0 });
        return this.translate.instant('common.duration', {
            duration: durationString,
        });
    }
}
