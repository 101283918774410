<sz-overlay
    name="common.selected"
    buttonType="close"
    (isCollapsedChange)="clearSelection.emit()"
>
    <div class="flex flex-col gap-2">
        <sz-list-overlay-details [details]="details" />
        <div class="flex flex-row gap-1">
            @for (button of buttons; track button.text) {
                <sz-icon-button
                    [icon]="button.icon"
                    size="small"
                    [matTooltip]="button.text | translate"
                    [link]="button.link"
                    [queryParams]="button.linkParams"
                    (click)="button.onClick ? button.onClick() : null"
                />
            }
            @if (showDeleteButton) {
                <sz-icon-button
                    icon="delete"
                    size="small"
                    color="warn"
                    [matTooltip]="'delete' | translate"
                    (click)="delete.emit()"
                />
            }
        </div>
    </div>
</sz-overlay>
