import {
    CountResult,
    CreateResult,
    DeleteResult,
    TopLevelQueryParams,
    UpdateResult,
} from './top-level-route-api';

export interface RawAccessAdapter<
    GetType,
    CreateType,
    QueryParams extends TopLevelQueryParams,
> {
    loadChunk(chunkId: number, chunkSize: number): Promise<GetType[]>;
    count(params: Partial<QueryParams>): Promise<CountResult>;
    query(params: Partial<QueryParams>): Promise<GetType[]>;
    get(id: number): Promise<GetType>;
    create(data: CreateType): Promise<CreateResult>;
    update(id: number, updates: Partial<CreateType>): Promise<UpdateResult>;
    delete(ids: number[]): Promise<DeleteResult>;
}

export const createRawAccess = <
    CreateType,
    QueryParams extends TopLevelQueryParams,
>(
    rawApi,
    options: {
        sortField: string;
        sortOrder: 'ASC' | 'DESC';
        disable?: {
            get: boolean;
            create: boolean;
            update: boolean;
            delete: boolean;
        };
        extraQueryParams?: Partial<QueryParams>;
    },
) => {
    options.disable ??= {
        get: false,
        create: false,
        update: false,
        delete: false,
    };
    options.extraQueryParams ??= {};
    return {
        loadChunk: async (chunkId: number, chunkSize: number) =>
            await rawApi.query({
                ...options.extraQueryParams,
                offset: chunkSize * chunkId,
                limit: chunkSize,
                sort: options.sortField,
                sortOrder: options.sortOrder,
            }),
        count: (queryParams?: Partial<QueryParams>) =>
            rawApi.count({
                ...queryParams,
                sort: options.sortField,
                sortOrder: options.sortOrder,
            }),
        query: (queryParams?: Partial<QueryParams>) =>
            rawApi.query(queryParams),
        get: async (id: number) => {
            if (options.disable.get) {
                throw Error('Get not supported');
            }
            return await rawApi.get(id);
        },
        create: async (data: CreateType) => {
            if (options.disable.create) {
                throw Error('Create not supported');
            }
            return await rawApi.create(data);
        },
        update: async (id: number, updates: Partial<CreateType>) => {
            if (options.disable.update) {
                throw Error('Update not supported');
            }
            return await rawApi.update(id, updates);
        },
        delete: async (ids: number[]) => {
            if (options.disable.delete) {
                throw Error('Delete not supported');
            }
            return await rawApi.delete(ids);
        },
    };
};
