import { EvaluationContext } from './evaluation-context';
import { Expression } from './expression';
import { ExpressionKind } from './kind';
import { ExpressionNull } from './null';
import { ExpressionType } from './type';

export class ExpressionList extends Expression {
    constructor(children: Array<Expression>) {
        super();
        this._children = children;
    }

    get kind(): ExpressionKind {
        return ExpressionKind.List;
    }

    get data(): unknown[] {
        return [this.kind, this.children.map((c) => c.data)];
    }

    get type(): ExpressionType {
        return ExpressionType.List;
    }

    toJavascript(): string {
        return `[${this.children.map((c) => c.toJavascript()).join(', ')}]`;
    }

    clone(): ExpressionList {
        return new ExpressionList(this.children.map((c) => c.clone()));
    }

    shrink() {
        if (this.children.length > 2) {
            this._children = this.children.slice(0, -1);
        }
    }

    grow() {
        this._children.push(new ExpressionNull());
    }

    evaluate(context: EvaluationContext) {
        return this._children.map((c) => c.evaluate(context));
    }
}
